import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';

// --- Components --- //
import RDSButton from '../../components/common/RDSButton';
import SvgIcon from '../../components/common/SvgIcon';
import EDSInput from '../../components/common/EDSInput';
import Loading from '../../components/common/LoadingIndicator';

// --- Utils --- //
import * as FormValidationsUtil from '../../utils/FormValidationsUtil';

// --- Constants --- //
import PagePathConstants from '../../constants/router/PagePathConstants';
import ColorConstants from '../../constants/ColorConstants';

// --- Actions --- //
import * as stGroupActionCreator from '../../actions/stGroupActionCreator';
import * as basicConfigActionCreator from '../../actions/basicConfigActionCreator';
import * as userActionCreator from '../../actions/userActionCreator';

import * as UserSelectors from '../../selectors/UserSelectors';

import I18N from '../../i18n';
import classNames from 'classnames';


class UserEditPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            name: '',
            remark: '',
            currencyList: null,
            selected_user_id: null,
            selected_curcrency: null,

            showError: false,
            showLoading: false,
            errMsg: '',
            isEditing: false,
        };

        this.handleGroupNameChange = this.handleGroupNameChange.bind(this);
        this.handleGroupRemarkChange = this.handleGroupRemarkChange.bind(this);
        this.toggleError = this.toggleError.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.toggleEditing = this.toggleEditing.bind(this);
        this.handleBackPage = this.handleBackPage.bind(this);
        this.editSTGroupInfo = this.editSTGroupInfo.bind(this);
        this.fetchCurrencyList = this.fetchCurrencyList.bind(this);
        this.handleSelectCurrency = this.handleSelectCurrency.bind(this);
        this.handleSelectUser = this.handleSelectUser.bind(this);
    }

    componentDidMount() {
        this.fetchCurrencyList();
        
    }

    fetchUserList() {
        const { userActions } = this.props;
        userActions && userActions.fetchUnpagedUserList()
        .then(() => {
            this.getStCardInfoById();
        })
        .catch(() => {
            this.toggleLoading();
        });
    }

    fetchCurrencyList() {
        const { basicConfigActions } = this.props;
        this.toggleLoading()
        basicConfigActions && basicConfigActions.fetchCurrencyList()
            .then((data) => {
                this.toggleLoading()
                const currencyList = data && data.map(item => {
                    return {
                        value: item.code,
                        label: item.currency
                    }
                });
                this.setState({ currencyList });
                this.fetchUserList();
            })
            .catch(() => {
                this.toggleLoading();
            });
    }

    getStCardInfoById() {
        const { location, stGroupActions, unpagedUserList } = this.props;
        const stGroupId = location.pathname.split(`${PagePathConstants.ST_GROUP}/`)[1];
        if (stGroupId && stGroupId !== 'add') {
            stGroupActions.getStGroupInfoById(stGroupId)
                .then(res => {
                    const stGroupInfo = res;
                    let selectedCurrency = this.state.currencyList.filter(item => item.value === stGroupInfo.bizhong_code)[0];
                    let selectedUser = unpagedUserList.filter(item => item.value == stGroupInfo.user_id)[0];

                    this.setState({
                        name: stGroupInfo.name || '',
                        remark: stGroupInfo.remark || '',
                        selected_curcrency: selectedCurrency || null,
                        selected_user_id: selectedUser || null,
                    });
                    this.toggleLoading();
                })
                .catch(() => {
                    this.toggleLoading();
                })
        }
    }

    toggleEditing() {
        this.setState({ isEditing: !this.state.isEditing });
    }

    toggleError() {
        this.setState({ showError: !this.state.showError });
    }

    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    handleGroupNameChange(e) {
        const name = e && e.target.value;
        this.setState({ name, showError: false });
    }

    handleGroupRemarkChange(e) {
        const remark = e && e.target.value;
        this.setState({ remark, showError: false });
    }

    handleSelectCurrency(selectedCurrency) {
        this.setState({selected_curcrency: selectedCurrency});
    }

    handleSelectUser(selectedUser) {
        this.setState({selected_user_id: selectedUser});
    }

    editSTGroupInfo() {
        const { stGroupActions, history, location } = this.props;
        const stGroupId = location.pathname.split(`${PagePathConstants.ST_GROUP}/`)[1];
        let { name, remark, selected_user_id, selected_curcrency } = this.state;
       
        const params = {
            name,
            remark,
            user_id: selected_user_id ? selected_user_id.value : null,
            bizhong_code: selected_curcrency ? selected_curcrency.value : null
        }
        if (stGroupId && stGroupId !== 'add') {
            params['id'] = stGroupId;
        }
        const formData = new FormData();
        Object.keys(params).forEach((key) => {
            formData.append(`stripe[${key}]`, params[key]);
        });
        this.toggleEditing();
        stGroupActions && stGroupActions.editSTGroupInfoById(formData)
            .then(() => {
                history && history.push(PagePathConstants.ST_GROUP);
            })
            .catch(err => {
                this.toggleEditing();
            });
    }

    handleBackPage() {
        const { history } = this.props;
        history && history.goBack();
    }

    render() {
        const { isEditing, showLoading, name, remark, showError, errMsg, errFiled, currencyList, selected_curcrency, selected_user_id } = this.state;
        const { location, unpagedUserList } = this.props;
        const stGroupId = location.pathname.split(`${PagePathConstants.ST_GROUP}/`)[1];
        const isEditSTGroup = (stGroupId && stGroupId !== 'add');
        return <div className='tw-w-full tw-px-[50px] tw-py-[50px]'>
            {showLoading && <Loading />}
            <div className='tw-rounded-[30px] tw-bg-white'>
                <div className='tw-flex tw-items-center  tw-px-[30px] tw-py-[24px] tw-border-b' onClick={this.handleBackPage}>
                    <SvgIcon name="rds-back-icon" color={ColorConstants.black[0]} size={24} />
                    <span className='tw-ml-[10px] tw-text-[18px] tw-font-bold' >{I18N.getText('back')}</span>
                </div>
                <div className='tw-text-[14px] tw-px-[30px] tw-py-[30px]'>
                    <div className='tw-flex tw-items-center'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('group-name')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`, { 'tw-border-light-red': (showError && errFiled === 'comment') })}
                            placeholder={I18N.getText('group-name-tip')}
                            value={name || ""}
                            type="text"
                            onChange={this.handleGroupNameChange}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('group-desc')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`, { 'tw-border-light-red': (showError && errFiled === 'apiKey') })}
                            placeholder={I18N.getText('group-desc-tip')}
                            value={remark || ""}
                            type="textarea"
                            onChange={this.handleGroupRemarkChange}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('currency')}</div>
                        <Select
                            className="tw-w-full tw-rounded-[30px]  tw-px-[20px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-role-selector-container"
                            styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                            value={selected_curcrency}
                            placeholder={I18N.getText('currency-tip')}
                            onChange={this.handleSelectCurrency}
                            isClearable={true}
                            name="currency"
                            options={currencyList}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('user')}</div>
                        <Select
                            className="tw-w-full tw-rounded-[30px]  tw-px-[20px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-role-selector-container"
                            styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                            value={selected_user_id}
                            placeholder={I18N.getText('user-tip')}
                            onChange={this.handleSelectUser}
                            isClearable={true}
                            name="user"
                            options={unpagedUserList}
                        />
                    </div>
                    
                    
                    {showError && <div className='tw-mt-[20px] tw-text-[14px] tw-text-error-text'>{errMsg}</div>}
                    <div className='tw-flex tw-justify-center tw-items-center tw-mt-[30px]'>
                        <RDSButton
                            loading={isEditing}
                            disabled={isEditing || name.trim() === '' || !selected_curcrency || !selected_user_id || showError}
                            onClick={this.editSTGroupInfo}
                            className="tw-text-white" >{I18N.getText('edit')}</RDSButton>
                    </div>
                </div>
            </div>
        </div>;
    }
}

function mapStateToProps(state) {
    return {
        unpagedUserList: UserSelectors.selectUnpagedUserListForSelector(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        stGroupActions: bindActionCreators(stGroupActionCreator, dispatch),
        userActions: bindActionCreators(userActionCreator, dispatch),
        basicConfigActions: bindActionCreators(basicConfigActionCreator, dispatch),
        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEditPage);