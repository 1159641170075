import { Map } from "immutable";
import StoreStateConstants from "../constants/store/StoreStateConstants";
import ActionConstants from "../constants/actions/ActionConstants";
import PagePathConstants from "../constants/router/PagePathConstants";
import I18N from "../i18n";

const BASE_ROUTER_LIST = [
	{
		icon: "fas fa-home",
		text: "Dashboard",
		path: "/dashboard",
		isOpen: window.location && window.location.hash.indexOf("/dashboard") >= 0,
	},
	{
		icon: "fas fa-table",
		text: "Order",
		path: "/order",
		isOpen: window.location && window.location.hash.indexOf("/order") >= 0,
	},
	{
		icon: "fas fa-handshake-alt-slash",
		text: "Blacklist",
		path: "/blacklist",
		isOpen: window.location && window.location.hash.indexOf("/blacklist") >= 0,
	},
	// {
	//   icon: 'fas fa-wallet',
	//   text: 'Payment',
	//   path: '/payment',
	//   isOpen: (window.location && window.location.hash.indexOf('/payment') >= 0),
	// }
];

const IBAN_ROUTER_LIST = [
	{
		icon: "fas fa-home",
		path: PagePathConstants.IBAN,
		text: I18N.getText("iban-route"),
		isOpen: window.location && window.location.hash.indexOf(PagePathConstants.IBAN) >= 0,
	},
];

const ADMIN_ROUTER_LIST = [
	{
		icon: "fas fa-user",
		path: PagePathConstants.USER,
		text: I18N.getText("user-route"),
		isOpen: window.location && window.location.hash.indexOf(PagePathConstants.USER) >= 0,
	},
	{
		icon: "fab fa-stripe-s",
		path: PagePathConstants.ST,
		text: I18N.getText("st-route"),
		isOpen: window.location && window.location.hash.indexOf("/st") >= 0,
		children: [
			{
				icon: null,
				text: "Stripe Card",
				path: PagePathConstants.ST,
			},
			{
				icon: null,
				text: "Stripe Group",
				path: PagePathConstants.ST_GROUP,
			},
		],
	},
	// {
	//   icon: 'fas fa-money-check-alt',
	//   path: PagePathConstants.IBAN,
	//   text: I18N.getText('iban-route'),
	//   isOpen: (window.location && window.location.hash.indexOf(PagePathConstants.IBAN) >= 0)
	// },
	// {
	//   icon: 'fas fa-money-bill-alt',
	//   path: PagePathConstants.ACH,
	//   text: I18N.getText('ach-route'),
	//   isOpen: (window.location && window.location.hash.indexOf(PagePathConstants.ACH) >= 0)
	// }, {
	//   icon: 'fab fa-bitcoin',
	//   path: PagePathConstants.COIN,
	//   text: I18N.getText('coin-route'),
	//   isOpen: (window.location && window.location.hash.indexOf(PagePathConstants.COIN) >= 0)
	// }
	{
		icon: "fab fa-product-hunt",
		path: PagePathConstants.PRODUCT,
		text: I18N.getText("product-route"),
		isOpen: window.location && window.location.hash.indexOf("/product") >= 0,
		children: [
			{
				icon: null,
				text: "Product",
				path: PagePathConstants.PRODUCT,
			},
			{
				icon: null,
				text: "Product Category",
				path: PagePathConstants.PRODUCT_CATETORY,
			},
		],
	},
	{
		icon: "fas fa-globe",
		path: PagePathConstants.ACCOUNT_COUNTRY,
		text: I18N.getText("account-country-route"),
		isOpen: window.location && window.location.hash.indexOf("/account-country") >= 0,
	},
];

const CUSTOMER_ROUTER_LIST = [
	{
		icon: "fab fa-stripe-s",
		path: PagePathConstants.ST,
		text: I18N.getText("st-route"),
		isOpen: window.location && window.location.hash.indexOf("/st") >= 0,
		children: [
			{
				icon: null,
				text: "Stripe Card",
				path: PagePathConstants.ST,
			},
		],
	},
	// {
	//   icon: 'fas fa-money-check-alt',
	//   path: PagePathConstants.IBAN,
	//   text: I18N.getText('iban-route'),
	//   isOpen: (window.location && window.location.hash.indexOf(PagePathConstants.IBAN) >= 0)
	// },
	// {
	//   icon: 'fas fa-money-bill-alt',
	//   path: PagePathConstants.ACH,
	//   text: I18N.getText('ach-route'),
	//   isOpen: (window.location && window.location.hash.indexOf(PagePathConstants.ACH) >= 0)
	// }, {
	//   icon: 'fab fa-bitcoin',
	//   path: PagePathConstants.COIN,
	//   text: I18N.getText('coin-route'),
	//   isOpen: (window.location && window.location.hash.indexOf(PagePathConstants.COIN) >= 0)
	// }
];

const initialState = Map({
	[StoreStateConstants.ROUTER_LIST]: BASE_ROUTER_LIST,
});

export const RouteReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionConstants.UPDATE_ROUTER_LIST: {
			return state.set(StoreStateConstants.ROUTER_LIST, action.payload);
		}
		case ActionConstants.SHOW_ADMIN_ROUTER: {
			// const WITHDRAW_ROUTER_LIST = [{
			//   icon: 'fas fa-hand-holding-usd',
			//   path: PagePathConstants.WITHDRAW_PAGE,
			//   text: I18N.getText('wallet-route'),
			//   isOpen: (window.location && window.location.hash.indexOf(PagePathConstants.WITHDRAW_PAGE) >= 0),
			//   children: [{
			//     icon: null,
			//     text: 'Withdraw List',
			//     path: PagePathConstants.WITHDRAW_PAGE
			//   }]
			// }];
			return state.set(StoreStateConstants.ROUTER_LIST, BASE_ROUTER_LIST.concat(ADMIN_ROUTER_LIST));
		}
		case ActionConstants.SHOW_BASE_ROUTER: {
			return state.set(StoreStateConstants.ROUTER_LIST, BASE_ROUTER_LIST);
		}
		// case ActionConstants.SHOW_FOREIGNER_ROUTER: {
		//   return state.set(StoreStateConstants.ROUTER_LIST, BASE_ROUTER_LIST.concat(IBAN_ROUTER_LIST));
		// }
		case ActionConstants.SHOW_CUSTOMER_ROUTER: {
			return state.set(StoreStateConstants.ROUTER_LIST, BASE_ROUTER_LIST.concat(CUSTOMER_ROUTER_LIST));
		}
		default:
			return state;
	}
};
