import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import { Input } from 'reactstrap';
import classnames from 'classnames';

// --- Components --- //
import DeleteConfirmationModal from '../../components/common/dialog/DeleteConfirmationModal';
import Loading from '../../components/common/LoadingIndicator';
import SvgIcon from '../../components/common/SvgIcon';


// --- Constants --- //
import ColorConstants from '../../constants/ColorConstants';
import DialogConstants from '../../constants/dialog/index';
import PagePathConstants from '../../constants/router/PagePathConstants';

// --- Actions --- //
import * as orderActionCreator from '../../actions/orderActionCreator';

// --- Icons --- //
import DollarIcon from '../../icons/DollarIcon';
import JPYIcon from '../../icons/JPYIcon';
import EURIcon from '../../icons/EURIcon';
import CalendarIcon from '../../icons/CalendarIcon';
import SearchIcon from '../../icons/SearchIcon';

// --- Selectors --- //
import * as OrderSelectors from '../../selectors/OrderSelectors';
import * as UserSelectors from '../../selectors/UserSelectors';
import I18N from '../../i18n';
import LocalStorageUtil from '../../utils/LocalStorageUtil';
import LocalStorageConstants from '../../constants/LocalStorageConstants';
import { Pagination } from 'element-react';
import classNames from 'classnames';
import {renderSubscriptionStatus, renderSubscriptionIconStatus} from '../../utils/OrderUtil';
import UserHelper from '../../helpers/UserHelper';

// --- Utils --- //
import {getCurrencySymbol} from '../../utils/InvoiceUtil';


// const PAYMENT_FILTER_TYPE = ['all', 'succeeded', 'failed', 'non-payment'];
const ORDER_FILTER_TYPE = [{
    id: '',
    text: 'all'
}, {
    id: 1,
    text: 'succeeded'
},
//  {
//   id: 2,
//   text: 'notified'
// }, 
// {
//     id: 3,
//     text: 'settle-accounts'
// }, {
//     id: 4,
//     text: 'timeout'
// },
{
    id: 0,
    text: 'unpaid'
},
// {
//     id: 5,
//     text: 'pending-review'
// },
{
    id: 6,
    text: 'failed'
}];

const CACHED_PARAM_KEY = LocalStorageConstants.ORDER_CACHED_PARAM;
class OrderPage extends Component {
    constructor(props, context) {
        super(props, context);
        const cachedParam = LocalStorageUtil.loadCachedParam(CACHED_PARAM_KEY);
        this.state = {
            orderParams: cachedParam ? cachedParam : {
                page: 1,
                per_page: 10,
                status: '',
                email: '',
                comment: '',
                user_id: ''
            },
            showLoading: false,
            showDeleteConfirmModal: false,
            showCancelConfirmModal: false,
            deleteItemId: '',
            cancelItem: '',

            previewOrderId: '',
            showOrderDetailModal: false,
            stCharge: null,
            iPaddressRisklevel: null,
            selectedUser: null,
        };

        this.getOrderRecord = this.getOrderRecord.bind(this);
        this.handleOrderFilterClick = this.handleOrderFilterClick.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.sendNotification = this.sendNotification.bind(this);
        this.routeToOrderDetailPage = this.routeToOrderDetailPage.bind(this);
        this.handleOrderSearchTextChange = this.handleOrderSearchTextChange.bind(this);
        this.toggleDeleteConfirmModal = this.toggleDeleteConfirmModal.bind(this);
        this.toggleCancelConfirmModal = this.toggleCancelConfirmModal.bind(this);
        this.handleUpdateOrderStatus = this.handleUpdateOrderStatus.bind(this);
        this.handleCancelSubscriptionOrder = this.handleCancelSubscriptionOrder.bind(this);
        this.handlePreviewOrderStatusWindow = this.handlePreviewOrderStatusWindow.bind(this);
        this.searchNewOrderRecord = this.searchNewOrderRecord.bind(this);
        this.handlePreviewOrderDetail = this.handlePreviewOrderDetail.bind(this);
        this.renderRiskScoreBg = this.renderRiskScoreBg.bind(this);
        this.toggleRiskModal = this.toggleRiskModal.bind(this);
        this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
        this.handleSelectedUser = this.handleSelectedUser.bind(this);
        this.resetQueryParamsByCachedData = this.resetQueryParamsByCachedData.bind(this);
    }

    componentDidMount() {
        this.getOrderRecord();
        this.resetQueryParamsByCachedData();
    }


    resetQueryParamsByCachedData(props) {
        const cachedParam = LocalStorageUtil.loadCachedParam(CACHED_PARAM_KEY);
        if (cachedParam && cachedParam.user_id !== '') {
          const {orderParams} = this.state;
          const {unpagedUserList} = (props || this.props);
          const filterUsers = unpagedUserList.filter((user) => {
            return user.value === orderParams.user_id;
          });
          if (filterUsers.length > 0) {
            this.setState({selectedUser: filterUsers[0]});
          }
        }
      }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.unpagedUserList !== this.props.unpagedUserList) {
      this.resetQueryParamsByCachedData(nextProps);
    }
  }

    handlePreviewOrderDetail(id) {
        const { orderActions } = this.props;
        this.toggleLoading();
        orderActions && orderActions.retrieveOrderRiskInfo(id)
            .then(data => {
                this.toggleLoading();
                this.setState({
                    previewOrderId: id,
                    showOrderDetailModal: true,
                    stCharge: data.charges,
                    iPaddressRisklevel: data.metadata
                });
            })
            .catch(() => {
                this.toggleLoading();
            })

    }

    handleSelectedUser(selectedUser) {
        let orderParams = Object.assign({}, this.state.orderParams);
        if (selectedUser) {
            orderParams.user_id = selectedUser.value;
        } else {
            //重置
            orderParams.user_id = '';
        }
        orderParams.page = 1;
        LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, orderParams);
        this.setState({
            selectedUser,
            orderParams,
            showError: false
        }, this.getOrderRecord);
    }

    renderRiskScoreBg(score) {
        if (0 <= score && score < 5) {
            return '#6ca329';
        } else if (5 <= score && score < 65) {
            return '#fcbf38';
        } else if (65 <= score && score < 75) {
            return '#f36c03';
        } else if (75 <= score && score <= 100) {
            return '#8c0432';
        } else {
            return '#6ca329';
        }
    }

    toggleRiskModal() {
        this.setState({ showOrderDetailModal: !this.state.showOrderDetailModal });
    }

    handlePreviewOrderStatusWindow(id) {
        this.toggleDeleteConfirmModal();
        this.setState({ deleteItemId: id });
    }

    handleCancelOrder(record) {
        this.toggleCancelConfirmModal();
        this.setState({ cancelItem: record });
    }

    toggleDeleteConfirmModal() {
        this.setState({ showDeleteConfirmModal: !this.state.showDeleteConfirmModal });
    }

    toggleCancelConfirmModal() {
        this.setState({ showCancelConfirmModal: !this.state.showCancelConfirmModal });
    }

    handleUpdateOrderStatus() {
        const { orderActions } = this.props;
        const { deleteItemId } = this.state;

        orderActions && (deleteItemId && deleteItemId !== '') && orderActions.updateOrderPaymentStatus(this.state.deleteItemId)
            .then(data => {
                this.toggleDeleteConfirmModal();
                this.getOrderRecord();
                return;
            })
            .catch(this.toggleDeleteConfirmModal);
    }

    handleCancelSubscriptionOrder() {
        const { orderActions } = this.props;
        const { cancelItem } = this.state;

        orderActions && (cancelItem && cancelItem !== '') && orderActions.cancelOrderSubscription(cancelItem)
            .then(data => {
                this.toggleCancelConfirmModal();
                return;
            })
            .catch(this.toggleCancelConfirmModal);
    }

    handleOrderSearchTextChange(e, property) {
        const value = e && e.target.value;
        let { orderParams } = this.state;
        orderParams[property] = value;
        this.setState({ orderParams });
    }

    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    sendNotification(recordId) {
        const { orderActions } = this.props;
        orderActions && orderActions.informOrder(recordId);
    }

    routeToOrderDetailPage(orderNo, record) {
        const { history } = this.props;
        history && history.push(PagePathConstants.ORDER_DETAIL_PAGE.replace(':id', orderNo) + `?cSiteUrl=${record.aisle_url}&orderId=${record.id}`);
    }

    searchNewOrderRecord() {
        let { orderParams } = this.state;
        orderParams.page = 1;
        this.setState({ orderParams }, () => {
            this.getOrderRecord();
            LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, orderParams);
        });
    }

    getOrderRecord(params) {
        if (!params) {
            params = this.state.orderParams;
        }
        const { orderActions } = this.props;
        this.toggleLoading();
        return orderActions && orderActions.fetchOrderRecord(params)
            .then(data => {
                this.toggleLoading();
                return;
            })
            .catch(this.toggleLoading);
    }

    handleOrderFilterClick(item) {
        let { orderParams } = this.state;
        orderParams.status = item.id;
        orderParams.page = 1;
        this.setState({ orderParams }, () => {
            LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, orderParams);
        });
        this.getOrderRecord(orderParams);
    }

    renderOrderStatus(is_notify, status) {
        if (is_notify) {
            return (<div className='tw-rounded-[20px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-py-[0px] tw-bg-status-notified-bg tw-flex tw-items-center tw-justify-center'>
                <span>{I18N.getText('notified')}</span>
            </div>)
        }
        switch (status) {
            case 1: {
                return (<div className='tw-rounded-[20px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-bg-status-succeed-bg tw-flex tw-items-center tw-justify-center'>
                    <span>{I18N.getText('succeed')}</span>
                </div>)
            }
            case 3: {
                return (<div className='tw-rounded-[20px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-bg-status-settle-accounts-bg tw-flex tw-items-center tw-justify-center'>
                    <span>{I18N.getText('settle-accounts')}</span>
                </div>)
            }
            case 4: {
                return (<div className='tw-rounded-[20px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-bg-status-timeout-bg tw-flex tw-items-center tw-justify-center'>
                    <span>{I18N.getText('timeout')}</span>
                </div>)
            }
            case 0: {
                return (<div className='tw-rounded-[20px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-bg-status-unpaid-bg tw-flex tw-items-center tw-justify-center'>
                    <span>{I18N.getText('unpaid')}</span>
                </div>)
            }
            case 5: {
                return (<div className='tw-rounded-[20px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-bg-status-being-processed-bg tw-flex tw-items-center tw-justify-center'>
                    <span>{I18N.getText('pending-review')}</span>
                </div>)
            }
            case 6: {
                return (<div className='tw-rounded-[20px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-bg-status-timeout-bg tw-flex tw-items-center tw-justify-center'>
                    <span>{I18N.getText('failed')}</span>
                </div>)
            }
            default: {
                return (<div className='tw-rounded-[20px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-py-[2px] tw-bg-status-being-processed-bg tw-flex tw-items-center tw-justify-center'>
                    <span>{I18N.getText('being-processed')}</span>
                </div>)
            }
        }
    }

    handlePageChange(page) {
        let { orderParams } = this.state;
        orderParams.page = page;
        this.getOrderRecord(orderParams)
            .then(() => {
                LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, orderParams);
                this.setState({ orderParams });
            });
    }

    handlePageSizeChange(pageSize) {
        let { orderParams } = this.state;
        orderParams.per_page = pageSize;
        orderParams.page = 1;
        this.getOrderRecord(orderParams)
            .then(() => {
                LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, orderParams);
                this.setState({ orderParams });
            });
    }

    render() {
        const { orderList, orderTotalNum, unpagedUserList, currentUser } = this.props;
        const { orderParams, showLoading, showDeleteConfirmModal, showCancelConfirmModal, showOrderDetailModal, stCharge, iPaddressRisklevel, selectedUser } = this.state;
        const total = Math.ceil(orderTotalNum / orderParams.per_page);
        const isAdmin = UserHelper.isAdminUser(currentUser);
        return (<div className='tw-w-full tw-px-[20px]'>
            {showLoading && <Loading />}
            <DeleteConfirmationModal isDeleteModalOpen={showDeleteConfirmModal} toggle={this.toggleDeleteConfirmModal} handleDeleteConfirm={this.handleUpdateOrderStatus} sectionName={DialogConstants.EDIT_ORDER_STATUS} />
            <DeleteConfirmationModal isDeleteModalOpen={showCancelConfirmModal} toggle={this.toggleCancelConfirmModal} handleDeleteConfirm={this.handleCancelSubscriptionOrder} sectionName={DialogConstants.CANCEL_ORDER_STATUS} />
            {showOrderDetailModal && <div className='tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-bg-[rgba(0,0,0,0.6)] tw-flex tw-justify-center tw-items-center tw-z-[100001]' onClick={this.toggleRiskModal}>
                <div className='tw-w-[40%] tw-max-w-[600px] tw-max-h-[80%] tw-bg-white tw-rounded-[6px] tw-px-[20px] tw-py-[20px]' onClick={(e) => e.stopPropagation()}>
                    <div className='tw-w-full tw-text-center tw-text-[16px] tw-flex tw-items-center tw-justify-center'>
                        <div>{stCharge && (stCharge.outcome.risk_level.charAt(0).toUpperCase() + stCharge.outcome.risk_level.substring(1))} Risk</div>
                        {stCharge && stCharge.outcome.risk_level !== undefined && <div className={classNames(`tw-w-[30px] tw-h-[30px] tw-ml-[10px] tw-rounded-full tw-text-[12px] tw-flex tw-items-center tw-justify-center tw-pt-[1px] tw-bg-[${this.renderRiskScoreBg(stCharge.outcome.risk_score)}]`, { 'tw-text-white': stCharge.outcome.risk_score >= 75 })}>{stCharge.outcome.risk_score}</div>}
                    </div>
                    <div className='tw-w-full tw-flex tw-items-center tw-justify-center tw-mt-[16px] '>
                        <div className='tw-w-[5%] tw-bg-[#6ca329] tw-h-[12px] tw-relative tw-rounded-tl-[6px] tw-rounded-bl-[6px]'>
                            <div className='tw-absolute tw-top-[12px] tw-left-0'>0</div>
                            <div className='tw-absolute tw-top-[12px] tw-right-[-4px]'>5</div>
                        </div>
                        <div className='tw-w-[60%] tw-bg-[#fcbf38] tw-h-[12px] tw-relative'>
                            <div className='tw-absolute tw-top-[12px] tw-right-[-10px]'>65</div>
                        </div>
                        <div className='tw-w-[10%] tw-bg-[#f36c03] tw-h-[12px] tw-relative'>
                            <div className='tw-absolute tw-top-[12px] tw-right-[-10px]'>75</div>
                        </div>
                        <div className='tw-w-[25%] tw-bg-[#8c0432] tw-h-[12px] tw-relative tw-rounded-tr-[6px] tw-rounded-br-[6px]'>
                            <div className='tw-absolute tw-top-[12px] tw-right-[-5px]'>100</div>
                        </div>
                    </div>

                    <div className='tw-mt-[30px]'>Risk factoirs for this payment are compared to payments across the Stripe network.</div>

                    <div className='tw-mt-[30px] tw-text-[20px] tw-font-bold'>Customer details</div>
                    <div className='tw-w-full tw-flex tw-items-center tw-mt-[8px]'>
                        <div className='tw-w-[40%] tw-text-[14px]'>Customer email</div>
                        <div className='tw-w-[60%]'>{stCharge && stCharge.billing_details.email}</div>
                    </div>
                    <div className='tw-w-full tw-flex tw-items-center tw-mt-[8px]'>
                        <div className='tw-w-[40%] tw-text-[14px]'>Name</div>
                        <div className='tw-w-[60%]'>{stCharge && stCharge.billing_details.name}</div>
                    </div>
                    <div className='tw-w-full tw-flex tw-items-center tw-mt-[8px]'>
                        <div className='tw-w-[40%] tw-text-[14px]'>Phone</div>
                        <div className='tw-w-[60%]'>{stCharge && stCharge.billing_details.phone}</div>
                    </div>
                    <div className='tw-w-full tw-flex tw-items-center tw-mt-[8px]'>
                        <div className='tw-w-[40%] tw-text-[14px]'>Billing address</div>
                        <div className='tw-w-[60%]'>{stCharge && `${stCharge.billing_details.address.country}  ${stCharge.billing_details.address.line1} ${stCharge.billing_details.address.postal_code}`}</div>
                    </div>


                    <div className='tw-mt-[30px] tw-text-[20px] tw-font-bold'>Checkout details</div>
                    <div className='tw-w-full tw-flex tw-items-center tw-mt-[8px]'>
                        <div className='tw-w-[40%] tw-text-[14px]'>IP address</div>
                        <div className='tw-w-[60%]'>{iPaddressRisklevel && iPaddressRisklevel.ip}</div>
                    </div>
                    <div className='tw-w-full tw-flex tw-items-center tw-mt-[8px]'>
                        <div className='tw-w-[40%] tw-text-[14px]'>IP address detail</div>
                        <div className='tw-w-[60%]'>{iPaddressRisklevel && `${iPaddressRisklevel.country} ${iPaddressRisklevel.region} ${iPaddressRisklevel.city}`}</div>
                    </div>
                    {/* <div className='tw-w-full tw-flex tw-items-center tw-mt-[8px]'>
                        <div className='tw-w-[40%] tw-text-[14px]'>Host</div>
                        <div className='tw-w-[60%]'>{iPaddressRisklevel && iPaddressRisklevel.hostname}</div>
                    </div> */}
                    <div className='tw-w-full tw-flex tw-items-center tw-mt-[8px]'>
                        <div className='tw-w-[40%] tw-text-[14px]'>Longitude and latitude</div>
                        <div className='tw-w-[60%]'>{iPaddressRisklevel && iPaddressRisklevel.loc}</div>
                    </div>
                    <div className='tw-w-full tw-flex tw-items-center tw-mt-[8px]'>
                        <div className='tw-w-[40%] tw-text-[14px]'>Internet service provider</div>
                        <div className='tw-w-[60%]'>{iPaddressRisklevel && iPaddressRisklevel.asn ? `${iPaddressRisklevel.asn.name} ${iPaddressRisklevel.asn.domain}` : ''}</div>
                    </div>
                    <div className='tw-w-full tw-flex tw-items-center tw-mt-[8px]'>
                        <div className='tw-w-[40%] tw-text-[14px]'>VPN</div>
                        <div className='tw-w-[60%]'>{iPaddressRisklevel && `${iPaddressRisklevel.vpn ? 'Yes' : 'No'}`}</div>
                    </div>
                    <div className='tw-w-full tw-flex tw-items-center tw-mt-[8px]'>
                        <div className='tw-w-[40%] tw-text-[14px]'>Proxy</div>
                        <div className='tw-w-[60%]'>{iPaddressRisklevel && `${iPaddressRisklevel.proxy ? 'Yes' : 'No'}`}</div>
                    </div>
                    <div className='tw-w-full tw-flex tw-items-center tw-mt-[8px]'>
                        <div className='tw-w-[40%] tw-text-[14px]'>User Agent</div>
                        <div className='tw-w-[60%] tw-text-[12px]'>{iPaddressRisklevel && iPaddressRisklevel.user_agent}</div>
                    </div>

                    
                </div>
            </div>}
            <div className='tw-mx-auto tw-w-full tw-mt-[10px]'>
                <div className='tw-flex tw-items-center tw-justify-between'>
                    <div className='tw-flex tw-w-full'>
                        {ORDER_FILTER_TYPE.map(item => {
                            return <div key={item.id} className={classnames('tw-h-[40px] tw-flex tw-justify-center tw-items-center tw-rounded-[10px] tw-text-body-text-1 tw-text-[14px] tw-font-bold tw-mr-[30px] tw-text-[#717579]', { '!tw-text-brand-primary-light': orderParams.status === item.id })} onClick={() => this.handleOrderFilterClick(item)}>{I18N.getText(item.text)}</div>
                        })}
                    </div>
                    <div className='tw-mt-[10px] tw-w-full tw-flex tw-justify-center tw-items-center'>
                        {/*  */}
                        {isAdmin && <Select
                            className="tw-bg-white tw-min-w-[320px] tw-rounded-[30px] tw-px-[20px] tw-shadow-xl placeholder:tw-font-normal placeholder:tw-text-[12px] hs-role-selector-container"
                            // components={{ ClearIndicator: this.customClearIndicatorWithClass('tw-text-brand-primary') }}
                            styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                            value={selectedUser}
                            placeholder={I18N.getText('st-group-placeholder')}
                            onChange={this.handleSelectedUser}
                            isClearable={true}
                            name="stGroup"
                            options={unpagedUserList}
                            // isMulti
                        />}
                        <div className='tw-ml-[30px] tw-relative tw-w-[300px] tw-flex tw-items-center'>
                            <Input
                                type="text"
                                placeholder={I18N.getText('order-email-search')}
                                className="tw-border-none tw-w-[300px] tw-h-[35px] tw-rounded-[30px] tw-py-[5px] tw-px-[20px] tw-text-[14px] custom-input tw-shadow-xl"
                                onChange={(e) => this.handleOrderSearchTextChange(e, 'email')}
                                value={orderParams.email} />
                            <SearchIcon color={ColorConstants.black[0]} size={20} className="tw-w-[20px] tw-h-[20px] tw-absolute tw-right-[20px] tw-top-[8px]" onClick={this.searchNewOrderRecord} />
                        </div>

                        <div className='tw-ml-[30px] tw-relative tw-w-[300px]'>
                            <Input
                                type="text"
                                placeholder={I18N.getText('order-aisle-search')}
                                className="tw-border-none tw-w-[300px] tw-h-[35px] tw-rounded-[30px] tw-py-[5px] tw-px-[20px] tw-text-[14px] custom-input tw-shadow-xl"
                                onChange={(e) => this.handleOrderSearchTextChange(e, 'comment')}
                                value={orderParams.comment} />
                            <SearchIcon color={ColorConstants.black[0]} size={20} className="tw-w-[20px] tw-h-[20px] tw-absolute tw-right-[20px] tw-top-[8px]" onClick={this.searchNewOrderRecord} />
                        </div>
                    </div>
                </div>
                <div className={classnames('tw-w-full tw-mt-[20px] tw-bg-white tw-rounded-[10px]', { 'tw-px-[20px] tw-py-[10px]': orderTotalNum > 0 })}>
                    {orderTotalNum > 0 && <div className='tw-w-full hs-order-table-container tw-py-[10px] '>
                        <div className='tw-flex tw-justify-between tw-items-center tw-text-center tw-font-bold tw-text-[12px] tw-mb-[10px] tw-h-[40px] tw-border tw-border-border-gray tw-rounded-[10px] tw-shadow-table'>
                            <div className='tw-w-[15%]'>{I18N.getText('mall-order-url')}</div>
                            <div className='tw-w-[15%]'>{I18N.getText('mall-payment-comment')}</div>
                            <div className='tw-w-[15%]'>{I18N.getText('order-email')}</div>
                            <div className='tw-w-[10%]'>{I18N.getText('amount')}</div>
                            <div className='tw-w-[15%]'>{I18N.getText('status')}</div>
                            <div className='tw-w-[15%]'>{I18N.getText('date')}</div>
                            <div className='tw-w-[15%]'>{I18N.getText('operation')}</div>
                        </div>
                        <div className='tw-w-full tw-text-[12px]'>
                            {orderList && orderList.map(record => {
                                return (<div className='tw-h-[50px] tw-flex tw-justify-between tw-items-center tw-mb-[10px] hs-table-row tw-border tw-border-border-gray tw-rounded-[10px] tw-shadow-table tw-relative'>
                                    <div className='tw-w-[15%]'>
                                        <div className='tw-flex tw-justify-center tw-items-center tw-px-[10px]'>
                                            <span className='tw-text-[12px] tw-ml-[5px] hs-td-cell'>{record.url}</span>
                                        </div>
                                    </div>
                                    <div className='tw-w-[15%]'>
                                        <div className='tw-flex tw-justify-center tw-items-center tw-px-[10px]'>
                                            <div className='tw-max-w-[calc(100%-30px)] tw-text-[12px] tw-ml-[10px] hs-td-cell'>{record.comment}</div>
                                        </div>
                                    </div>

                                    <div className='tw-w-[15%]'>
                                        <div className='tw-flex tw-justify-center tw-items-center'>
                                            <span className='tw-text-[12px] hs-td-cell'>{record.email}</span>
                                        </div>
                                    </div>
                                    <div className='tw-w-[10%]'>
                                        <div className='tw-flex tw-justify-center tw-items-center'>
                                            <span className='tw-text-[16px]'>{getCurrencySymbol(record.curcrency)}</span>
                                            <span className='tw-text-[12px] tw-ml-[5px] tw-font-bold  hs-td-cell'>{record.amount}</span>
                                        </div>
                                    </div>
                                    <div className='tw-w-[15%]'>
                                        <div className='tw-flex tw-justify-center tw-item-center tw-text-white'>{this.renderOrderStatus(record.is_notify, record.status)}</div>
                                    </div>
                                    <div className='tw-w-[15%]'>
                                        <div className='tw-flex tw-justify-center tw-items-center'>
                                            <CalendarIcon size={24} color={ColorConstants.black[0]} />
                                            <span className='tw-text-[12px] tw-ml-[5px]  hs-td-cell'>{record.updatetime}</span>
                                        </div>
                                    </div>
                                    <div className='tw-w-[15%] tw-flex tw-justify-center tw-items-center'>
                                        <div className='tw-w-[100px] tw-flex tw-items-center tw-relative'>
                                            {record.status === 1 && record.paid !== null && <div className={`tw-flex tw-justify-center tw-items-center tw-mr-[6px] tw-group`} >
                                                <SvgIcon size={20} color={renderSubscriptionIconStatus(record.paid)}  name="subscription-status-icon" className="tw-z-[10]"/>
                                                <div className="tw-z-[100002] tw-absolute tw-top-[20px] tw-left-1/2 tw-translate-x-[-50%] tw-mt-2 tw-w-32 tw-bg-gray-800 tw-text-white tw-text-sm tw-py-1 tw-px-2 tw-rounded tw-shadow-lg tw-hidden tw-transition-all tw-duration-300 group-hover:tw-inline-block tw-text-center">
                                                    {renderSubscriptionStatus(record.paid)}
                                                </div>
                                            </div>}
                                            <div className={`tw-flex tw-justify-center tw-items-center tw-mr-[6px]`} onClick={() => this.handlePreviewOrderDetail(record.id)} >
                                                <SvgIcon size={20} color={ColorConstants.brand.primary} name="radar-icon" />
                                            </div>
                                            <div className={`tw-flex tw-justify-center tw-items-center tw-mr-[6px]`} onClick={() => this.routeToOrderDetailPage(record.client_orderNo, record)} >
                                                <SvgIcon size={20} color={ColorConstants.brand.primary} name="eye-icon" />
                                            </div>
                                            <div className={`tw-flex tw-justify-center tw-items-center tw-mr-[6px]`} onClick={() => this.handlePreviewOrderStatusWindow(record.id)} >
                                                <SvgIcon size={20} color={ColorConstants.brand.primary} name="edit-icon" />
                                            </div>
                                            {record.status === 1 && <div className={`tw-flex tw-justify-center tw-items-center`} onClick={() => this.handleCancelOrder(record)} >
                                                <SvgIcon size={18} color={ColorConstants.brand.primary} name="cancel-order-icon" />
                                            </div>}
                                            {record.tuikuancs > 0 && <SvgIcon size={28} color={ColorConstants.brand.primary} name="refund-order-icon" className="tw-ml-[10px]" onMouseEnter={this.handleMouseEvent} onMouseLeave={this.handleMouseLeave} />}
                                        </div>
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div>}
                </div>
                {orderTotalNum === 0 && <div className='tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center'>
                    <SvgIcon size={200} name="non-record-icon" color={ColorConstants.brand.primary} />
                    <div className='tw-mt-[20px] tw-text-[16px] tw-font-medium'>{I18N.getText('no-records')}</div>
                </div>}
                {orderTotalNum > 0 && <div className='tw-w-full tw-flex tw-justify-center tw-mt-[20px]'>
                    <Pagination total={orderTotalNum} pageSize={orderParams.per_page} currentPage={orderParams.page} layout='sizes,prev,pager,next,jumper,->,total' pageSizes={[10, 20, 30, 50]} onCurrentChange={this.handlePageChange} onSizeChange={this.handlePageSizeChange} />
                </div>}
            </div>
        </div>);
    }
}

function mapStateToProps(state) {
    return {
        orderList: OrderSelectors.selectOrderList(state),
        orderTotalNum: OrderSelectors.selectOrderRecordTotalNum(state),
        unpagedUserList: UserSelectors.selectUnpagedUserListForSelector(state),
        currentUser: UserSelectors.selectCurrentUser(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        orderActions: bindActionCreators(orderActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderPage);