import React from 'react';
import Colors from '../constants/ColorConstants';

const ClockIcon = ({
  size = 24,
  color = Colors.icon.default,
  viewBox = '0 0 24 24',
  ...props
}) => (
  <svg viewBox="0 0 1024 1024" p-id="2767" width={size} height={size} {...props}>
    <path d="M512 192C332.8 192 192 332.8 192 512s140.8 320 320 320 320-140.8 320-320S691.2 192 512 192zM512 768c-140.8 0-256-115.2-256-256s115.2-256 256-256 256 115.2 256 256S652.8 768 512 768z" fill="#272636" p-id="2768"></path><path d="M544 499.2 544 320c0-19.2-12.8-32-32-32S480 300.8 480 320l0 192c0 6.4 6.4 19.2 6.4 25.6l102.4 102.4c6.4 6.4 12.8 6.4 25.6 6.4s19.2 0 25.6-6.4c12.8-12.8 12.8-32 0-44.8L544 499.2z" fill="#272636" p-id="2769"></path><path d="M364.8 192C364.8 185.6 358.4 179.2 358.4 172.8l0 0C320 140.8 256 140.8 217.6 172.8L172.8 217.6C140.8 256 140.8 320 172.8 358.4 179.2 358.4 185.6 364.8 192 364.8 224 288 288 224 364.8 192z" fill="#272636" p-id="2770"></path><path d="M851.2 217.6l-44.8-44.8c-38.4-38.4-96-38.4-134.4 0-6.4 6.4-6.4 12.8-12.8 19.2 76.8 38.4 140.8 96 172.8 172.8 6.4-6.4 12.8-6.4 19.2-12.8C883.2 320 883.2 256 851.2 217.6z" fill="#272636" p-id="2771"></path><path d="M262.4 780.8l-25.6 25.6c-12.8 12.8-12.8 32 0 44.8 6.4 6.4 12.8 6.4 25.6 6.4s19.2 0 25.6-6.4l25.6-25.6c12.8-12.8 12.8-32 0-44.8S275.2 768 262.4 780.8z" fill="#272636" p-id="2772"></path><path d="M761.6 780.8c-12.8-12.8-32-12.8-44.8 0s-12.8 32 0 44.8l25.6 25.6c6.4 6.4 12.8 6.4 25.6 6.4s19.2 0 25.6-6.4c12.8-12.8 12.8-32 0-44.8L761.6 780.8z" fill={color} p-id="2773"></path>
  </svg>
);

export default ClockIcon;
