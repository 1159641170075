import React from "react";

const CountryFlag = ({ countryCode, size = 32 }) => {
  if (!countryCode) return null;

  // 通过 FlagCDN 获取国旗
  const flagUrl = `https://flagcdn.com/${size}x${size*0.75}/${countryCode.toLowerCase()}.png`;

  return (
    <img
      src={flagUrl}
      alt={`Flag of ${countryCode}`}
      width={size}
      height={size}
    />
  );
};

export default CountryFlag;