import React, { Component } from 'react';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import Pagination from '@material-ui/lab/Pagination';

// --- Components --- //
import Loading from '../../components/common/LoadingIndicator';
import SvgIcon from '../../components/common/SvgIcon';
import EDSInput from '../../components/common/EDSInput';

// --- Icons --- //
import SearchIcon from '../../icons/SearchIcon';
import CommentIcon from '../../icons/CommentIcon';
import WebsiteIcon from '../../icons/WebsiteIcon';
import DollarIcon from '../../icons/DollarIcon';
import CalendarIcon from '../../icons/CalendarIcon';

// --- Constants --- //
import ColorConstants from '../../constants/ColorConstants';

// --- Actions --- //
import * as stGroupActionCreator from '../../actions/stGroupActionCreator';
import * as basicConfigActionCreator from '../../actions/basicConfigActionCreator';
import * as userActionCreator from '../../actions/userActionCreator';

// --- Selectors --- //
import * as StGroupSelectors from '../../selectors/StGroupSelectors';
import * as UserSelectors from '../../selectors/UserSelectors';

import I18N from '../../i18n';
import RDSButton from '../../components/common/RDSButton';
import PagePathConstants from '../../constants/router/PagePathConstants';
import LocalStorageConstants from '../../constants/LocalStorageConstants';
import LocalStorageUtil from '../../utils/LocalStorageUtil';

const CACHED_PARAM_KEY = LocalStorageConstants.ST_GROUP_CACHED_PARAM;
class STGroupPage extends Component {
  constructor(props, context) {
    super(props, context);

    const cachedParam = LocalStorageUtil.loadCachedParam(CACHED_PARAM_KEY);
    this.state = {
      stGroupListParams: cachedParam ? cachedParam : {
        page: 1,
        per_page: 5,
        keywords: ''
      },
      currencyList: null,
      showLoading: false,
    };

    this.getSTGroupList = this.getSTGroupList.bind(this);
    this.handleStGroupFilterClick = this.handleStGroupFilterClick.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.handleNameFilterChange = this.handleNameFilterChange.bind(this);
    this.handleEditStGroup = this.handleEditStGroup.bind(this);
    this.createNewSdGroup = this.createNewSdGroup.bind(this);
    this.fetchCurrencyList = this.fetchCurrencyList.bind(this);
  }

  componentDidMount() {
    this.fetchCurrencyList();
    this.getSTGroupList();
  }

  fetchCurrencyList() {
    const { basicConfigActions } = this.props;
    this.toggleLoading()
    basicConfigActions && basicConfigActions.fetchCurrencyList()
      .then((data) => {
        const currencyList = data && data.map(item => {
          return {
            value: item.code,
            label: item.currency
          }
        });
        console.error(currencyList)
        this.setState({ currencyList });
      })
      .catch(() => {
        this.toggleLoading();
      });
  }


  handleEditStGroup(id) {
    const { history } = this.props;
    history && history.push(PagePathConstants.ST_GROUP_EDIT.replace(':id', id));
  }

  toggleLoading() {
    this.setState({ showLoading: !this.state.showLoading });
  }

  handleNameFilterChange(e) {
    const name = e && e.target.value;
    const { stGroupListParams } = this.state;
    stGroupListParams.keywords = name;
    this.setState({ stGroupListParams });
  }

  getSTGroupList(params) {
    if (!params) {
      params = this.state.stGroupListParams;
    }
    const { stGroupActions } = this.props;
    this.toggleLoading();
    return stGroupActions && stGroupActions.getStGroupList(params)
      .then(data => {
        this.toggleLoading();
        return;
      });
  }

  handleStGroupFilterClick() {
    let { stGroupListParams } = this.state;
    stGroupListParams.page = 1;
    this.setState({ stGroupListParams }, () => {
      LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, stGroupListParams);
    });
    this.getSTGroupList(stGroupListParams);
  }

  handlePageChange(e, page) {
    let { stGroupListParams } = this.state;
    stGroupListParams.page = page;
    this.getSTGroupList(stGroupListParams)
      .then(() => {
        LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, stGroupListParams);
        this.setState({ stGroupListParams });
      });
  }

  createNewSdGroup() {
    const { history } = this.props;
    history && history.push(PagePathConstants.ST_GROUP_ADD);
  }


  render() {
    const { stGroupList, stGroupTotalNum, unpagedUserList } = this.props;
    const { stGroupListParams, showLoading } = this.state;
    const total = Math.ceil(stGroupTotalNum / stGroupListParams.per_page);
    return (<div className='tw-w-full tw-px-[50px]'>
      {showLoading && <Loading />}
      <div className='tw-w-full tw-mt-[30px]'>
        <div className='tw-flex tw-justify-between tw-items-center tw-w-full tw-mt-[20px] hs-userlist-filter-container'>
          <div className='tw-relative'>
            <EDSInput className="tw-w-[450px] tw-py-[12px] tw-rounded-[30px] tw-px-[20px] tw-border-none tw-shadow-xl"
              value={stGroupListParams.keywords}
              type="text"
              placeholder={I18N.getText('keywords-placeholder')}
              onChange={this.handleNameFilterChange}
            />
            <SearchIcon size={24} color={ColorConstants.black[0]} className="tw-absolute tw-right-[20px] tw-bottom-[12px] tw-w-[24px] tw-h-[24px]" onClick={this.handleStGroupFilterClick} />
          </div>
          <div className='tw-w-[300px] tw-flex tw-justify-end tw-items-center'>
            {/* <RDSButton className="tw-text-white tw-mr-[30px]" onClick={this.handleStGroupFilterClick}>{I18N.getText('search')}</RDSButton> */}
            <RDSButton className="tw-text-white" onClick={this.createNewSdGroup}>Create</RDSButton>
          </div>
        </div>

        {stGroupList && stGroupList.length > 0 && <React.Fragment>
          <div className='tw-w-full tw-mt-[30px]'>

            <div className='tw-w-full hs-users-table-container'>
              <div className='tw-flex tw-justify-between tw-items-center tw-text-center tw-font-bold tw-text-[14px] tw-mb-[20px] tw-rounded-[30px] tw-bg-white tw-h-[80px] tw-shadow-xl'>
                <div className='tw-w-[30%]'>{I18N.getText('group-name')}</div>
                <div className='tw-w-[40%]'>{I18N.getText('group-desc')}</div>
                <div className='tw-w-[40%]'>{I18N.getText('currency')}</div>
                <div className='tw-w-[40%]'>{I18N.getText('user')}</div>
                <div className='tw-w-[20%]'>{I18N.getText('date')}</div>
                <div className='tw-w-[10%]'>{I18N.getText('operation')}</div>
              </div>
              <div className='tw-w-full tw-text-[14px]'>
                {stGroupList && stGroupList.map(record => {
                  const filterCurrency = this.state.currencyList && this.state.currencyList.filter(item => item.value === record.bizhong_code)[0]
                  const filterUser = unpagedUserList && unpagedUserList.filter(item => item.value === record.user_id)[0]
                  
                  return (<div className='tw-rounded-[30px] tw-h-[80px] tw-bg-white tw-overflow-hidden tw-flex tw-justify-between tw-items-center tw-text-center tw-mb-[20px] tw-shadow-xl hs-table-row'>
                    <div className='tw-w-[30%] tw-flex tw-justify-center tw-items-center'>
                      <CommentIcon size={24} color={ColorConstants.black[0]} />
                      <span className='tw-text-[14px] tw-ml-[5px] hs-td-cell'>{record.name}</span>
                    </div>
                    <div className='tw-w-[40%] tw-flex tw-justify-center tw-items-center'>
                      <WebsiteIcon size={24} color={ColorConstants.black[0]} />
                      <span className='tw-text-[14px] tw-ml-[5px] hs-td-cell'>{record.remark}</span>
                    </div>
                    <div className='tw-w-[40%] tw-flex tw-justify-center tw-items-center'>
                      <span className='tw-text-[14px] tw-ml-[5px] hs-td-cell'>{filterCurrency && filterCurrency.label}</span>
                    </div>
                    <div className='tw-w-[40%] tw-flex tw-justify-center tw-items-center'>
                      <span className='tw-text-[14px] tw-ml-[5px] hs-td-cell'>{filterUser && filterUser.label}</span>
                    </div>
                    <div className='tw-w-[20%] tw-flex tw-justify-center tw-items-center'>
                      <CalendarIcon size={24} color={ColorConstants.black[0]} className="tw-mb-[2px]" />
                      <span className='tw-text-[14px] tw-ml-[5px] hs-td-cell'>{record.createtime}</span>
                    </div>

                    <div className='tw-w-[10%] tw-flex tw-justify-center tw-items-center'>
                      <SvgIcon onClick={() => this.handleEditStGroup(record.id)} size={20} color={ColorConstants.brand.primary} name="edit-icon" />
                    </div>
                  </div>)
                })}
              </div>
            </div>
          </div>
          <div className='tw-w-full tw-flex tw-justify-center'>
            <Pagination count={total} page={stGroupListParams.page} onChange={this.handlePageChange} variant="outlined" color="primary" />
          </div>
        </React.Fragment>}
        {stGroupList && stGroupList.length === 0 && <div className='tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center'>
          <SvgIcon size={200} name="non-record-icon" color={ColorConstants.brand.primary} />
          <div className='tw-mt-[20px] tw-text-[16px] tw-font-medium'>{I18N.getText('no-records')}</div>
        </div>}
      </div>
    </div>);
  }
}

function mapStateToProps(state) {
  return {
    stGroupList: StGroupSelectors.selectStGroupList(state),
    stGroupTotalNum: StGroupSelectors.selectStGroupRecordTotalNum(state),
    unpagedUserList: UserSelectors.selectUnpagedUserListForSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    stGroupActions: bindActionCreators(stGroupActionCreator, dispatch),
    userActions: bindActionCreators(userActionCreator, dispatch),
    basicConfigActions: bindActionCreators(basicConfigActionCreator, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(STGroupPage);

