import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// --- Components --- //
import RDSButton from "../../components/common/RDSButton";
import SvgIcon from "../../components/common/SvgIcon";
import EDSInput from "../../components/common/EDSInput";
import Loading from "../../components/common/LoadingIndicator";
import ReactFlagsSelect from "react-flags-select";

// --- Constants --- //
import PagePathConstants from "../../constants/router/PagePathConstants";
import ColorConstants from "../../constants/ColorConstants";

// --- Actions --- //
import * as globalAlertActionCreator from "../../actions/globalAlertActionCreator";
import * as countryActionCreator from "../../actions/countryActionCreator";

// --- Utils --- //
import * as UserLocationUtil from "../../utils/UserLocationUtil";

import I18N from "../../i18n";
import classNames from "classnames";
import moment from "moment";
import GlobalAlertConstants from "../../constants/GlobalAlertConstants";

class AccountCountryEditPage extends Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			resetTime: "",
			selectedCountryCode: "US",
		};

		this.toggleLoading = this.toggleLoading.bind(this);
		this.toggleEditing = this.toggleEditing.bind(this);
		this.handleBackPage = this.handleBackPage.bind(this);
		this.editAccountCountryInfo = this.editAccountCountryInfo.bind(this);
		this.onSelectCountry = this.onSelectCountry.bind(this);
		this.handleChangeResetTime = this.handleChangeResetTime.bind(this);
		this.getAccountCountryInfoById = this.getAccountCountryInfoById.bind(this);
	}

	componentDidMount() {
		this.getAccountCountryInfoById();
	}

	getAccountCountryInfoById() {
		const { location, countryActions } = this.props;
		const countryId = location.pathname.split(`${PagePathConstants.ACCOUNT_COUNTRY}/`)[1];
		if (countryId && countryId !== "add") {
			this.toggleLoading();

            console.log(countryActions)
			countryActions
				.fetchAccountCountryDetailById(countryId)
				.then((res) => {
					this.setState({
						resetTime: res.time_country,
						selectedCountryCode: res.country_code,
					});
					this.toggleLoading();
				})
				.catch(() => {
					this.toggleLoading();
				});
		}
	}

	toggleEditing() {
		this.setState({ isEditing: !this.state.isEditing });
	}

	toggleLoading() {
		this.setState({ showLoading: !this.state.showLoading });
	}

	editAccountCountryInfo() {
		const { countryActions, history, location } = this.props;
		const { selectedCountryCode, resetTime } = this.state;
		const countryId = location.pathname.split(`${PagePathConstants.ACCOUNT_COUNTRY}/`)[1];

		const params = {
			country_code: selectedCountryCode,
			time_country: resetTime,
		};
		if (countryId && countryId !== "add") {
			params["id"] = countryId;
		}
		this.toggleEditing();
		countryActions &&
			countryActions
				.createOrEditAccountCountryInfo(params)
				.then(() => {
					history && history.push(PagePathConstants.ACCOUNT_COUNTRY);
				})
				.catch((err) => {
					this.toggleEditing();
				});
	}

	handleBackPage() {
		const { history } = this.props;
		history && history.goBack();
	}

	onSelectCountry(countryCode) {
		this.setState({ selectedCountryCode: countryCode });
	}

	handleChangeResetTime(e) {
		let resetTime = e && e.target.value;
		// 限制只能输入数字和冒号
		resetTime = resetTime.replace(/[^0-9:]/g, "");

		// 允许的格式：
		// 1. 空字符串（允许清空输入）
		// 2. 只有 1-2 位数字（小时部分）
		// 3. 有效的 HH: 格式
		// 4. 有效的 HH:MM: 格式
		// 5. 完整的 HH:MM:SS 格式
		// 24小时制时间格式（支持逐步输入）
        const partialRegex = /^(?:[01]?[0-9]|2[0-3])?(?::[0-5]?[0-9]?)?(?::[0-5]?[0-9]?)?$/;

		if (partialRegex.test(resetTime) || resetTime === "") {
			this.setState({ resetTime, showError: false });
		}
	}

	render() {
		const { isEditing, showLoading, showError, errMsg, selectedCountryCode, resetTime } = this.state;
		const countrySelected = selectedCountryCode !== "";
		return (
			<div className="tw-w-full tw-px-[50px] tw-py-[50px]">
				{showLoading && <Loading />}
				<div className="tw-rounded-[30px] tw-bg-white">
					<div className="tw-flex tw-items-center  tw-px-[30px] tw-py-[24px] tw-border-b" onClick={this.handleBackPage}>
						<SvgIcon name="rds-back-icon" color={ColorConstants.black[0]} size={24} />
						<span className="tw-ml-[10px] tw-text-[18px] tw-font-bold">{I18N.getText("back")}</span>
					</div>
					<div className="tw-text-[14px] tw-px-[30px] tw-py-[30px] tw-flex tw-justify-center tw-flex-col tw-items-center">
						<div className="tw-flex tw-items-center tw-w-[500px] tw-flex tw-justify-between">
							<div className="tw-font-bold">Account Country</div>
							<ReactFlagsSelect className={`country-selector ${countrySelected ? "" : "no-country"} `} selected={selectedCountryCode} placeholder="Select Country or Location" onSelect={this.onSelectCountry} searchable searchPlaceholder="Search Country or location" customLabels={UserLocationUtil.getCountryNames(I18N.getText)} />
						</div>
						<div className="tw-flex tw-items-center tw-mt-[30px] tw-w-[500px] tw-flex tw-justify-between">
							<div className="tw-font-bold">Account Reset Time</div>
							<EDSInput className={classNames(`tw-w-[300px] tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`)} placeholder="Please enter the reset time (HH:MM:SS)" value={resetTime || ""} onChange={this.handleChangeResetTime} />
						</div>
						{showError && <div className="tw-mt-[20px] tw-text-[14px] tw-text-error-text">{errMsg}</div>}
						<div className="tw-flex tw-justify-center tw-items-center tw-mt-[30px]">
							<RDSButton loading={isEditing} disabled={isEditing || !selectedCountryCode || resetTime.trim() === ""} onClick={this.editAccountCountryInfo} className="tw-text-white">
								{I18N.getText("edit")}
							</RDSButton>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {};
}

function mapDispatchToProps(dispatch) {
	return {
		countryActions: bindActionCreators(countryActionCreator, dispatch),
		globalAlertActions: bindActionCreators(globalAlertActionCreator, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountCountryEditPage);
