import React, { Component } from "react";
import { connect } from "react-redux";
import Select from 'react-select';
import { bindActionCreators } from "redux";
import { Pagination } from "element-react";

// --- Components --- //
import Loading from "../../components/common/LoadingIndicator";
import SvgIcon from "../../components/common/SvgIcon";

// --- Constants --- //
import ColorConstants from "../../constants/ColorConstants";
import DialogConstants from "../../constants/dialog/index";

// --- Actions --- //
import * as stCardActionCreator from "../../actions/stCardActionCreator";
import * as basicConfigActionCreator from "../../actions/basicConfigActionCreator";
import * as orderActionCreator from "../../actions/orderActionCreator";
import * as productActionCreator from "../../actions/productActionCreator";
import * as productCategoryActionCreator from "../../actions/productCategoryActionCreator";

// --- Selectors --- //
import * as UserSelectors from "../../selectors/UserSelectors";
import * as ProductSelectors from "../../selectors/ProductSelectors";
import * as ProductCategorySelectors from "../../selectors/ProductCategorySelectors";

import I18N from "../../i18n";
import RDSButton from "../../components/common/RDSButton";
import PagePathConstants from "../../constants/router/PagePathConstants";
import DeleteConfirmationModal from "../../components/common/dialog/DeleteConfirmationModal";
import LocalStorageConstants from "../../constants/LocalStorageConstants";
import LocalStorageUtil from "../../utils/LocalStorageUtil";
import * as DateTimeUtil from "../../utils/DateTimeUtil";
import UserHelper from "../../helpers/UserHelper";

const CACHED_PARAM_KEY = LocalStorageConstants.PRODUCT_PARAM;
class STCardPage extends Component {
	constructor(props, context) {
		super(props, context);
		const cachedParam = LocalStorageUtil.loadCachedParam(CACHED_PARAM_KEY);
		this.state = {
			productListParams: cachedParam ?
			cachedParam : {
				page: 1,
				per_page: 20,
				zhandianfenleiid: ''
			},
			selectedCategory: null,
			showLoading: false,
			showDeleteConfirmModal: false,
			deleteItemId: "",
			cancelItem: "",
		};

		this.getProductList = this.getProductList.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.toggleLoading = this.toggleLoading.bind(this);
		this.handleEditProduct = this.handleEditProduct.bind(this);
		this.handleDeleteProduct = this.handleDeleteProduct.bind(this);
		this.createNewProduct = this.createNewProduct.bind(this);
		this.toggleDeleteConfirmModal = this.toggleDeleteConfirmModal.bind(this);
		this.handleDeleteProductConfirmation = this.handleDeleteProductConfirmation.bind(this);
		this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
		this.fetchCategoryList = this.fetchCategoryList.bind(this);
		this.handleSelectedCategory = this.handleSelectedCategory.bind(this);
		this.resetQueryParamsByCachedData = this.resetQueryParamsByCachedData.bind(this);
	}

	componentDidMount() {
		this.fetchCategoryList();
		
	}

	resetQueryParamsByCachedData(props) {
        const cachedParam = LocalStorageUtil.loadCachedParam(CACHED_PARAM_KEY);
        if (cachedParam && cachedParam.zhandianfenleiid !== '') {
          const {productListParams} = this.state;
          const {unpagedCategoryList} = (props || this.props);
          const filterCategories = unpagedCategoryList.filter((user) => {
            return user.value === productListParams.zhandianfenleiid;
          });
          if (filterCategories.length > 0) {
            this.setState({selectedCategory: filterCategories[0]});
          }
        }
      }

	fetchCategoryList() {
        const { productCategoryActions } = this.props;
        this.toggleLoading();
        productCategoryActions && productCategoryActions.fetchUnpagedProductCategoryList()
            .then((data) => {
				this.setState({showLoading: false}, () => {
					this.resetQueryParamsByCachedData();
					this.getProductList();
				});
            })
            .catch(() => {
                this.toggleLoading();
            });
    }

	toggleDeleteConfirmModal() {
		this.setState({ showDeleteConfirmModal: !this.state.showDeleteConfirmModal });
	}

	handleEditProduct(id) {
		const { history } = this.props;
		history && history.push(PagePathConstants.PRODUCT_EDIT.replace(":id", id));
	}

	handleDeleteProduct(id) {
		this.toggleDeleteConfirmModal();
		this.setState({ deleteItemId: id });
	}

	handleDeleteProductConfirmation() {
		const { productActions } = this.props;
		const { deleteItemId } = this.state;

		productActions &&
			deleteItemId &&
			deleteItemId !== "" &&
			productActions
				.deleteProduct(this.state.deleteItemId)
				.then((data) => {
					this.toggleDeleteConfirmModal();
					this.getProductList();
					return;
				})
				.catch(this.toggleDeleteConfirmModal);
	}

	toggleLoading() {
		this.setState({ showLoading: !this.state.showLoading });
	}

	getProductList(params) {
		if (!params) {
			params = this.state.productListParams;
		}
		const { productActions } = this.props;
		this.toggleLoading();
		return (
			productActions &&
			productActions
				.fetchProductList(params)
				.then((data) => {
					this.toggleLoading();
					return;
				})
				.catch(this.toggleLoading)
		);
	}

	handlePageChange(page) {
		let { productListParams } = this.state;
		productListParams.page = page;
		this.getProductList(productListParams).then(() => {
			LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, productListParams);
			this.setState({ productListParams });
		});
	}

	createNewProduct() {
		const { history } = this.props;
		history && history.push(PagePathConstants.PRODUCT_ADD);
	}

	handlePageSizeChange(pageSize) {
		let { productListParams } = this.state;
		productListParams.per_page = pageSize;
		productListParams.page = 1;
		this.getProductList(productListParams).then(() => {
			LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, productListParams);
			this.setState({ productListParams });
		});
	}


    handleSelectedCategory(selectedCategory) {
        let productListParams = Object.assign({}, this.state.productListParams);
        if (selectedCategory) {
            productListParams.zhandianfenleiid = selectedCategory.value;
        } else {
			//删除
			productListParams.zhandianfenleiid = '';
		}
		productListParams.page = 1;
        LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, productListParams);
        this.setState({
            selectedCategory,
            productListParams,
            showError: false
        }, this.getProductList);
    }

	render() {
		const { productList, productTotalNum, currentUser, unpagedCategoryList} = this.props;
		const { productListParams, showLoading, showDeleteConfirmModal, selectedCategory } = this.state;
		const total = Math.ceil(productTotalNum / productListParams.per_page);
		const isAdmin = UserHelper.isAdminUser(currentUser);
		return (
			<div className="tw-w-full tw-px-[20px]">
				{showLoading && <Loading />}
				<DeleteConfirmationModal isDeleteModalOpen={showDeleteConfirmModal} toggle={this.toggleDeleteConfirmModal} handleDeleteConfirm={this.handleDeleteProductConfirmation} sectionName={DialogConstants.PRODUCT} />
				<div className="tw-w-full tw-mt-[30px]">
					<div className="tw-w-full tw-flex tw-justify-end tw-items-center tw-w-full tw-mt-[20px] hs-userlist-filter-container">
						<Select
							className="tw-bg-white tw-w-[400px] tw-rounded-[30px] tw-px-[20px] tw-shadow-xl placeholder:tw-font-normal placeholder:tw-text-[12px] hs-role-selector-container"
							styles={{ dropdownIndicator: (base) => ({ ...base, color: ColorConstants.brand.primary }) }}
							value={selectedCategory}
							placeholder={"Please select the product category"}
							onChange={this.handleSelectedCategory}
							isClearable={true}
							name="productCategory"
							options={unpagedCategoryList}
						/>

						<div className="tw-ml-[30px]">
							{isAdmin && (
								<div className="tw-flex tw-justify-end tw-items-center tw-ml-[30px]">
									<RDSButton className="tw-text-white" onClick={this.createNewProduct}>
										Create
									</RDSButton>
								</div>
							)}
						</div>
					</div>

					{productList && productList.length > 0 && (
						<React.Fragment>
							<div className="tw-w-full tw-mt-[30px]">
								<div className="tw-w-full hs-users-table-container tw-bg-white tw-rounded-[20px] tw-overflow-hidden">
									<div className="tw-flex tw-justify-between tw-items-center tw-text-center tw-font-bold tw-text-[14px] tw-mb-[20px] tw-rounded-[30px] tw-bg-white tw-h-[60px] ">
										<div className="tw-w-[25%]">Product name</div>
										<div className="tw-w-[20%]">Product category</div>
										<div className="tw-w-[25%]">Chinese product name</div>
										<div className="tw-w-[10%]">Date</div>
										<div className="tw-w-[20%]">Operation</div>
									</div>
									<div className="tw-w-full tw-text-[12px]">
										{productList &&
											productList.map((record) => {
												return (
													<div className="tw-h-[50px] tw-bg-white tw-overflow-hidden tw-flex tw-justify-between tw-items-center tw-text-center tw-mb-[10px]  hs-table-row">
														<div className="tw-w-[25%] tw-flex tw-justify-center tw-items-center">
															<span className="tw-text-[12px] tw-ml-[5px] hs-td-cell">{record.product_name}</span>
														</div>
														<div className="tw-w-[20%] tw-flex tw-justify-center tw-items-center tw-font-bold hs-td-cell">{record.fenlei}</div>
														<div className="tw-w-[25%] tw-flex tw-justify-center tw-items-center hs-td-cell">{record.zhongwen}</div>
														<div className="tw-w-[10%] tw-flex tw-justify-center tw-items-center hs-td-cell">{DateTimeUtil.showFullTimeFormat(record.updatetime * 1000)}</div>
														<div className="tw-w-[20%] tw-flex tw-justify-center tw-items-center">
															{isAdmin && <SvgIcon onClick={() => this.handleEditProduct(record.id)} size={20} color={ColorConstants.brand.primary} name="edit-icon" className="tw-mr-[6px]" />}
															{isAdmin && <SvgIcon onClick={() => this.handleDeleteProduct(record.id)} size={20} color={ColorConstants.brand.primary} name="delete-icon" />}
														</div>
													</div>
												);
											})}
									</div>
								</div>
							</div>
							{productTotalNum > 0 && (
								<div className="tw-w-full tw-flex tw-justify-center tw-mt-[20px]">
									<Pagination total={productTotalNum} pageSize={productListParams.per_page} currentPage={productListParams.page} layout="sizes,prev,pager,next,jumper,->,total" pageSizes={[10, 20, 30, 50]} onCurrentChange={this.handlePageChange} onSizeChange={this.handlePageSizeChange} />
								</div>
							)}
						</React.Fragment>
					)}
					{productList && productList.length === 0 && (
						<div className="tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center">
							<SvgIcon size={200} name="non-record-icon" color={ColorConstants.brand.primary} />
							<div className="tw-mt-[20px] tw-text-[16px] tw-font-medium">{I18N.getText("no-records")}</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		currentUser: UserSelectors.selectCurrentUser(state),
		productList: ProductSelectors.selectProductList(state),
		productTotalNum: ProductSelectors.selectProductRecordTotalNum(state),
		unpagedCategoryList: ProductCategorySelectors.selectUnpagedProductCategoryList(state),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		productActions: bindActionCreators(stCardActionCreator, dispatch),
		basicConfigActions: bindActionCreators(basicConfigActionCreator, dispatch),
		orderActions: bindActionCreators(orderActionCreator, dispatch),
		productActions: bindActionCreators(productActionCreator, dispatch),
		productCategoryActions: bindActionCreators(productCategoryActionCreator, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(STCardPage);
