import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';

// --- Components --- //
import RDSButton from '../../components/common/RDSButton';
import SvgIcon from '../../components/common/SvgIcon';
import EDSInput from '../../components/common/EDSInput';
import Loading from '../../components/common/LoadingIndicator';

// --- Utils --- //
import * as FormValidationsUtil from '../../utils/FormValidationsUtil';

// --- Selectors --- //
import * as ProductCategorySelectors from '../../selectors/ProductCategorySelectors'
import * as AccountCountrySelectors from '../../selectors/AccountCountrySelectors'

// --- Constants --- //
import PagePathConstants from '../../constants/router/PagePathConstants';
import ColorConstants from '../../constants/ColorConstants';

// --- Actions --- //
import * as stCardActionCreator from '../../actions/stCardActionCreator';
import * as stGroupActionCreator from '../../actions/stGroupActionCreator';
import * as productCategoryActionCreator from '../../actions/productCategoryActionCreator';
import * as countryActionCreator from '../../actions/countryActionCreator';

import I18N from '../../i18n';
import classNames from 'classnames';

const SD_CARD_STATUS_LIST = [
    { value: 2, label: I18N.getText('t7') },
    { value: 1, label: I18N.getText('t2') },
    { value: 0, label: I18N.getText('off') },
];

const SUBSCRIPTION_FREQUENCY_LIST = [
    { value: 'day', label: I18N.getText('day') },
    { value: 'week', label: I18N.getText('week') },
    { value: 'month', label: I18N.getText('month') },
    { value: 'year', label: I18N.getText('year') },
];


class UserEditPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            comment: '',
            apiKey: '',
            publicKey: '',
            endpointSecret: '',
            cSiteUrl: '',
            maxPolling: 1,
            maxAmount: 0,
            maxOrder: 0,
            dailyOrder: 0,
            status: SD_CARD_STATUS_LIST[0],
            // level: 0,
            // subscription: 0,
            description: '',
            showError: false,
            showLoading: false,
            errMsg: '',
            errFiled: '',
            isEditing: false,
            selectGroups: null,
            selectFrequency: null,
            selectedCategory: null,
            selectCountry: null,
            stGroupList: [],
        };

        this.handleCommentChange = this.handleCommentChange.bind(this);
        this.handleAPIKeyChange = this.handleAPIKeyChange.bind(this);
        this.handleEndpointSercetChange = this.handleEndpointSercetChange.bind(this);
        this.handleCSiteUrlChange = this.handleCSiteUrlChange.bind(this);
        this.handleMaxAmountChange = this.handleMaxAmountChange.bind(this);
        this.handleMaxOrderChange = this.handleMaxOrderChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleFrequencyChange = this.handleFrequencyChange.bind(this);
        // this.handleLevelChange = this.handleLevelChange.bind(this);
        this.toggleError = this.toggleError.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.toggleEditing = this.toggleEditing.bind(this);
        this.handleBackPage = this.handleBackPage.bind(this);
        this.handlePublicKeyChange = this.handlePublicKeyChange.bind(this);
        this.editSTCardInfo = this.editSTCardInfo.bind(this);
        this.getStGroupList = this.getStGroupList.bind(this);
        this.handleStGroupChange = this.handleStGroupChange.bind(this);
        this.handleDescChange = this.handleDescChange.bind(this);
        this.handleDailyOrderChange = this.handleDailyOrderChange.bind(this);
        this.handleMaxPollingChange = this.handleMaxPollingChange.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.handleAccountCountryChange = this.handleAccountCountryChange.bind(this);
        // this.handleSubscriptionChange = this.handleSubscriptionChange.bind(this);
    }

    componentDidMount() {
        this.getStGroupList();
    }


    handleDescChange(e) {
        const description = e && e.target.value;
        this.setState({ description, showError: false });
    }

    getStGroupList() {
        const { stGroupActions, productCategoryActions, countryActions } = this.props;
        stGroupActions && stGroupActions.getStGroupList()
            .then(data => {
                let newGroupList = [].concat(data.list);
                newGroupList = newGroupList.map(item => {
                    return { value: item.id, label: item.name };
                });
                this.setState({ stGroupList: newGroupList }, () => {
                    countryActions && countryActions.fetchUnpagedAccountCountryList()
                    .then( () => {
                        this.getStCardInfoById();
                    })
                } );
            })
    }


    getStCardInfoById() {
        const { location, stCardActions, unpagedCategoryList, unpagedAccountCountryList, productCategoryActions} = this.props;
        const stCardId = location.pathname.split(`${PagePathConstants.ST}/`)[1];
        productCategoryActions && productCategoryActions.fetchUnpagedProductCategoryList()
        .then(() => {
            if (stCardId && stCardId !== 'add') {
                this.toggleLoading();
                stCardActions.getStCardInfoById(stCardId)
                    .then(res => {
                        const stCardInfo = res;
                        let status = SD_CARD_STATUS_LIST.filter(item => item.value === stCardInfo.status)[0];
                        const { stGroupList } = this.state;
                        const userStGroups = stCardInfo.group_id ? stCardInfo.group_id.toString().split(',') : [];
                        const filterSelectGroups = stGroupList.filter(item => userStGroups.indexOf(item.value.toString()) >= 0);
                        const filterSelectFrequency = SUBSCRIPTION_FREQUENCY_LIST.filter(item => item.value === stCardInfo.frequency);
                        const filterSelectCategory = unpagedCategoryList.filter(item => item.value === stCardInfo.zhandianfenleiid);
                        const filterSelectCountry = unpagedAccountCountryList.filter(item => item.value === stCardInfo.zhuandianId);
                        this.setState({
                            comment: stCardInfo.comment,
                            apiKey: stCardInfo.api_key,
                            publicKey: stCardInfo.api_publishable_key,
                            endpointSecret: stCardInfo.endpoint_secret,
                            cSiteUrl: stCardInfo.c_site_url,
                            maxAmount: stCardInfo.max_money,
                            maxPolling: stCardInfo.lunxun_max,
                            maxOrder: stCardInfo.max_order,
                            dailyOrder: stCardInfo.jishu,
                            description: stCardInfo.description,
                            status: status,
                            selectFrequency: filterSelectFrequency.length > 0 ? filterSelectFrequency[0] : this.state.selectFrequency,
                            selectedCategory: filterSelectCategory.length > 0 ? filterSelectCategory[0] : this.state.selectedCategory,
                            // level: stCardInfo.level,
                            // subscription: stCardInfo.subscription,
                            selectGroups: filterSelectGroups || [],
                            selectCountry: filterSelectCountry[0] || null,
                        });
                        this.toggleLoading();
                    })
                    .catch(() => {
                        this.toggleLoading();
                    })
            }
        })

    }

    toggleEditing() {
        this.setState({ isEditing: !this.state.isEditing });
    }

    toggleError() {
        this.setState({ showError: !this.state.showError });
    }

    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    handleCommentChange(e) {
        const comment = e && e.target.value;
        this.setState({ comment, showError: false });
    }

    handleAPIKeyChange(e) {
        const apiKey = e && e.target.value;
        this.setState({ apiKey, showError: false });
    }

    handlePublicKeyChange(e) {
        const publicKey = e && e.target.value;
        this.setState({ publicKey, showError: false });
    }

    handleEndpointSercetChange(e) {
        const endpointSecret = e && e.target.value;
        this.setState({ endpointSecret, showError: false });
    }

    handleCSiteUrlChange(e) {
        const cSiteUrl = e && e.target.value;
        this.setState({ cSiteUrl, showError: false });
    }

    handleFrequencyChange(selectFrequency) {
        this.setState({ selectFrequency, showError: false });
    }

    handleCategoryChange(selectedCategory) {
        this.setState({ selectedCategory, showError: false });
    }

    handleAccountCountryChange(selectCountry) {
        console.log(selectCountry)
        this.setState({ selectCountry, showError: false });
    }

    handleMaxAmountChange(e) {
        const maxAmount = e && e.target.value;
        const regx = new RegExp(/^([0-9]|[1-9]\d+)(\.[0-9]{0,2})?$/);
        if (regx.test(maxAmount) || maxAmount === '') {
            this.setState({ maxAmount });
        }
        this.setState({ showError: false });
    }

    handleMaxPollingChange(e) {
        const maxPolling = e && e.target.value;
        const regx = new RegExp(/^([1-9]\d*)?$/);
        if (regx.test(maxPolling) || maxPolling === '') {
            this.setState({ maxPolling });
        }
        this.setState({ showError: false });
    }

    handleMaxOrderChange(e) {
        const maxOrder = e && e.target.value;
        if (maxOrder !== '' && FormValidationsUtil.isNumber(maxOrder)) {
            this.setState({ maxOrder: maxOrder, showError: false });
        }
        if (maxOrder === '') this.setState({ maxOrder, showError: false })
    }

    handleDailyOrderChange(e) {
        const dailyOrder = e && e.target.value;
        if (dailyOrder !== '' && FormValidationsUtil.isNumber(dailyOrder)) {
            this.setState({ dailyOrder: dailyOrder, showError: false });
        }
        if (dailyOrder === '') this.setState({ dailyOrder, showError: false })
    }

    handleStatusChange(status) {
        this.setState({ status, showError: false });
    }

    // handleLevelChange(e, isOpen) {
    //     this.setState({ level: isOpen ? 1 : 0, showError: false });
    // }

    // handleSubscriptionChange(e, isSubscription) {
    //     this.setState({ subscription: isSubscription ? 1 : 0, showError: false });
    // }

    handleStGroupChange(stGroups) {
        this.setState({ selectGroups: stGroups, showError: false });
    }

    customClearIndicatorWithClass(className) {
        const ClearIndicator = (props) => {
            const { innerProps: { ...restInnerProps }, } = props;
            return (<div {...restInnerProps} >
                <SvgIcon name="x-icon" className={className} color={ColorConstants.brand.primary} />
            </div>);
        };
        return ClearIndicator;
    }

    editSTCardInfo() {
        const { stCardActions, history, location } = this.props;
        const stCardId = location.pathname.split(`${PagePathConstants.ST}/`)[1];
        let { comment, apiKey, publicKey, endpointSecret, cSiteUrl, maxAmount, maxOrder, dailyOrder, status, level, subscription, description, maxPolling, selectGroups, selectFrequency, selectedCategory, selectCountry } = this.state;
        if (comment.trim() !== '' && !FormValidationsUtil.isValidString(comment.trim())) {
            this.setState({ showError: true, errFiled: 'comment', errMsg: I18N.getText('inValid-comment') });
            return;
        }
        if (apiKey.trim() !== '' && !FormValidationsUtil.isValidString(apiKey.trim())) {
            this.setState({ showError: true, errFiled: 'apiKey', errMsg: I18N.getText('inValid-apiKey') });
            return;
        }
        if (publicKey.trim() !== '' && !FormValidationsUtil.isValidString(publicKey.trim())) {
            this.setState({ showError: true, errFiled: 'publicKey', errMsg: I18N.getText('inValid-publicKey') });
            return;
        }
        if (endpointSecret.trim() !== '' && !FormValidationsUtil.isValidString(endpointSecret.trim())) {
            this.setState({ showError: true, errFiled: 'endpointSecret', errMsg: I18N.getText('inValid-endpointSecret') });
            return;
        }
        if (cSiteUrl.trim() !== '' && !FormValidationsUtil.isValidString(cSiteUrl.trim())) {
            this.setState({ showError: true, errFiled: 'cSiteUrl', errMsg: I18N.getText('inValid-cSiteUrl') });
            return;
        }
        const formateGroupIds = selectGroups.map(item => item.value).join(',');
        const params = {
            comment,
            api_key: apiKey,
            api_publishable_key: publicKey,
            endpoint_secret: endpointSecret,
            c_site_url: cSiteUrl,
            max_money: parseFloat(maxAmount),
            max_order: maxOrder,
            jishu: dailyOrder,
            status: status.value,
            description: description,
            lunxun_max: maxPolling,
            // level: level,
            // subscription: subscription,
            group_id: formateGroupIds,
            zhuandianId: selectCountry && selectCountry.value,
            frequency: selectFrequency && selectFrequency.value,
            zhandianfenleiid: selectedCategory && selectedCategory.value
        }
        if (stCardId && stCardId !== 'add') {
            params['id'] = stCardId;
        }
        const formData = new FormData();
        Object.keys(params).forEach((key) => {
            formData.append(`stripe[${key}]`, params[key]);
        });
        this.toggleEditing();
        stCardActions && stCardActions.editSTCardInfoById(params)
            .then(() => {
                history && history.push(PagePathConstants.ST);
            })
            .catch(err => {
                this.toggleEditing();
            });
    }

    handleBackPage() {
        const { history } = this.props;
        history && history.goBack();
    }

    render() {
        const { isEditing, showLoading, comment, apiKey, publicKey, endpointSecret, cSiteUrl, maxAmount, maxPolling, maxOrder, dailyOrder, status, level, subscription, description, showError, errMsg, errFiled, stGroupList, selectGroups, selectFrequency, selectedCategory, selectCountry } = this.state;
        const { location, unpagedCategoryList, unpagedAccountCountryList } = this.props;
        const stCardId = location.pathname.split(`${PagePathConstants.ST}/`)[1];
        const isEditSTCard = (stCardId && stCardId !== 'add');
        return <div className='tw-w-full tw-px-[50px] tw-py-[50px]'>
            {showLoading && <Loading />}
            <div className='tw-rounded-[30px] tw-bg-white'>
                <div className='tw-flex tw-items-center  tw-px-[30px] tw-py-[24px] tw-border-b' onClick={this.handleBackPage}>
                    <SvgIcon name="rds-back-icon" color={ColorConstants.black[0]} size={24} />
                    <span className='tw-ml-[10px] tw-text-[18px] tw-font-bold' >{I18N.getText('back')}</span>
                </div>
                <div className='tw-text-[14px] tw-px-[30px] tw-py-[30px]'>
                    <div className='tw-flex tw-items-center'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('comment')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`, { 'tw-border-light-red': (showError && errFiled === 'comment') })}
                            placeholder={I18N.getText('comment-tip')}
                            value={comment || ""}
                            type="text"
                            onChange={this.handleCommentChange}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('api-key')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`, { 'tw-border-light-red': (showError && errFiled === 'apiKey') })}
                            placeholder={I18N.getText('api-key-tip')}
                            value={apiKey || ""}
                            type="text"
                            onChange={this.handleAPIKeyChange}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('public-key')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`, { 'tw-border-light-red': (showError && errFiled === 'publicKey') })}
                            placeholder={I18N.getText('public-key-tip')}
                            value={publicKey}
                            type="text"
                            onChange={this.handlePublicKeyChange}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('callback-key')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`, { 'tw-border-light-red': (showError && errFiled === 'endpointSecret') })}
                            placeholder={I18N.getText('callback-key-tip')}
                            value={endpointSecret || ""}
                            type="text"
                            onChange={this.handleEndpointSercetChange}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('c-site-url')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`, { 'tw-border-light-red': (showError && errFiled === 'cSiteUrl') })}
                            placeholder={I18N.getText('c-site-url-tip')}
                            value={cSiteUrl || ""}
                            type="text"
                            onChange={this.handleCSiteUrlChange}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('max-money')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`)}
                            placeholder={I18N.getText('max-money-tip')}
                            value={maxAmount}
                            type="text"
                            onChange={this.handleMaxAmountChange}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('max-order')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`)}
                            placeholder={I18N.getText('max-order-tip')}
                            value={maxOrder}
                            type="text"
                            onChange={this.handleMaxOrderChange}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('daily-order')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`)}
                            placeholder={I18N.getText('daily-order-tip')}
                            value={dailyOrder}
                            type="text"
                            onChange={this.handleDailyOrderChange}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('status')}</div>
                        <Select
                            className="tw-w-full tw-rounded-[30px]  tw-px-[20px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-role-selector-container"
                            components={{ ClearIndicator: this.customClearIndicatorWithClass('tw-text-brand-primary') }}
                            styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                            value={status}
                            placeholder={I18N.getText('status-placeholder')}
                            onChange={this.handleStatusChange}
                            isClearable={true}
                            name="status"
                            options={SD_CARD_STATUS_LIST}
                        />
                    </div>
                    {/* <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('is-top')}</div>
                        <div className='tw-w-full'>
                            <Switch checked={level === 1}
                                color='primary'
                                onChange={this.handleLevelChange} />
                        </div>
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('is-subscription')}</div>
                        <div className='tw-w-full'>
                            <Switch checked={subscription === 1}
                                color='primary'
                                onChange={this.handleSubscriptionChange} />
                        </div>
                    </div> */}
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold'>{I18N.getText('st-group')}</div>
                        <Select
                            className="tw-w-full tw-rounded-[30px]  tw-px-[20px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-role-selector-container"
                            components={{ ClearIndicator: this.customClearIndicatorWithClass('tw-text-brand-primary') }}
                            styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                            value={selectGroups}
                            placeholder={I18N.getText('st-group-placeholder')}
                            onChange={this.handleStGroupChange}
                            isClearable={true}
                            name="stGroup"
                            options={stGroupList}
                            isMulti
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('max-polling')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`)}
                            placeholder={I18N.getText('max-polling-tip')}
                            value={maxPolling}
                            type="text"
                            onChange={this.handleMaxPollingChange}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('subscription-frequency')}</div>
                        <Select
                            className="tw-w-full tw-rounded-[30px]  tw-px-[20px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-role-selector-container"
                            components={{ ClearIndicator: this.customClearIndicatorWithClass('tw-text-brand-primary') }}
                            styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                            value={selectFrequency}
                            placeholder={I18N.getText('subscription-frequency-tip')}
                            onChange={this.handleFrequencyChange}
                            isClearable={true}
                            name="frequency"
                            options={SUBSCRIPTION_FREQUENCY_LIST}
                        />
                    </div>

                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >Product category</div>
                        <Select
                            className="tw-w-full tw-rounded-[30px]  tw-px-[20px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-role-selector-container"
                            components={{ ClearIndicator: this.customClearIndicatorWithClass('tw-text-brand-primary') }}
                            styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                            value={selectedCategory}
                            placeholder="Please select the product category"
                            onChange={this.handleCategoryChange}
                            isClearable={true}
                            name="category"
                            options={unpagedCategoryList}
                        />
                    </div>

                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold'>Account Country</div>
                        <Select
                            className="tw-w-full tw-rounded-[30px]  tw-px-[20px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-role-selector-container"
                            components={{ ClearIndicator: this.customClearIndicatorWithClass('tw-text-brand-primary') }}
                            styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                            value={selectCountry}
                            placeholder="Please select the product category"
                            onChange={this.handleAccountCountryChange}
                            isClearable={true}
                            name="category"
                            options={unpagedAccountCountryList}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('desc')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`, { 'tw-border-light-red': (showError && errFiled === 'apiKey') })}
                            placeholder={I18N.getText('desc-tip')}
                            value={description || ""}
                            type="textarea"
                            onChange={this.handleDescChange}
                        />
                    </div>
                    {showError && <div className='tw-mt-[20px] tw-text-[14px] tw-text-error-text'>{errMsg}</div>}
                    <div className='tw-flex tw-justify-center tw-items-center tw-mt-[30px]'>
                        <RDSButton
                            loading={isEditing}
                            disabled={isEditing || comment.trim() === '' || apiKey.trim() === '' || publicKey.trim() === '' || endpointSecret.trim() === '' || cSiteUrl.trim() === '' || maxAmount === '' || maxPolling === '' || maxOrder === '' || dailyOrder === '' || !selectGroups || selectGroups.length == 0 || !selectCountry}
                            onClick={this.editSTCardInfo}
                            className="tw-text-white" >{I18N.getText('edit')}</RDSButton>
                    </div>
                </div>
            </div>
        </div>;
    }
}

function mapStateToProps(state) {
    return {
        unpagedCategoryList: ProductCategorySelectors.selectUnpagedProductCategoryList(state),
        unpagedAccountCountryList: AccountCountrySelectors.selectUnpagedAccountCountryList(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        stCardActions: bindActionCreators(stCardActionCreator, dispatch),
        stGroupActions: bindActionCreators(stGroupActionCreator, dispatch),
        productCategoryActions: bindActionCreators(productCategoryActionCreator, dispatch),
        countryActions: bindActionCreators(countryActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEditPage);