import ActionConstants from '../constants/actions/ActionConstants';
import * as ProductAPI from '../apis/ProductAPI';
import { showErrorGlobalAlert, showSuccessGlobalAlert } from './globalAlertActionCreator';

export const updateProductListAction = (productList, total) => ({
    type: ActionConstants.UPDATE_PRODUCT_LIST,
    payload: { data: productList, total: total },
});

export const fetchProductList = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            ProductAPI.fetchProductList(params)
                .then((res) => {
                    if (res.status === 200) {
                        const productList = res.data.data.list;
                        const total = res.data.data.total;
                        dispatch(updateProductListAction(productList, total));
                        return resolve('success');
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}

export const fetchProductInfoById = (id) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            ProductAPI.fetchProductDetail(id)
                .then((res) => {
                    if (res.status === 200) {
                        return resolve(res.data.data);
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}

export const createOrEditProduct = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            ProductAPI.createOrEditProduct(params)
                .then((res) => {
                    if (res.status === 200) {
                        if (params.id && params.id !== '') {
                            dispatch(showSuccessGlobalAlert('edit-product-succeed'));
                        } else {
                            dispatch(showSuccessGlobalAlert('create-product-info-succeed'));
                        }
                        return resolve('success');
                    }
                })
                .catch((err) => {
                    if (params.id && params.id !== '') {
                        dispatch(showErrorGlobalAlert('edit-product-failed'));
                    } else {
                        dispatch(showErrorGlobalAlert('create-product-info-failed'));
                    }
                    
                    return reject(err);
                });
        });
    };
}

export const deleteProduct = (id) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            ProductAPI.deleteProduct(id)
                .then((res) => {
                    if (res.status === 200) {
                        dispatch(showSuccessGlobalAlert('delete-info-succeed'));
                        return resolve('success');
                    }
                })
                .catch((err) => {
                    dispatch(showErrorGlobalAlert('delete-info-failed'));
                    return reject(err);
                });
        });
    };
}