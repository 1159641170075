import { Map } from 'immutable';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ActionConstants from '../constants/actions/ActionConstants';


const initialState = Map({
  [StoreStateConstants.PRODUCT_CATEGORY_LIST]: [],
  [StoreStateConstants.UNPAGED_PRODUCT_CATEGORY_LIST]: [],
  [StoreStateConstants.PRODUCT_CATEGORY_TOTAL_COUNT]: 0,
});

export const ProductCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionConstants.UPDATE_PRODUCT_CATEGORY_LIST: {
      return state.set(StoreStateConstants.PRODUCT_CATEGORY_LIST, action.payload.data).set(StoreStateConstants.PRODUCT_CATEGORY_TOTAL_COUNT, action.payload.total);
    }
    case ActionConstants.UPDATE_UNPAGED_PRODUCT_CATEGORY_LIST: {
      return state.set(StoreStateConstants.UNPAGED_PRODUCT_CATEGORY_LIST, action.payload.data);
    }
    default:
      return state;
  }
};
