import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

// 最新版本的导入方式
import { Swiper, SwiperSlide } from "swiper/react";
// Import required modules
import { Pagination, Autoplay } from "swiper";
import { isScreenBelow } from "../utils/ResponsivePageUtils";

// Import required modules
import Logo from "../images/welcome/logo.png";
import Logo2 from "../images/welcome/logo-2.png";
import HeroBg from "../images/welcome/hero-bg.svg";
import WelcomeBanner from "../images/welcome/welcome-banner.svg";
import WelcomeBanner2 from "../images/welcome/welcome-banner-2.svg";
import WelcomeContactUS from "../images/welcome/welcome-contact-img.svg";
import WelcomeOurStoryBg from "../images/welcome/welcome-our-story-bg.svg";
import Partner1 from "../images/welcome/partner-1.png";
import Partner2 from "../images/welcome/partner-2.png";
import Partner3 from "../images/welcome/partner-3.png";
import Partner4 from "../images/welcome/partner-4.png";
import SliderPartner1 from "../images/welcome/slider-partner-1.png";
import SliderPartner2 from "../images/welcome/slider-partner-2.png";
import SliderPartner3 from "../images/welcome/slider-partner-3.png";


import FacebookIcon from '../icons/FacebookIcon';
import TwitterIcon from '../icons/TwitterIcon';
import InstagramIcon from '../icons/InstagramIcon';
import LinkedInIcon from '../icons/LinkedInIcon';

import * as globalAlertActionCreator from '../actions/globalAlertActionCreator';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// 导入样式
import "swiper/css";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

// --- Selectors --- //
import * as UserSelectors from "../selectors/UserSelectors";
import { sendTelegramMessage } from "../apis/TelegramAPI";
import GlobalAlertConstants from "../constants/GlobalAlertConstants";

// 将 testimonials 数据移到类的外部
const testimonials = [
	{
		id: 1,
		name: "Ena Shah",
		role: "Businessman",
		image: SliderPartner1,
		content: "Lorem ipsum dolor sit amet, conseta dipscing elitr, sed diam nonumy eirmod temp invidunt ut laboreet dolore magna aliquyamera. Lorem ipsum dolor sitamet.",
	},
	{
		id: 2,
		name: "Jonathon Smith",
		role: "Founder Food fanda",
		image: SliderPartner2,
		content: "Lorem ipsum dolor sit amet, conseta dipscing elitr, sed diam nonumy eirmod temp invidunt ut laboreet dolore magna aliquyamera. Lorem ipsum dolor sitamet.",
	},
	{
		id: 3,
		name: "Dev Ed",
		role: "Teacher at Udemy",
		image: SliderPartner3,
		content: "Lorem ipsum dolor sit amet, conseta dipscing elitr, sed diam nonumy eirmod temp invidunt ut laboreet dolore magna aliquyamera. Lorem ipsum dolor sitamet.",
	},
];

// 在 render 方法之前定义 teamMembers 数组
const teamMembers = [
	{
		name: "Anna Smith",
		role: "Software Engineer",
		image: Partner1,
		socials: [
			{ platform: "Facebook", url: "https://facebook.com/saraak", icon: <FacebookIcon /> },
			{ platform: "Twitter", url: "https://twitter.com/saraak", icon: <TwitterIcon /> },
			{ platform: "Instagram", url: "https://instagram.com/saraak", icon: <InstagramIcon /> },
			{ platform: "LinkedIn", url: "https://linkedin.com/in/saraak", icon: <LinkedInIcon /> },
		],
	},
	{
		name: "John Doe",
		role: "Lead Designer",
		image: Partner2,
		socials: [
			{ platform: "Facebook", url: "https://facebook.com/johndoe", icon: <FacebookIcon /> },
			{ platform: "Twitter", url: "https://twitter.com/johndoe", icon: <TwitterIcon /> },
			{ platform: "Instagram", url: "https://instagram.com/johndoe", icon: <InstagramIcon /> },
			{ platform: "LinkedIn", url: "https://linkedin.com/in/johndoe", icon: <LinkedInIcon /> },
		],
	},
	{
		name: "Abraham Smith",
		role: "Head Of Marketing",
		image: Partner3,
		socials: [
			{ platform: "Facebook", url: "https://facebook.com/abrahamsmith", icon: <FacebookIcon /> },
			{ platform: "Twitter", url: "https://twitter.com/abrahamsmith", icon: <TwitterIcon /> },
			{ platform: "Instagram", url: "https://instagram.com/abrahamsmith", icon: <InstagramIcon /> },
			{ platform: "LinkedIn", url: "https://linkedin.com/in/abrahamsmith", icon: <LinkedInIcon /> },
		],
	},
	{
		name: "Sara A. K.",
		role: "Founder",
		image: Partner4,
		socials: [
			{ platform: "Facebook", url: "https://facebook.com/saraak", icon: <FacebookIcon /> },
			{ platform: "Twitter", url: "https://twitter.com/saraak", icon: <TwitterIcon /> },
			{ platform: "Instagram", url: "https://instagram.com/saraak", icon: <InstagramIcon /> },
			{ platform: "LinkedIn", url: "https://linkedin.com/in/saraak", icon: <LinkedInIcon /> },
		],
	},
];

class WelcomePage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isScrolled: false,
			currentTestimonial: 0,
			activeSlide: 0,
			pricingPeriod: "monthly",
			contactForm: {
				name: "",
				email: "",
				subject: "",
				number: "",
				message: "",
			},
			showScrollTop: false,
			subscribeEmail: "",
		};
		this.swiperRef = React.createRef();
	}

	componentDidMount() {
		window.addEventListener("scroll", this.handleScroll);
		this.testimonialInterval = setInterval(this.nextTestimonial, 5000);
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll);
		clearInterval(this.testimonialInterval);
	}

	handleScroll = () => {
		if (window.scrollY > 50) {
			this.setState({ isScrolled: true });
		} else {
			this.setState({ isScrolled: false });
		}

		// 控制返回顶部按钮的显示
		if (window.scrollY > 300) {
			this.setState({ showScrollTop: true });
		} else {
			this.setState({ showScrollTop: false });
		}
	};

	scrollToSection = (sectionId) => {
		const element = document.getElementById(sectionId);
		if (element) {
			element.scrollIntoView({ behavior: "smooth" });
		}
	};

	nextTestimonial = () => {
		this.setState((prevState) => ({
			currentTestimonial: (prevState.currentTestimonial + 1) % testimonials.length,
		}));
	};

	prevTestimonial = () => {
		this.setState((prevState) => ({
			currentTestimonial: prevState.currentTestimonial === 0 ? testimonials.length - 1 : prevState.currentTestimonial - 1,
		}));
	};

	handleContactInput = (e) => {
		const { name, value } = e.target;
		this.setState((prevState) => ({
			contactForm: {
				...prevState.contactForm,
				[name]: value,
			},
		}));
	};

	handleSubmitContact = (e) => {
		e.preventDefault();
		// 处理表单提交
		console.log("Form submitted:", this.state.contactForm);
		const {globalAlertActions} = this.props;
		sendTelegramMessage(Object.assign({}, {title: 'Stcrof Contact Form Message'}, this.state.contactForm))
		.then(() => {
			globalAlertActions && globalAlertActions.dispatchGlobalAlert('submit-success', 'Submit successfully!', GlobalAlertConstants.SUCCESS);
		});
		// // 重置表单
		// this.setState({
		// 	contactForm: {
		// 		name: "",
		// 		email: "",
		// 		subject: "",
		// 		number: "",
		// 		message: "",
		// 	},
		// });
	};

	scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	handleSubscribe = (e) => {
		e.preventDefault();
		console.log("Subscribe email:", this.state.subscribeEmail);
		this.setState({ subscribeEmail: "" });
	};

	render() {
		const { isScrolled } = this.state;
		const isScreenBelowLG = isScreenBelow('lg');

		const navItems = [
			{ id: "home", label: "Home" },
			{ id: "features", label: "Features" },
			{ id: "about", label: "About" },
			{ id: "team", label: "Team" },
			{ id: "pricing", label: "Pricing" },
			{ id: "contact", label: "Contact" },
		];

		const TeamMember = ({ name, role, image, socials }) => (
			<div className="tw-text-center">
				<div className="tw-w-[250px] tw-h-[250px] md:tw-w-full md:tw-pb-[100%] tw-max-w-[250px] tw-rounded-full tw-mx-auto" style={{backgroundImage: `url(${image})`, backgroundSize: '100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}></div>
				<h3 className="tw-mt-4 tw-text-[22px] tw-font-bold tw-text-gray-900 tw-mb-1 tw-mt-[20px]">{name}</h3>
				<p className="tw-text-gray-600 tw-text-[18px] tw-mt-[10px]">{role}</p>
				<div className="tw-flex tw-justify-center tw-gap-[16px] tw-mt-[20px]">
					{socials.map((social, index) => (
						<button key={index} onClick={() => window.open(social.url, "_blank")} className="tw-w-[30px] tw-h-[30px] tw-text-gray-400 hover:tw-text-brand-primary-welcome tw-transition-colors" aria-label={`Visit ${name}'s ${social.platform}`}>
							{social.icon}
						</button>
					))}
				</div>
			</div>
		);

		return (
			<div className="tw-w-full tw-min-h-screen tw-bg-white sen-font">
				<div style={{ backgroundImage: `url(${HeroBg})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
					{/* Navigation */}
					<nav className={`tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-z-50 tw-transition-all tw-duration-300 ${isScrolled ? "tw-bg-white tw-shadow-md" : "tw-bg-transparent"}`}>
						<div className="xs:tw-px-[30px] tw-mx-auto sm:tw-px-[50px] md:tw-px-[128px] tw-w-full xl:tw-w-[1296px] tw-h-[80px] tw-py-6 tw-flex tw-justify-between tw-items-center">
							<div className="tw-flex tw-items-center tw-gap-2">
								<img src={isScrolled ? Logo2 : Logo} alt="SaaSLand" className="tw-h-[32px]" />
							</div>
							<div className="tw-hidden lg:tw-flex tw-justify-between tw-item-center tw-gap-[32px] xl:tw-w-[635px]">
								{navItems.map((item) => (
									<button key={item.id} onClick={() => this.scrollToSection(item.id)} className={`tw-text-[17px] tw-transition-colors tw-cursor-pointer ${isScrolled ? "tw-text-gray-600 hover:tw-text-brand-primary-welcome" : "tw-text-white/90 hover:tw-text-white"}`}>
										{item.label}
									</button>
								))}
							</div>
							<div className="tw-block lg:tw-hidden">
								<div onClick={() => this.setState({isMenuOpen: !this.state.isMenuOpen})} className="tw-w-[32px] tw-gap-y-[5px] tw-flex tw-flex-col tw-items-center tw-justify-center tw-cursor-pointer">
									<div className={`tw-w-full tw-h-[2px] tw-transition-all tw-duration-300 ${isScrolled ? "tw-bg-gray-600" : "tw-bg-white"} ${this.state.isMenuOpen ? "tw-rotate-45 tw-translate-y-[7px]" : ""}`}></div>
									<div className={`tw-w-full tw-h-[2px] tw-transition-all tw-duration-300 ${isScrolled ? "tw-bg-gray-600" : "tw-bg-white"} ${this.state.isMenuOpen ? "tw-opacity-0" : ""}`}></div>
									<div className={`tw-w-full tw-h-[2px] tw-transition-all tw-duration-300 ${isScrolled ? "tw-bg-gray-600" : "tw-bg-white"} ${this.state.isMenuOpen ? "-tw-rotate-45 -tw-translate-y-[7px]" : ""}`}></div>
								</div>
								{this.state.isMenuOpen && (
									<div className="tw-fixed tw-top-[80px] tw-left-0 tw-right-0 tw-bg-white tw-shadow-md tw-py-4">
										{navItems.map((item) => (
											<button 
												key={item.id} 
												onClick={() => {
													this.scrollToSection(item.id);
													this.setState({isMenuOpen: false});
												}} 
												className="tw-block tw-w-full tw-text-left tw-px-[30px] tw-py-3 tw-text-[17px] tw-text-gray-600 hover:tw-text-brand-primary-welcome hover:tw-bg-gray-50 tw-transition-colors"
											>
												{item.label}
											</button>
										))}
									</div>
								)}
							</div>
						</div>
					</nav>

					{/* Hero Section */}
					<section id="home" className="tw-pt-[180px] tw-w-full">
						<div className="tw-mx-auto tw-px-4 tw-pb-20">
							<div className="tw-max-w-[746px] tw-mx-auto tw-text-center">
								<h1 className="xs:tw-text-[40px] tw-text-[50px] lg:tw-text-[60px] xl:lg:tw-text-[40px] xl:tw-text-[50px] tw-font-bold tw-mb-6 tw-text-white tw-leading-normal">
									SaaS, WebApp and
									<br />
									Software Landing Page
								</h1>
								<p className="tw-text-[18px] tw-text-white tw-mb-8">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</p>
								<button className="tw-w-[200px] tw-h-[63px] tw-text-[16px] xl:tw-text-[18px] tw-font-bold tw-bg-white tw-text-brand-primary-welcome tw-px-[16px] tw-py-[12px] tw-rounded-full hover:tw-shadow-lg tw-transition-all tw-relative tw-overflow-hidden tw-group">
									<span className="tw-relative tw-z-10">Get Started</span>
									<span className="tw-absolute tw-left-1/2 tw-top-1/2 tw-w-[30px] tw-h-[30px] tw-bg-[#f4f4f4] tw-rounded-full -tw-translate-x-1/2 -tw-translate-y-1/2 tw-scale-0 group-hover:tw-scale-[8] tw-transition-transform tw-duration-300 tw-ease-out"></span>
								</button>
							</div>

							{/* Illustration */}
							<div className="xs:tw-w-[406px] xs:tw-h-[221px] tw-relative tw-w-[516px] tw-h-[281px] md:tw-w-[696px] md:tw-h-[380px] xl:tw-w-[1296px] xl:tw-h-[760px] tw-mx-auto" style={{ backgroundImage: `url(${WelcomeBanner})`, backgroundSize: "contain", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
								<div className="tw-max-w-4xl tw-mx-auto tw-relative tw-z-10">
									{/* <div className="tw-bg-white tw-rounded-lg tw-shadow-xl tw-p-4">
										<div className="tw-flex tw-items-center tw-gap-2 tw-mb-4">
											<div cla/>+8888888820.sName="tw-flex tw-gap-1">
												<div className="tw-w-3 tw-h-3 tw-rounded-full tw-bg-red-400"></div>
												<div className="tw-w-3 tw-h-3 tw-rounded-full tw-bg-yellow-400"></div>
												<div className="tw-w-3 tw-h-3 tw-rounded-full tw-bg-green-400"></div>
											</div>
											<div className="tw-flex-1 tw-bg-gray-100 tw-rounded tw-px-4 tw-py-2">www.</div>
										</div>
										<div className="tw-h-64 tw-bg-gray-50 tw-rounded tw-flex tw-items-center tw-justify-center">
											<div className="tw-text-gray-400">Browser Content Area</div>
										</div>
									</div> */}
								</div>

								{/* Decorative Elements */}
								<div className="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full">
									<div className="tw-absolute tw-left-10 tw-top-10">
										<div className="tw-w-12 tw-h-12 tw-border-4 tw-border-white/20 tw-rounded-full"></div>
									</div>
									<div className="tw-absolute tw-right-10 tw-bottom-10">
										<div className="tw-w-16 tw-h-16 tw-border-4 tw-border-white/20 tw-rounded-full"></div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>

				{/* Features Section */}
				<section id="features" className="tw-w-full tw-py-20 tw-bg-white tw-pt-[100px] md:tw-pt-[150px]">
					<div className="tw-container tw-mx-auto tw-px-4">
						<div className="tw-w-full  tw-container tw-mx-auto tw-flex tw-flex-col xl:tw-flex-row tw-items-center">
							{/* Left Content */}
							<div className="tw-w-full xl:tw-w-1/2 xl:tw-px-0 tw-text-left xl:tw-text-left tw-mb-12 xl:tw-mb-0 xl:tw-pr-[100px]">
								<h2 className="tw-text-[40px] md:tw-text-[50px] tw-font-bold tw-text-gray-900 tw-mb-4">Our Cool Features and Services</h2>
								<p className="tw-text-gray-600 tw-text-[18px] tw-mt-[30px]">Lorem ipsum dolor sit amet, consetetur sadipscing elitr sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam era.Lorem ipsum dolor sisert amet, consetetur sadipscing sed diam.</p>
								<button className="tw-mt-[40px] tw-w-[200px] tw-h-[63px] tw-text-[16px] xl:tw-text-[18px] tw-font-bold tw-border tw-border-brand-primary-welcome tw-border-[2px] tw-text-brand-primary-welcome tw-px-[16px] tw-py-[12px] tw-rounded-full hover:tw-shadow-lg tw-transition-all tw-relative tw-overflow-hidden tw-group">
									<span className="tw-relative tw-z-10">Get Started</span>
									<span className="tw-absolute tw-left-1/2 tw-top-1/2 tw-w-[30px] tw-h-[30px] tw-bg-[#f4f4f4] tw-rounded-full -tw-translate-x-1/2 -tw-translate-y-1/2 tw-scale-0 group-hover:tw-scale-[8] tw-transition-transform tw-duration-300 tw-ease-out"></span>
								</button>
							</div>

							{/* Right Content */}
							<div className="tw-w-full xl:tw-w-1/2">
								<div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-[32px]">
									{/* Feature Card 1 */}
									<div className="single-feature-card tw-rounded-[20px] tw-px-[30px] tw-py-[45px]">
										<div className="tw-text-brand-primary-welcome tw-mb-4">
											<svg width="64" height="64" viewBox="0 0 64 64" fill="none">
												<g id="rocket 1">
													<g id="Group">
														<path id="Vector" d="M62.7999 18.5L54.2999 9.99997L54 9.79997C53.5 9.29997 52.7 9.29997 51.9 9.29997L38.9 10.4C28.5 1.79997 15.4999 -1.90003 4.79995 0.999972C2.99995 1.29997 1.69995 2.79997 1.09995 4.69997C-1.80005 15.3 1.89995 28.4 10.3999 38.5L9.09995 51.8C9.09995 52.8 9.49995 53.8 10.1999 54.5L18.4 62.8C18.9 63.3 19.9999 63.9 20.7999 63.9C21.0999 63.9 21.3 63.9 21.9 63.6C22.9 62.9 23.9999 62.3 24.2999 60.7L25.6 50.1C26.9 50.6 28.3 51.3 29.6 51.7C30.3 51.9 30.7 52 31.2 52C32.5 52 33.9 51.5 34.9 50.4L50.2999 35C51.5999 33.7 52.1999 31.3 51.5999 29.4C51.0999 28.1 50.5 26.7 50 25.4L60.9 24.1C62.2 23.8 62.9999 23 63.5999 21.7C64.0999 20.7 63.8999 19.6 62.7999 18.5ZM19.7 56.3L14.5999 51.2L15.3999 43.5C17.2999 45.1 19.1 46.4 21 47.8L19.7 56.3ZM31.4 47C26.0999 45.1 20.8 41.7 16.5 37.4C7.49995 28.4 3.19995 15.9 5.89995 5.99997C16 3.39997 28.1999 7.39997 37.2999 16.7C41.5999 21 44.7 26 46.9 31.6L31.4 47ZM47.5999 20.7C46.2999 18.8 44.8999 17 43.2999 15.1L51 14.6L56.0999 19.7L47.5999 20.7Z" fill="#286ADE"></path>
														<path id="Vector_2" d="M56.4 40.1C55.6 39.6 54.3 39.8 53.5 40.4C52.4 41.2 47.4 46.2 46.6 47C39.7 53.9 39.7 53.9 40.2 55.5C40.3 56 40.5 56.3 41 56.8C44.5 60.3 49.5 61.1 53.5 61.1C56.4 61.1 58.6 60.6 58.8 60.6C59.9 60.3 60.7 59.5 60.9 58.5C60.9 58 63.3 46.5 57.2 40.7C56.7 40.3 56.7 40.3 56.4 40.1ZM55.9 55.8C53.2 56.1 49.5 56.1 46.6 54.7C48.7 52.3 52.4 48.9 54.6 46.7C55.9 49.4 55.9 53.1 55.9 55.8Z" fill="#286ADE"></path>
														<path id="Vector_3" d="M25.7999 16.7C20.6999 16.7 16.7999 20.7 16.7999 25.7C16.7999 30.7 20.7999 34.7 25.7999 34.7C30.7999 34.7 34.7999 30.7 34.7999 25.7C34.7999 20.7 30.8999 16.7 25.7999 16.7ZM25.7999 29.4C23.6999 29.4 22.0999 27.8 22.0999 25.7C22.0999 23.6 23.6999 22 25.7999 22C27.8999 22 29.4999 23.6 29.4999 25.7C29.5999 27.8 27.9999 29.4 25.7999 29.4Z" fill="#286ADE"></path>
													</g>
												</g>
											</svg>
										</div>
										<h3 className="tw-font-bold tw-mb-[22px] tw-text-[28px]">Blazing Fast</h3>
										<p className="tw-text-gray-600 tw-text-[18px]">Lorem ipsum dolor samet consetetur sadipscing dge</p>
									</div>

									{/* Feature Card 2 */}
									<div className="single-feature-card tw-rounded-[20px] tw-px-[30px] tw-py-[45px]">
										<div className="tw-text-brand-primary-welcome tw-mb-4">
											<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M39 26.4L30.1 35L27.9 32.8C26.8 31.7 25.1 31.7 24 32.8C22.9 33.9 22.9 35.6 24 36.7L27.9 40.6C28.5 41.2 29.3 41.4 30.1 41.4C30.9 41.4 31.8 41.1 32.6 40.6L42.9 30.6C44 29.5 44 27.8 42.9 26.7C41.7 25.6 40.1 25.3 39 26.4Z" fill="#286ADE"></path>
												<path d="M58.7 23.1C55.9 20 52 17.8 47.9 17C45.7 13.4 42.1 10.6 38.2 9.20001C36.3 8.40001 34.3 8.10001 32.1 8.10001C22.1 8.10001 13.7 15.9 13.2 25.6C5.6 26.7 0 33.1 0 40.6C0 49 7 55.9 15.3 55.9H44.2C55.1 55.9 64 47 64 36.2C64 31.4 62.1 26.7 58.7 23.1ZM44 50.6H15C9.4 50.6 5.3 46.4 5.3 40.9C5.3 35.4 9.8 31.2 15 31.2H18.3V27.3C18.3 19.8 24.4 13.7 31.9 13.7C33.6 13.7 35 14 36.4 14.5C39.5 15.6 42.2 17.8 43.9 20.9L44.7 22.3L46.1 22.6C49.4 22.9 52.8 24.5 55 27.1C57.5 29.9 58.9 33.2 58.9 36.8C58.4 44.2 52 50.6 44 50.6Z" fill="#286ADE"></path>
											</svg>
										</div>
										<h3 className="tw-font-bold tw-mb-[22px] tw-text-[28px]">SaaS Focused</h3>
										<p className="tw-text-gray-600 tw-text-[18px]">Lorem ipsum dolor samet consetetur sadipscing dge</p>
									</div>

									{/* Feature Card 3 */}
									<div className="single-feature-card tw-rounded-[20px] tw-px-[30px] tw-py-[45px]">
										<div className="tw-text-brand-primary-welcome tw-mb-4">
											<svg fill="none" height="58" viewBox="0 0 64 58" width="64" xmlns="http://www.w3.org/2000/svg">
												<path d="m62.1 3.00001c-2.7-2.700002-7-2.700003-9.6-.3l-34.8 32.39999c-1.1 0-2.4.3-3.7.8-2.4 1.1-3.5 2.9-4.5 4.5-1.1 1.9-2.1 3.7-5.6 5.9l-3.9 2.5 3.7 2.1c2.1 1.3 10.2 6.2 17.1 6.2 2.1 0 4-.5 5.6-1.6 2.4-1.9 3.7-4.3 3.5-7v-.3l32.1-35.3c2.8-2.99999 2.5-7.19999.1-9.89999zm-38.8 48.19999c-2.1 1.6-8.3-.3-13.4-2.7 1.9-1.9 2.9-3.7 3.7-5.1.8-1.3 1.3-2.1 1.9-2.4 1.9-.8 3.7 0 4 0 2.4 1.9 5.1 4.8 5.1 7.5.3.5 0 1.6-1.3 2.7zm34.8-42.09999-30 33.19999c-1.3-2.1-3.2-4-4.8-5.4l32.6-30.19999c.5-.5 1.6-.5 2.1 0 .9.6.9 1.6.1 2.4z" fill="#286ade"></path>
											</svg>
										</div>
										<h3 className="tw-font-bold tw-mb-[22px] tw-text-[28px]">Clean Design</h3>
										<p className="tw-text-gray-600 tw-text-[18px]">Lorem ipsum dolor samet consetetur sadipscing dge</p>
									</div>

									{/* Feature Card 4 */}
									<div className="single-feature-card tw-rounded-[20px] tw-px-[30px] tw-py-[45px]">
										<div className="tw-text-brand-primary-welcome tw-mb-4">
											<svg xmlns="http://www.w3.org/2000/svg" height="64" viewBox="0 0 118 94" role="img">
												<path fill-rule="evenodd" clip-rule="evenodd" d="M24.509 0c-6.733 0-11.715 5.893-11.492 12.284.214 6.14-.064 14.092-2.066 20.577C8.943 39.365 5.547 43.485 0 44.014v5.972c5.547.529 8.943 4.649 10.951 11.153 2.002 6.485 2.28 14.437 2.066 20.577C12.794 88.106 17.776 94 24.51 94H93.5c6.733 0 11.714-5.893 11.491-12.284-.214-6.14.064-14.092 2.066-20.577 2.009-6.504 5.396-10.624 10.943-11.153v-5.972c-5.547-.529-8.934-4.649-10.943-11.153-2.002-6.484-2.28-14.437-2.066-20.577C105.214 5.894 100.233 0 93.5 0H24.508zM80 57.863C80 66.663 73.436 72 62.543 72H44a2 2 0 01-2-2V24a2 2 0 012-2h18.437c9.083 0 15.044 4.92 15.044 12.474 0 5.302-4.01 10.049-9.119 10.88v.277C75.317 46.394 80 51.21 80 57.863zM60.521 28.34H49.948v14.934h8.905c6.884 0 10.68-2.772 10.68-7.727 0-4.643-3.264-7.207-9.012-7.207zM49.948 49.2v16.458H60.91c7.167 0 10.964-2.876 10.964-8.281 0-5.406-3.903-8.178-11.425-8.178H49.948z" fill="#286ade"></path>
											</svg>
										</div>
										<h3 className="tw-font-bold tw-mb-[22px] tw-text-[28px]">Bootstrap 5</h3>
										<p className="tw-text-gray-600 tw-text-[18px]">Lorem ipsum dolor samet consetetur sadipscing dge</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				{/* Learn More Section */}
				<section id="about" className="tw-pb-[100px] tw-bg-white">
					<div className="tw-w-full xl:tw-w-[1320px] tw-mx-auto xs:tw-px-4 tw-px-[100px] lg:tw-px-[20px] xl:tw-px-4">
						<div className="tw-flex tw-flex-col lg:tw-flex-row tw-items-center tw-gap-12">
							<div className="tw-w-full tw-px-[100px] lg:tw-pr-[40px] lg:tw-w-1/2">
								<img src={WelcomeBanner2} alt="Learn More" className="tw-w-full" />
							</div>
							<div className="tw-w-full lg:tw-w-1/2">
								<h2 className="tw-text-[40px] xl:tw-text-[50px] tw-font-bold tw-text-gray-900 tw-mb-6">Learn More About Us</h2>
								<p className="tw-text-[18px] tw-text-gray-600 tw-my-[40px]">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.</p>
								<div className="feature-detail-card tw-flex tw-justify-center tw-items-start tw-py-[20px] tw-rounded-[10px]">
									<div className="tw-w-[33%] tw-h-full tw-text-white tw-font-bold tw-px-[30px] tw-py-0 tw-text-center tw-border-r-[0px] md:tw-border-r md:tw-border-r-[2px] md:tw-border-white">
										<div className="tw-text-[36px] xl:tw-text-[42px] tw-font-bold tw-mb-[10px] xl:tw-mb-[32px]">1M+</div>
										<div className="tw-text-[16px] xl:tw-text-[18px] tw-font-bold">Downloads</div>
									</div>
									<div className="tw-w-[33%] tw-h-full tw-text-white tw-font-bold tw-px-[30px] tw-py-0 tw-text-center tw-border-r-[0px] md:tw-border-r md:tw-border-r-[2px] md:tw-border-white">
										<div className="tw-text-[36px] xl:tw-text-[42px] tw-font-bold tw-mb-[10px] xl:tw-mb-[32px]">234K+</div>
										<div className="tw-text-[16px] xl:tw-text-[18px] tw-font-bold">Happy Users</div>
									</div>
									<div className="tw-w-[33%] tw-h-full tw-text-white tw-font-bold tw-px-[30px] tw-py-0 tw-text-center">
										<div className="tw-text-[36px] xl:tw-text-[42px] tw-font-bold tw-mb-[10px] xl:tw-mb-[32px]">34K+</div>
										<div className="tw-text-[16px] xl:tw-text-[18px] tw-font-bold">Reviews</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				{/* Team Section */}
				<section id="team" className="xs:tw-px-4 tw-pt-[60px] tw-pb-[100px] tw-bg-white">
					<div className="tw-container tw-mx-auto">
						<div className="tw-text-center tw-mb-16 tw-w-full tw-w-[636px] tw-mx-auto">
							<h2 className="tw-text-[40px] xl:tw-text-[50px] tw-font-bold tw-text-gray-900 tw-mb-[36px]">Meet The Team</h2>
							<p className="tw-text-gray-600 tw-text-[18px]">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore</p>
						</div>

						<div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-[32px]">
							{teamMembers.map((member, index) => (
								<TeamMember key={index} {...member} />
							))}
						</div>
					</div>
				</section>

				<section className="tw-py-20 tw-bg-brand-primary-welcome tw-relative tw-overflow-hidden" style={{backgroundImage: `url(${WelcomeOurStoryBg})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
					

					<div className="tw-container tw-mx-auto tw-px-4 tw-relative">
						<h2 className="xs:tw-text-[36px] sm:tw-text-[40px] xl:tw-text-[50px] tw-font-bold tw-text-white tw-text-center tw-mb-16">What Our Users Says</h2>

						<div className="tw-w-full xl:tw-w-[1296px] tw-mx-auto">
							<div className="tw-relative">
								<Swiper
									modules={[Autoplay]}
									autoplay={{
										delay: 3000,
										disableOnInteraction: false,
									}}
									loop={true}
									slidesPerView={isScreenBelowLG ? 1 : 2}
									spaceBetween={30}
									className="testimonials-swiper"
									ref={this.swiperRef}
									onSlideChange={(swiper) => this.setState({ activeSlide: swiper.realIndex })}>
									{testimonials.map((testimonial, index) => (
										<SwiperSlide key={testimonial.id}>
											<div className="tw-text-white tw-p-0 xl:tw-p-[32px] tw-flex tw-items-start">
												<img src={testimonial.image} alt={testimonial.name} className="tw-w-[110px] tw-h-[110px] tw-rounded-full tw-object-cover" />
												<div className="tw-ml-[16px]">
													<p className=" tw-mb-[32px] tw-text-[18px]">{testimonial.content}</p>
													<div>
														<h3 className="tw-font-bold tw-text-[20px]">{testimonial.name}</h3>
														<p className="tw-mt-[16px] tw-text-[18px]">{testimonial.role}</p>
													</div>
												</div>
											</div>
										</SwiperSlide>
									))}
								</Swiper>
							</div>
						</div>
					</div>
				</section>

				{/* Pricing Section */}
				<section id="pricing" className="tw-py-20 tw-bg-white">
					<div className="tw-container tw-mx-auto tw-px-4">
						<div className="tw-text-center tw-mb-16 tw-flex tw-flex-col tw-items-start lg:tw-flex-row tw-justify-between lg:tw-items-center xl:tw-w-[1296px] tw-mx-auto">
							<div className="tw-text-left tw-w-full lg:tw-w-[526px]">
								<h2 className="tw-text-[45px] xl:tw-text-[50px] tw-font-bold tw-text-gray-900 tw-mb-4">Choose Your Plan</h2>
								<p className="tw-text-[18px] tw-text-gray-600 tw-mt-[30px]">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore</p>
							</div>
							{/* Period Toggle */}
							<div className="tw-mt-[50px] tw-justify-start lg:tw-justify-center lg:tw-mt-0 tw-inline-flex tw-items-center tw-bg-gray-100 tw-rounded-[6px] tw-p-1">
								<button className={`tw-text-[16px] tw-px-6 tw-py-[10px] tw-rounded-[6px] tw-transition-all ${this.state.pricingPeriod === "monthly" ? "tw-bg-brand-primary-welcome tw-text-white" : "tw-text-gray-600"}`} onClick={() => this.setState({ pricingPeriod: "monthly" })}>
									Monthly
								</button>
								<button className={`tw-text-[16px] tw-px-6 tw-py-[10px] tw-rounded-[6px] tw-transition-all ${this.state.pricingPeriod === "yearly" ? "tw-bg-brand-primary-welcome tw-text-white" : "tw-text-gray-600"}`} onClick={() => this.setState({ pricingPeriod: "yearly" })}>
									Yearly
								</button>
							</div>
						</div>

						{/* Pricing Cards */}
						<div className="tw-relative xl:tw-w-[1296px] tw-mx-auto">
							<div className={`tw-w-full tw-gap-[32px] tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-mx-auto tw-transition-all tw-duration-500 tw-ease-in-out ${this.state.pricingPeriod === "monthly" ? "tw-opacity-100 tw-translate-x-0" : "tw-opacity-0 tw-translate-x-8 tw-absolute tw-inset-0"}`}>
								{/* Monthly Pricing Cards */}
								<div className="tw-bg-white tw-rounded-[20px] tw-shadow-around tw-p-[16px] tw-text-center hover:tw-shadow-xl tw-transition-all tw-w-full tw-py-[60px] tw-flex tw-flex-col tw-justify-center tw-items-center">
									{/* Startup Plan - Monthly */}
									<div className="tw-text-brand-primary-welcome tw-mb-6 tw-bg-brand-primary-light-welcome tw-rounded-full tw-w-[130px] tw-h-[130px] tw-flex tw-items-center tw-justify-center tw-mx-auto">
										<svg height="86" viewBox="0 0 86 86" width="86">
											<clipPath id="a">
												<path d="m517 4402h86v86h-86z" />
											</clipPath>
											<g clip-path="url(#a)" transform="translate(-517 -4402)">
												<g fill="#286ade" transform="translate(517.001 4402.01)">
													<path d="m53.743 85.988a17.914 17.914 0 1 1 0-35.828 17.192 17.192 0 0 1 5.761.964 1.792 1.792 0 0 1 -1.204 3.376 13.56 13.56 0 0 0 -4.557-.756 14.331 14.331 0 1 0 14.331 14.33 13.56 13.56 0 0 0 -.756-4.557 1.792 1.792 0 1 1 3.375-1.2 17.192 17.192 0 0 1 .964 5.761 17.935 17.935 0 0 1 -17.914 17.91z" />
													<path d="m53.743 75.24a7.165 7.165 0 0 1 -5.066-12.232 1.791 1.791 0 0 1 2.533 2.533 3.582 3.582 0 1 0 5.066 5.066 1.791 1.791 0 1 1 2.533 2.533 7.123 7.123 0 0 1 -5.066 2.1z" />
													<path d="m53.743 69.866a1.79 1.79 0 0 1 -1.268-3.056l12.54-12.54a1.791 1.791 0 0 1 2.532 2.53l-12.54 12.54a1.785 1.785 0 0 1 -1.264.526z" />
													<path d="m75.24 57.326h-8.957a1.792 1.792 0 0 1 -1.791-1.791v-8.958a1.8 1.8 0 0 1 .523-1.268l8.957-8.957a1.793 1.793 0 0 1 3.06 1.268v7.166h7.168a1.793 1.793 0 0 1 1.268 3.06l-8.96 8.954a1.8 1.8 0 0 1 -1.268.526zm-7.166-3.583h6.426l5.374-5.374h-4.634a1.792 1.792 0 0 1 -1.791-1.791v-4.634l-5.374 5.374z" />
													<path d="m32.246 58.078a1.8 1.8 0 0 1 -1.268-.523l-12.791-12.791a1.79 1.79 0 0 1 -.068-2.461l17.265-19.348a2.043 2.043 0 0 1 .43-.451c12.286-14.07 31.368-22.826 48.422-22.504a1.793 1.793 0 0 1 1.752 1.752 59.854 59.854 0 0 1 -6.682 27.71 1.79 1.79 0 1 1 -3.2-1.6 57.107 57.107 0 0 0 6.307-24.283c-15.546.272-33.034 8.739-44.044 21.458a1.569 1.569 0 0 1 -.251.236l-16.195 18.155 11.591 11.59a1.793 1.793 0 0 1 -1.268 3.06z" />
													<path d="m23.435 40.887a1.546 1.546 0 0 1 -.254-.018l-17.151-2.457a1.792 1.792 0 0 1 -1-3.049 45.475 45.475 0 0 1 31.988-13.31 1.791 1.791 0 1 1 0 3.583 41.819 41.819 0 0 0 -26.746 9.764l13.418 1.923a1.792 1.792 0 0 1 -.254 3.565z" />
													<path d="m61.385 33.557a8.954 8.954 0 1 1 6.334-2.619 8.929 8.929 0 0 1 -6.334 2.619zm0-14.324a5.371 5.371 0 1 0 3.8 1.569 5.362 5.362 0 0 0 -3.8-1.569zm5.066 10.44h.036z" />
													<path d="m1.791 85.988a1.788 1.788 0 0 1 -1.716-2.3c.588-1.96 5.851-19.279 9.684-23.113a11.074 11.074 0 0 1 15.661 15.657c-3.834 3.834-21.153 9.1-23.113 9.684a1.969 1.969 0 0 1 -.516.072zm15.8-25.076a7.479 7.479 0 0 0 -5.3 2.189c-2.067 2.071-5.507 11.318-7.778 18.373 7.051-2.272 16.3-5.711 18.369-7.778a7.486 7.486 0 0 0 -5.292-12.784z" />
												</g>
											</g>
										</svg>
									</div>
									<h3 className="tw-text-[26px] tw-font-bold tw-mb-4">Startup</h3>
									<div className="tw-text-[40px] tw-font-bold tw-text-brand-primary-welcome tw-my-[16px]">
										<div className={`tw-transition-opacity tw-duration-300 ${this.state.pricingPeriod === "monthly" ? "tw-opacity-100" : "tw-opacity-0"}`}>$13.00</div>
									</div>
									<ul className="tw-space-y-[16px] tw-mb-8 tw-text-[16px] tw-text-[#5B657E]">
										<li>Extra features</li>
										<li>Lifetime free support</li>
										<li>Upgrade options</li>
										<li>Full access</li>
									</ul>
									<button className="tw-text-[16px] xl:tw-text-[18px] tw-font-bold tw-border-2 tw-border-brand-primary-welcome tw-text-brand-primary-welcome hover:tw-bg-brand-primary-welcome hover:tw-text-white tw-px-[16px] tw-py-[12px] tw-rounded-full tw-transition-all">Get Started</button>
								</div>
								<div className="tw-bg-white tw-rounded-[20px] tw-shadow-around tw-p-[16px] tw-text-center hover:tw-shadow-xl tw-transition-all tw-w-full tw-py-[60px] tw-flex tw-flex-col tw-justify-center tw-items-center">
									{/* Standard Plan - Monthly */}
									<div className="tw-text-brand-primary-welcome tw-mb-6 tw-bg-brand-primary-light-welcome tw-rounded-full tw-w-[130px] tw-h-[130px] tw-flex tw-items-center tw-justify-center tw-mx-auto">
										<svg height="86" viewBox="0 0 86 86" width="86">
											<clipPath id="b">
												<path d="m917 4402h86v86h-86z" />
											</clipPath>
											<g clip-path="url(#b)" transform="translate(-917 -4402)">
												<g fill="#286ade" transform="translate(917.001 4402.013)">
													<path d="m42.492 68.324a1.8 1.8 0 0 1 -1.268-.523l-23.041-23.038a1.794 1.794 0 0 1 -.072-2.461l17.269-19.344a2.13 2.13 0 0 1 .412-.434c12.278-14.079 31.188-22.915 48.439-22.524a1.793 1.793 0 0 1 1.756 1.748c.283 12.214-4.213 25.735-12.336 37.092a1.79 1.79 0 1 1 -2.909-2.085c7.291-10.2 11.494-22.188 11.673-33.177-15.546.272-33.037 8.738-44.047 21.457a1.762 1.762 0 0 1 -.251.24l-16.194 18.155 20.644 20.64 4.726-4.213a1.791 1.791 0 0 1 2.372 2.683l-5.976 5.331a1.806 1.806 0 0 1 -1.2.451z" />
													<path d="m49.342 81.426a1.624 1.624 0 0 1 -.509-.079 1.772 1.772 0 0 1 -1.275-1.376l-2.437-17.126a1.914 1.914 0 0 1 1.784-1.935 1.665 1.665 0 0 1 1.781 1.644v.036l1.881 13.138c.813-.96 1.566-1.942 2.279-2.956a1.791 1.791 0 1 1 2.931 2.06 45.523 45.523 0 0 1 -5.037 6 2.015 2.015 0 0 1 -1.4.588z" />
													<path d="m23.435 40.887a1.562 1.562 0 0 1 -.258-.018l-17.151-2.458a1.792 1.792 0 0 1 -1.01-3.042 44.876 44.876 0 0 1 32-13.317 1.791 1.791 0 1 1 0 3.583 41.2 41.2 0 0 0 -26.755 9.765l13.428 1.924a1.791 1.791 0 0 1 -.254 3.565z" />
													<path d="m61.384 33.56a8.957 8.957 0 0 1 -6.334-15.291 9.169 9.169 0 0 1 12.665 0 8.957 8.957 0 0 1 -6.331 15.291zm0-14.328a5.374 5.374 0 0 0 -3.8 9.175 5.5 5.5 0 0 0 7.6 0 5.382 5.382 0 0 0 0-7.6 5.324 5.324 0 0 0 -3.8-1.573zm5.066 10.44h.036z" />
													<path d="m1.791 85.987a1.788 1.788 0 0 1 -1.716-2.3c.584-1.964 5.847-19.287 9.681-23.117a11.074 11.074 0 1 1 15.66 15.66c-3.834 3.834-21.149 9.1-23.112 9.684a1.9 1.9 0 0 1 -.512.072zm15.8-25.076a7.485 7.485 0 0 0 -5.3 2.189c-2.067 2.071-5.507 11.318-7.775 18.369 7.051-2.271 16.3-5.711 18.369-7.778a7.483 7.483 0 0 0 -5.3-12.78z" />
													<path d="m73.171 85.987h-6.614c-1.924 0-3.995-1.447-3.995-4.625v-4.673a6.549 6.549 0 0 0 -2.676-5.089 15.877 15.877 0 0 1 -6.141-12.9 16.2 16.2 0 0 1 15.883-15.707 16 16 0 0 1 10.443 28.422 7.375 7.375 0 0 0 -2.906 5.639v4.946a3.994 3.994 0 0 1 -3.995 3.984zm-3.307-39.411h-.19a12.738 12.738 0 0 0 -12.346 12.2 12.322 12.322 0 0 0 4.772 10.003 10.1 10.1 0 0 1 4.049 7.911v4.672c0 .107.011 1.043.412 1.043h6.614a.407.407 0 0 0 .412-.4v-4.947a10.938 10.938 0 0 1 4.228-8.42 12.437 12.437 0 0 0 -7.947-22.063z" />
													<path d="m75.374 79.419h-11.02a1.791 1.791 0 1 1 0-3.583h11.02a1.791 1.791 0 1 1 0 3.583z" />
												</g>
											</g>
										</svg>
									</div>
									<h3 className="tw-text-[26px] tw-font-bold tw-mb-4">Standard</h3>
									<div className="tw-text-[40px] tw-font-bold tw-text-brand-primary-welcome tw-my-[16px]">${this.state.pricingPeriod === "monthly" ? "53.00" : "530.00"}</div>
									<ul className="tw-space-y-[16px] tw-mb-8 tw-text-[16px] tw-text-[#5B657E]">
										<li>Extra features</li>
										<li>Lifetime free support</li>
										<li>Upgrade options</li>
										<li>Full access</li>
									</ul>
									<button className="tw-text-[16px] xl:tw-text-[18px] tw-font-bold tw-bg-brand-primary-welcome tw-text-white tw-px-[16px] tw-py-[12px] tw-rounded-full hover:tw-shadow-lg tw-transition-all">Get Started</button>
								</div>
								<div className="tw-bg-white tw-rounded-[20px] tw-shadow-around tw-p-[16px] tw-text-center hover:tw-shadow-xl tw-transition-all tw-w-full tw-py-[60px] tw-flex tw-flex-col tw-justify-center tw-items-center tw-text-brand-primary-welcome">
									{/* Premium Plan - Monthly */}
									<div className="tw-text-brand-primary-welcome tw-mb-6 tw-bg-brand-primary-light-welcome tw-rounded-full tw-w-[130px] tw-h-[130px] tw-flex tw-items-center tw-justify-center tw-mx-auto">
										<svg height="86" viewBox="0 0 86 86" width="86">
											<clipPath id="c">
												<path d="m1317 4402h86v86h-86z" />
											</clipPath>
											<g clip-path="url(#c)" transform="translate(-1317 -4402)">
												<g fill="#286ade" transform="translate(1317.001 4402.01)">
													<path d="m75.983 85.987a1.8 1.8 0 0 1 -1.007-.308l-12.276-8.356-12.28 8.355a1.789 1.789 0 0 1 -2.741-1.92l3.8-15-11.44-9.767a1.792 1.792 0 0 1 1.064-3.149l14.628-.828 5.288-14.421a1.866 1.866 0 0 1 3.364 0l5.291 14.417 14.628.828a1.792 1.792 0 0 1 1.064 3.149l-11.44 9.767 3.8 15a1.783 1.783 0 0 1 -.706 1.906 1.858 1.858 0 0 1 -1.037.327zm-13.283-12.623a1.8 1.8 0 0 1 1.007.308l9.38 6.384-2.913-11.5a1.8 1.8 0 0 1 .57-1.8l8.892-7.592-11.35-.645a1.792 1.792 0 0 1 -1.58-1.172l-4.006-10.939-4.011 10.938a1.8 1.8 0 0 1 -1.58 1.172l-11.35.645 8.892 7.592a1.787 1.787 0 0 1 .57 1.8l-2.913 11.5 9.38-6.384a1.768 1.768 0 0 1 1.01-.312z" />
													<path d="m34.18 60.011a1.8 1.8 0 0 1 -1.268-.523l-14.726-14.725a1.794 1.794 0 0 1 -.072-2.461l17.269-19.344a2.13 2.13 0 0 1 .412-.434c12.275-14.079 31.231-22.832 48.44-22.524a1.8 1.8 0 0 1 1.752 1.748c.283 12.128-4.174 25.581-12.228 36.906a1.789 1.789 0 1 1 -2.916-2.074c7.226-10.168 11.393-22.095 11.569-33-15.546.272-33.037 8.738-44.047 21.457a1.763 1.763 0 0 1 -.251.24l-16.195 18.15 13.525 13.525a1.793 1.793 0 0 1 0 2.533 1.774 1.774 0 0 1 -1.265.527z" />
													<path d="m23.435 40.887a1.562 1.562 0 0 1 -.258-.018l-17.151-2.458a1.792 1.792 0 0 1 -1.01-3.042 44.876 44.876 0 0 1 32-13.317 1.791 1.791 0 0 1 0 3.583 41.2 41.2 0 0 0 -26.755 9.765l13.428 1.924a1.791 1.791 0 0 1 -.254 3.565z" />
													<path d="m61.384 33.56a8.957 8.957 0 0 1 -6.334-15.291 9.169 9.169 0 0 1 12.665 0 8.957 8.957 0 0 1 -6.331 15.291zm0-14.328a5.374 5.374 0 0 0 -3.8 9.175 5.5 5.5 0 0 0 7.6 0 5.382 5.382 0 0 0 0-7.6 5.324 5.324 0 0 0 -3.8-1.573zm5.066 10.44h.036z" />
													<path d="m1.791 85.987a1.788 1.788 0 0 1 -1.716-2.3c.584-1.964 5.847-19.287 9.681-23.117a11.074 11.074 0 0 1 15.66 15.66c-3.834 3.834-21.149 9.1-23.112 9.684a1.9 1.9 0 0 1 -.512.072zm15.8-25.076a7.485 7.485 0 0 0 -5.3 2.189c-2.067 2.071-5.507 11.318-7.775 18.369 7.051-2.271 16.3-5.711 18.369-7.778a7.483 7.483 0 0 0 -5.3-12.78z" />
												</g>
											</g>
										</svg>
									</div>
									<h3 className="tw-text-[26px] tw-font-bold tw-mb-4">Premium</h3>
									<div className="tw-text-[40px] tw-font-bold tw-text-brand-primary-welcome tw-my-[16px]">${this.state.pricingPeriod === "monthly" ? "93.00" : "930.00"}</div>
									<ul className="tw-space-y-[16px] tw-mb-8 tw-text-[16px] tw-text-[#5B657E]">
										<li>Extra features</li>
										<li>Lifetime free support</li>
										<li>Upgrade options</li>
										<li>Full access</li>
									</ul>
									<button className="tw-text-[16px] xl:tw-text-[18px] tw-font-bold tw-border-2 tw-border-brand-primary-welcome tw-text-brand-primary-welcome hover:tw-bg-brand-primary-welcome hover:tw-text-white tw-px-[16px] tw-py-[12px] tw-rounded-full tw-transition-all">Get Started</button>
								</div>
							</div>

							<div className={`tw-w-full tw-gap-[32px] tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-mx-auto tw-transition-all tw-duration-500 tw-ease-in-out ${this.state.pricingPeriod === "yearly" ? "tw-opacity-100 tw-translate-x-0" : "tw-opacity-0 tw-translate-x-8 tw-absolute tw-inset-0"}`}>
								{/* Yearly Pricing Cards */}
								<div className="tw-bg-white tw-rounded-[20px] tw-shadow-around tw-p-[16px] tw-text-center hover:tw-shadow-xl tw-transition-all tw-w-full tw-py-[60px] tw-flex tw-flex-col tw-justify-center tw-items-center">
									{/* Startup Plan - Yearly */}
									<div className="tw-text-brand-primary-welcome tw-mb-6 tw-bg-brand-primary-light-welcome tw-rounded-full tw-w-[130px] tw-h-[130px] tw-flex tw-items-center tw-justify-center tw-mx-auto">
										<svg height="86" viewBox="0 0 86 86" width="86">
											<clipPath id="d">
												<path d="m517 4402h86v86h-86z" />
											</clipPath>
											<g clip-path="url(#d)" transform="translate(-517 -4402)">
												<g fill="#286ade" transform="translate(517.001 4402.01)">
													<path d="m53.743 85.988a17.914 17.914 0 1 1 0-35.828 17.192 17.192 0 0 1 5.761.964 1.792 1.792 0 0 1 -1.204 3.376 13.56 13.56 0 0 0 -4.557-.756 14.331 14.331 0 1 0 14.331 14.33 13.56 13.56 0 0 0 -.756-4.557 1.792 1.792 0 1 1 3.375-1.2 17.192 17.192 0 0 1 .964 5.761 17.935 17.935 0 0 1 -17.914 17.91z" />
													<path d="m53.743 75.24a7.165 7.165 0 0 1 -5.066-12.232 1.791 1.791 0 0 1 2.533 2.533 3.582 3.582 0 1 0 5.066 5.066 1.791 1.791 0 1 1 2.533 2.533 7.123 7.123 0 0 1 -5.066 2.1z" />
													<path d="m53.743 69.866a1.79 1.79 0 0 1 -1.268-3.056l12.54-12.54a1.791 1.791 0 0 1 2.532 2.53l-12.54 12.54a1.785 1.785 0 0 1 -1.264.526z" />
													<path d="m75.24 57.326h-8.957a1.792 1.792 0 0 1 -1.791-1.791v-8.958a1.8 1.8 0 0 1 .523-1.268l8.957-8.957a1.793 1.793 0 0 1 3.06 1.268v7.166h7.168a1.793 1.793 0 0 1 1.268 3.06l-8.96 8.954a1.8 1.8 0 0 1 -1.268.526zm-7.166-3.583h6.426l5.374-5.374h-4.634a1.792 1.792 0 0 1 -1.791-1.791v-4.634l-5.374 5.374z" />
													<path d="m32.246 58.078a1.8 1.8 0 0 1 -1.268-.523l-12.791-12.791a1.79 1.79 0 0 1 -.068-2.461l17.265-19.348a2.043 2.043 0 0 1 .43-.451c12.286-14.07 31.368-22.826 48.422-22.504a1.793 1.793 0 0 1 1.752 1.752 59.854 59.854 0 0 1 -6.682 27.71 1.79 1.79 0 1 1 -3.2-1.6 57.107 57.107 0 0 0 6.307-24.283c-15.546.272-33.034 8.739-44.044 21.458a1.569 1.569 0 0 1 -.251.236l-16.195 18.155 11.591 11.59a1.793 1.793 0 0 1 -1.268 3.06z" />
													<path d="m23.435 40.887a1.546 1.546 0 0 1 -.254-.018l-17.151-2.457a1.792 1.792 0 0 1 -1-3.049 45.475 45.475 0 0 1 31.988-13.31 1.791 1.791 0 1 1 0 3.583 41.819 41.819 0 0 0 -26.746 9.764l13.418 1.923a1.792 1.792 0 0 1 -.254 3.565z" />
													<path d="m61.385 33.557a8.954 8.954 0 1 1 6.334-2.619 8.929 8.929 0 0 1 -6.334 2.619zm0-14.324a5.371 5.371 0 1 0 3.8 1.569 5.362 5.362 0 0 0 -3.8-1.569zm5.066 10.44h.036z" />
													<path d="m1.791 85.988a1.788 1.788 0 0 1 -1.716-2.3c.588-1.96 5.851-19.279 9.684-23.113a11.074 11.074 0 0 1 15.661 15.657c-3.834 3.834-21.153 9.1-23.113 9.684a1.969 1.969 0 0 1 -.516.072zm15.8-25.076a7.479 7.479 0 0 0 -5.3 2.189c-2.067 2.071-5.507 11.318-7.778 18.373 7.051-2.272 16.3-5.711 18.369-7.778a7.486 7.486 0 0 0 -5.292-12.784z" />
												</g>
											</g>
										</svg>
									</div>
									<h3 className="tw-text-[26px] tw-font-bold tw-mb-4">Startup</h3>
									<div className="tw-text-[40px] tw-font-bold tw-text-brand-primary-welcome tw-my-[16px]">
										<div className={`tw-transition-opacity tw-duration-300 ${this.state.pricingPeriod === "yearly" ? "tw-opacity-100" : "tw-opacity-0"}`}>$130.00</div>
									</div>
									<ul className="tw-space-y-[16px] tw-mb-8 tw-text-[16px] tw-text-[#5B657E]">
										<li>Extra features</li>
										<li>Lifetime free support</li>
										<li>Upgrade options</li>
										<li>Full access</li>
									</ul>
									<button className="tw-text-[16px] xl:tw-text-[18px] tw-font-bold tw-border-2 tw-border-brand-primary-welcome tw-text-brand-primary-welcome hover:tw-bg-brand-primary-welcome hover:tw-text-white tw-px-[16px] tw-py-[12px] tw-rounded-full tw-transition-all">Get Started</button>
								</div>
								<div className="tw-bg-white tw-rounded-[20px] tw-shadow-around tw-p-[16px] tw-text-center hover:tw-shadow-xl tw-transition-all tw-w-full tw-py-[60px] tw-flex tw-flex-col tw-justify-center tw-items-center">
									{/* Standard Plan - Yearly */}
									<div className="tw-text-brand-primary-welcome tw-mb-6 tw-bg-brand-primary-light-welcome tw-rounded-full tw-w-[130px] tw-h-[130px] tw-flex tw-items-center tw-justify-center tw-mx-auto">
										<svg height="86" viewBox="0 0 86 86" width="86">
											<clipPath id="e">
												<path d="m917 4402h86v86h-86z" />
											</clipPath>
											<g clip-path="url(#e)" transform="translate(-917 -4402)">
												<g fill="#286ade" transform="translate(917.001 4402.013)">
													<path d="m42.492 68.324a1.8 1.8 0 0 1 -1.268-.523l-23.041-23.038a1.794 1.794 0 0 1 -.072-2.461l17.269-19.344a2.13 2.13 0 0 1 .412-.434c12.278-14.079 31.188-22.915 48.439-22.524a1.793 1.793 0 0 1 1.756 1.748c.283 12.214-4.213 25.735-12.336 37.092a1.79 1.79 0 1 1 -2.909-2.085c7.291-10.2 11.494-22.188 11.673-33.177-15.546.272-33.037 8.738-44.047 21.457a1.762 1.762 0 0 1 -.251.24l-16.194 18.155 20.644 20.64 4.726-4.213a1.791 1.791 0 0 1 2.372 2.683l-5.976 5.331a1.806 1.806 0 0 1 -1.2.451z" />
													<path d="m49.342 81.426a1.624 1.624 0 0 1 -.509-.079 1.772 1.772 0 0 1 -1.275-1.376l-2.437-17.126a1.914 1.914 0 0 1 1.784-1.935 1.665 1.665 0 0 1 1.781 1.644v.036l1.881 13.138c.813-.96 1.566-1.942 2.279-2.956a1.791 1.791 0 1 1 2.931 2.06 45.523 45.523 0 0 1 -5.037 6 2.015 2.015 0 0 1 -1.4.588z" />
													<path d="m23.435 40.887a1.562 1.562 0 0 1 -.258-.018l-17.151-2.458a1.792 1.792 0 0 1 -1.01-3.042 44.876 44.876 0 0 1 32-13.317 1.791 1.791 0 1 1 0 3.583 41.2 41.2 0 0 0 -26.755 9.765l13.428 1.924a1.791 1.791 0 0 1 -.254 3.565z" />
													<path d="m61.384 33.56a8.957 8.957 0 0 1 -6.334-15.291 9.169 9.169 0 0 1 12.665 0 8.957 8.957 0 0 1 -6.331 15.291zm0-14.328a5.374 5.374 0 0 0 -3.8 9.175 5.5 5.5 0 0 0 7.6 0 5.382 5.382 0 0 0 0-7.6 5.324 5.324 0 0 0 -3.8-1.573zm5.066 10.44h.036z" />
													<path d="m1.791 85.987a1.788 1.788 0 0 1 -1.716-2.3c.584-1.964 5.847-19.287 9.681-23.117a11.074 11.074 0 1 1 15.66 15.66c-3.834 3.834-21.149 9.1-23.112 9.684a1.9 1.9 0 0 1 -.512.072zm15.8-25.076a7.485 7.485 0 0 0 -5.3 2.189c-2.067 2.071-5.507 11.318-7.775 18.369 7.051-2.271 16.3-5.711 18.369-7.778a7.483 7.483 0 0 0 -5.3-12.78z" />
													<path d="m73.171 85.987h-6.614c-1.924 0-3.995-1.447-3.995-4.625v-4.673a6.549 6.549 0 0 0 -2.676-5.089 15.877 15.877 0 0 1 -6.141-12.9 16.2 16.2 0 0 1 15.883-15.707 16 16 0 0 1 10.443 28.422 7.375 7.375 0 0 0 -2.906 5.639v4.946a3.994 3.994 0 0 1 -3.995 3.984zm-3.307-39.411h-.19a12.738 12.738 0 0 0 -12.346 12.2 12.322 12.322 0 0 0 4.772 10.003 10.1 10.1 0 0 1 4.049 7.911v4.672c0 .107.011 1.043.412 1.043h6.614a.407.407 0 0 0 .412-.4v-4.947a10.938 10.938 0 0 1 4.228-8.42 12.437 12.437 0 0 0 -7.947-22.063z" />
													<path d="m75.374 79.419h-11.02a1.791 1.791 0 1 1 0-3.583h11.02a1.791 1.791 0 1 1 0 3.583z" />
												</g>
											</g>
										</svg>
									</div>
									<h3 className="tw-text-[26px] tw-font-bold tw-mb-4">Standard</h3>
									<div className="tw-text-[40px] tw-font-bold tw-text-brand-primary-welcome tw-my-[16px]">${this.state.pricingPeriod === "yearly" ? "530.00" : "530.00"}</div>
									<ul className="tw-space-y-[16px] tw-mb-8 tw-text-[16px] tw-text-[#5B657E]">
										<li>Extra features</li>
										<li>Lifetime free support</li>
										<li>Upgrade options</li>
										<li>Full access</li>
									</ul>
									<button className="tw-text-[16px] xl:tw-text-[18px] tw-font-bold tw-bg-brand-primary-welcome tw-text-white tw-px-[16px] tw-py-[12px] tw-rounded-full hover:tw-shadow-lg tw-transition-all">Get Started</button>
								</div>
								<div className="tw-bg-white tw-rounded-[20px] tw-shadow-around tw-p-[16px] tw-text-center hover:tw-shadow-xl tw-transition-all tw-w-full tw-py-[60px] tw-flex tw-flex-col tw-justify-center tw-items-center">
									{/* Premium Plan - Yearly */}
									<div className="tw-text-brand-primary-welcome tw-mb-6 tw-bg-brand-primary-light-welcome tw-rounded-full tw-w-[130px] tw-h-[130px] tw-flex tw-items-center tw-justify-center tw-mx-auto">
										<svg height="86" viewBox="0 0 86 86" width="86">
											<clipPath id="f">
												<path d="m1317 4402h86v86h-86z" />
											</clipPath>
											<g clip-path="url(#f)" transform="translate(-1317 -4402)">
												<g fill="#286ade" transform="translate(1317.001 4402.01)">
													<path d="m75.983 85.987a1.8 1.8 0 0 1 -1.007-.308l-12.276-8.356-12.28 8.355a1.789 1.789 0 0 1 -2.741-1.92l3.8-15-11.44-9.767a1.792 1.792 0 0 1 1.064-3.149l14.628-.828 5.288-14.421a1.866 1.866 0 0 1 3.364 0l5.291 14.417 14.628.828a1.792 1.792 0 0 1 1.064 3.149l-11.44 9.767 3.8 15a1.783 1.783 0 0 1 -.706 1.906 1.858 1.858 0 0 1 -1.037.327zm-13.283-12.623a1.8 1.8 0 0 1 1.007.308l9.38 6.384-2.913-11.5a1.8 1.8 0 0 1 .57-1.8l8.892-7.592-11.35-.645a1.792 1.792 0 0 1 -1.58-1.172l-4.006-10.939-4.011 10.938a1.8 1.8 0 0 1 -1.58 1.172l-11.35.645 8.892 7.592a1.787 1.787 0 0 1 .57 1.8l-2.913 11.5 9.38-6.384a1.768 1.768 0 0 1 1.01-.312z" />
													<path d="m34.18 60.011a1.8 1.8 0 0 1 -1.268-.523l-14.726-14.725a1.794 1.794 0 0 1 -.072-2.461l17.269-19.344a2.13 2.13 0 0 1 .412-.434c12.275-14.079 31.231-22.832 48.44-22.524a1.8 1.8 0 0 1 1.752 1.748c.283 12.128-4.174 25.581-12.228 36.906a1.789 1.789 0 1 1 -2.916-2.074c7.226-10.168 11.393-22.095 11.569-33-15.546.272-33.037 8.738-44.047 21.457a1.763 1.763 0 0 1 -.251.24l-16.195 18.15 13.525 13.525a1.793 1.793 0 0 1 0 2.533 1.774 1.774 0 0 1 -1.265.527z" />
													<path d="m23.435 40.887a1.562 1.562 0 0 1 -.258-.018l-17.151-2.458a1.792 1.792 0 0 1 -1.01-3.042 44.876 44.876 0 0 1 32-13.317 1.791 1.791 0 0 1 0 3.583 41.2 41.2 0 0 0 -26.755 9.765l13.428 1.924a1.791 1.791 0 0 1 -.254 3.565z" />
													<path d="m61.384 33.56a8.957 8.957 0 0 1 -6.334-15.291 9.169 9.169 0 0 1 12.665 0 8.957 8.957 0 0 1 -6.331 15.291zm0-14.328a5.374 5.374 0 0 0 -3.8 9.175 5.5 5.5 0 0 0 7.6 0 5.382 5.382 0 0 0 0-7.6 5.324 5.324 0 0 0 -3.8-1.573zm5.066 10.44h.036z" />
													<path d="m1.791 85.987a1.788 1.788 0 0 1 -1.716-2.3c.584-1.964 5.847-19.287 9.681-23.117a11.074 11.074 0 0 1 15.66 15.66c-3.834 3.834-21.149 9.1-23.112 9.684a1.9 1.9 0 0 1 -.512.072zm15.8-25.076a7.485 7.485 0 0 0 -5.3 2.189c-2.067 2.071-5.507 11.318-7.775 18.369 7.051-2.271 16.3-5.711 18.369-7.778a7.483 7.483 0 0 0 -5.3-12.78z" />
												</g>
											</g>
										</svg>
									</div>
									<h3 className="tw-text-[26px] tw-font-bold tw-mb-4">Premium</h3>
									<div className="tw-text-[40px] tw-font-bold tw-text-brand-primary-welcome tw-my-[16px]">${this.state.pricingPeriod === "yearly" ? "930.00" : "930.00"}</div>
									<ul className="tw-space-y-[16px] tw-mb-8 tw-text-[16px] tw-text-[#5B657E]">
										<li>Extra features</li>
										<li>Lifetime free support</li>
										<li>Upgrade options</li>
										<li>Full access</li>
									</ul>
									<button className="tw-text-[16px] xl:tw-text-[18px] tw-font-bold tw-border-2 tw-border-brand-primary-welcome tw-text-brand-primary-welcome hover:tw-bg-brand-primary-welcome hover:tw-text-white tw-px-[16px] tw-py-[12px] tw-rounded-full tw-transition-all">Get Started</button>
								</div>
							</div>
						</div>
					</div>
				</section>

				{/* Contact Section */}
				<section id="contact" className="xs:tw-pt-0 sm:tw-pt-20 tw-py-20 tw-bg-white">
					<div className="tw-container tw-mx-auto tw-px-4">
						<div className="tw-text-center tw-mb-16 xs:tw-w-full sm:tw-w-[550px] tw-mx-auto">
							<h2 className="tw-text-[45px] xl:tw-text-[50px] tw-font-bold tw-text-gray-900 tw-mb-4">Get In Touch</h2>
							<p className="tw-text-[18px] tw-text-gray-600">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor</p>
						</div>

						<div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-12 xl:tw-w-[1320px] tw-mx-auto tw-mt-[80px]">
							{/* Contact Form */}
							<div>
								<form onSubmit={this.handleSubmitContact} className="tw-space-y-6">
									<div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6">
										<input type="text" name="name" value={this.state.contactForm.name} onChange={this.handleContactInput} placeholder="Your Name" className="tw-text-[16px] tw-w-full tw-px-4 tw-py-3 tw-rounded-[30px] tw-pl-[30px] tw-bg-white focus:tw-border-brand-primary-welcome focus:tw-border-[1px] focus:tw-ring-1 focus:tw-ring-brand-primary-welcome tw-outline-none tw-transition-all tw-shadow-around tw-rounded-[30px] tw-h-[60px]" required />
										<input type="email" name="email" value={this.state.contactForm.email} onChange={this.handleContactInput} placeholder="Your E-mail" className="tw-text-[16px] tw-w-full tw-px-4 tw-py-3 tw-rounded-[30px] tw-pl-[30px] tw-bg-white focus:tw-border-brand-primary-welcome focus:tw-border-[1px] focus:tw-ring-1 focus:tw-ring-brand-primary-welcome tw-outline-none tw-transition-all tw-shadow-around tw-rounded-[30px] tw-h-[60px]" required />
									</div>
									<div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6">
										<input type="text" name="subject" value={this.state.contactForm.subject} onChange={this.handleContactInput} placeholder="Subject" className="tw-text-[16px] tw-w-full tw-px-4 tw-py-3 tw-rounded-[30px] tw-pl-[30px] tw-bg-white focus:tw-border-brand-primary-welcome focus:tw-border-[1px] focus:tw-ring-1 focus:tw-ring-brand-primary-welcome tw-outline-none tw-transition-all tw-shadow-around tw-rounded-[30px] tw-h-[60px]" required />
										<input type="tel" name="number" value={this.state.contactForm.number} onChange={this.handleContactInput} placeholder="Number" className="tw-text-[16px] tw-w-full tw-px-4 tw-py-3 tw-rounded-[30px] tw-pl-[30px] tw-bg-white focus:tw-border-brand-primary-welcome focus:tw-border-[1px] focus:tw-ring-1 focus:tw-ring-brand-primary-welcome tw-outline-none tw-transition-all tw-shadow-around tw-rounded-[30px] tw-h-[60px]" required />
									</div>
									<textarea name="message" value={this.state.contactForm.message} onChange={this.handleContactInput} placeholder="Message" rows="6" className="tw-text-[16px] tw-w-full tw-px-4 tw-py-3 tw-rounded-[30px] tw-pl-[30px] tw-bg-white focus:tw-border-brand-primary-welcome focus:tw-border-[1px] focus:tw-ring-1 focus:tw-ring-brand-primary-welcome tw-outline-none tw-transition-all tw-shadow-around tw-rounded-[30px]" required></textarea>
									<button type="submit" className="tw-text-[18px] tw-font-[800] tw-bg-brand-primary-welcome tw-text-white tw-px-[30px] tw-py-[12px] tw-rounded-full hover:tw-shadow-lg tw-transition-all">
										Submit Message
									</button>
								</form>
							</div>

							{/* Illustration */}
							<div className="tw-flex tw-items-center tw-justify-center">
								<img src={WelcomeContactUS} alt="Contact Us" className="tw-max-w-full tw-h-auto" />
							</div>
						</div>
					</div>
				</section>

				{/* Scroll to Top Button */}
				<button onClick={this.scrollToTop} className={`tw-fixed tw-bottom-[32px] tw-right-[32px] tw-bg-brand-primary-welcome tw-text-white tw-w-[45px] tw-h-[45px] tw-rounded-[6px] tw-flex tw-items-center tw-justify-center tw-shadow-lg hover:tw-shadow-xl tw-transition-all tw-z-50 ${this.state.showScrollTop ? "tw-opacity-100" : "tw-opacity-0 tw-pointer-events-none"}`} aria-label="Scroll to top">
					<svg className="tw-w-[18px] tw-h-[18px]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
					</svg>
				</button>

				{/* Footer */}
				<footer className="primary-gradient tw-pt-20 tw-pb-8">
					<div className="tw-container tw-mx-auto tw-px-4">
						

						{/* Footer Links */}
						<div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-4 tw-gap-[32px] tw-mb-12">
							{/* Brand */}
							<div>
								<div className="tw-flex tw-items-center tw-gap-2 tw-mb-6">
									<img src={Logo} alt="SaaSLand" className="tw-h-[56px]" />
								</div>
								<div className="tw-flex tw-gap-[32px]">
									<button onClick={() => window.open("https://facebook.com/saasland", "_blank")} className="tw-text-white tw-transition-colors" aria-label="Visit our Facebook page">
										<FacebookIcon />
									</button>
									<button onClick={() => window.open("https://twitter.com/saasland", "_blank")} className="tw-text-white tw-transition-colors" aria-label="Visit our Twitter page">
										<TwitterIcon />
									</button>
									<button onClick={() => window.open("https://instagram.com/saasland", "_blank")} className="tw-text-white tw-transition-colors" aria-label="Visit our Instagram page">
										<InstagramIcon />
									</button>
									<button onClick={() => window.open("https://linkedin.com/company/saasland", "_blank")} className="tw-text-white tw-transition-colors" aria-label="Visit our LinkedIn page">
										<LinkedInIcon />
									</button>
								</div>
							</div>

							{/* Explore Links */}
							<div>
								<h3 className="tw-text-[22px] tw-font-bold tw-text-white tw-mb-4">Explore</h3>
								<ul className="tw-space-y-[14px] tw-mt-[40px]">
									<li>
										<button onClick={() => this.scrollToSection("about")} className="tw-text-[16px] tw-text-white tw-transition-colors">
											About
										</button>
									</li>
									<li>
										<button onClick={() => this.scrollToSection("team")} className="tw-text-[16px] tw-text-white tw-transition-colors">
											Our Team
										</button>
									</li>
									<li>
										<button onClick={() => this.scrollToSection("features")} className="tw-text-[16px] tw-text-white tw-transition-colors">
											Features
										</button>
									</li>
								</ul>
							</div>

							{/* Terms Links */}
							<div>
								<h3 className="tw-text-[22px] tw-font-bold tw-text-white tw-mb-4">Terms</h3>
								<ul className="tw-space-y-[14px] tw-mt-[40px]">
									<li>
										<button className="tw-text-[16px] tw-text-white tw-transition-colors">
											Refund Policy
										</button>
									</li>
									<li>
										<button className="tw-text-[16px] tw-text-white tw-transition-colors">
											Terms of Service
										</button>
									</li>
									<li>
										<button className="tw-text-[16px] tw-text-white tw-transition-colors">
											Support Policy
										</button>
									</li>
								</ul>
							</div>

							{/* Links */}
							<div>
								<h3 className="tw-text-[22px] tw-font-bold tw-text-white tw-mb-4">Links</h3>
								<ul className="tw-space-y-[14px] tw-mt-[40px]">
									<li>
										<button className="tw-text-[16px] tw-text-white tw-transition-colors">
											Help
										</button>
									</li>
									<li>
										<button className="tw-text-[16px] tw-text-white tw-transition-colors">
											Support
										</button>
									</li>
									<li>
										<button className="tw-text-[16px] tw-text-white tw-transition-colors">
											Contact
										</button>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</footer>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		currentUser: UserSelectors.selectCurrentUser(state),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		globalAlertActions: bindActionCreators(globalAlertActionCreator, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage);
