import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Collapse } from 'reactstrap';
import classNames from 'classnames';
import { Switch, Route, Redirect } from 'react-router-dom';

// --- Components --- //
import Header from '../components/common/header';
import DashboardPage from '../pages/DashboardPage';
import PaymentPage from '../pages/PaymentPage';

import OrderPage from '../pages/order/OrderPage';
import OrderDetailPage from '../pages/order/OrderDetailPage';

import WalletPage from './withdraw/WalletPage';
import WithdrawPage from './withdraw/WithdrawPage';

import UserPage from '../pages/user/UserPage';
import UserEditPage from '../pages/user/UserEditPage';

import StCardPage from '../pages/st/StCardPage';
import StGroupPage from '../pages/st/StGroupPage';
import StEditPage from '../pages/st/StEditPage';
import StGroupEditPage from '../pages/st/StGroupEditPage';

import ProductListPage from '../pages/product/ProductListPage';
import ProductEditPage from '../pages/product/ProductEditPage';
import ProductCategoryListPage from '../pages/product/ProductCategoryListPage';
import ProductCategoryEditPage from '../pages/product/ProductCategoryEditPage';

import AccountCountryListPage from '../pages/country/AccountCountryListPage';
import AccountCountryEditPage from '../pages/country/AccountCountryEditPage';

import BlacklistPage from '../pages/blacklist/BlacklistPage';
import BlacklistEditPage from '../pages/blacklist/BlacklistEditPage';

import IbanPage from '../pages/iban/IbanPage';
import IbanEditPage from '../pages/iban/IbanEditPage';

import AchPage from '../pages/ach/AchPage';
import AchEditPage from '../pages/ach/AchEditPage';

import CoinPage from '../pages/coin/CoinPage';
import CoinEditPage from '../pages/coin/CoinEditPage';

// --- Constants --- //
import PagePathConstants from '../constants/router/PagePathConstants';

// --- Actions --- //
import * as userActionCreator from '../actions/userActionCreator';
import * as routeActionCreator from '../actions/routeActionCreator';

// --- Icons --- //
import TriangleRightIcon from '../icons/TriangleRightIcon';

// --- Selectors --- //
import * as UserSelectors from '../selectors/UserSelectors';
import * as RouteSelectors from '../selectors/RouteSelectors';

// --- Factories --- //
import WithLoggedInRedirectionFactory from '../factories/WithLoggedInRedirectionFactory';
import UserHelper from '../helpers/UserHelper';
import { BASE_URL } from '../constants/api/ApiEndpointConstants';

class EntrancePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showProfileDropdown: false,
      showPaymentTypeDropdown: false,
      isCallapsed: false,
    }
    this.toggleProfileDropdown = this.toggleProfileDropdown.bind(this);
    this.logout = this.logout.bind(this);
    this.routeToSpecPage = this.routeToSpecPage.bind(this);
    this.updateActiveRoute = this.updateActiveRoute.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleCollapseStatus = this.toggleCollapseStatus.bind(this);
  }

  componentDidMount() {
    this.updateActiveRoute();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location, navRouterList } = nextProps;
    if ((location.pathname && location.pathname !== this.props.location.pathname)) {
      this.updateActiveRoute(nextProps);
    }
  }

  updateActiveRoute(props) {
    let { navRouterList, location, routeActions } = props || this.props;
    navRouterList = navRouterList.map(nav => {
      if (location.pathname.indexOf(nav.path) >= 0) {
        nav.isOpen = true;
      }
      return nav;
    });
    routeActions && routeActions.updateMenuStatus(navRouterList);

  }

  toggleMenu(index, menu) {
    if (!menu.children) {
      this.routeToSpecPage(menu.path);
    } else {
      this.routeToSpecPage(menu.children[0].path)
    }
    let { navRouterList } = this.props;
    navRouterList = navRouterList.map((item, i) => {
      if (index === i) {
        item.isOpen = !item.isOpen
      } else {
        item.isOpen = false
      }
      return item;
    });
    const { routeActions } = this.props;
    routeActions && routeActions.toggleMenuStatus(navRouterList);
  }

  toggleCollapseStatus() {
    this.setState({ isCallapsed: !this.state.isCallapsed });
  }

  routeToSpecPage(url) {
    const { history } = this.props;
    url && history && history.push(url);
  }

  toggleProfileDropdown() {
    this.setState({ showProfileDropdown: !this.state.showProfileDropdown });
  }

  togglePaymenTypeDropdown() {
    this.setState({ showPaymentTypeDropdown: !this.state.showPaymentTypeDropdown });
  }

  logout() {
    const { userActions, history } = this.props;
    userActions && userActions.logout()
      .then(() => {
        history && history.push(PagePathConstants.LOGIN_PAGE);
      });
  }

  switchPaymentType(type) {
    const { userActions } = this.props;
    userActions && userActions.updatePaymentTypeAction(type);
  }

  render() {
    const { history, currentUser, navRouterList } = this.props;
    const pathname = history.location && history.location.pathname;
    const isAdmin = UserHelper.isAdminUser(currentUser);
    const isCustomer = UserHelper.isCustomerUser(currentUser);
    const { isCallapsed } = this.state;
    return <div className='tw-w-full tw-h-full tw-flex  tw-bg-[#f6f6f6] tw-text-body-text-primary'>
      <div className={classNames('tw-w-[300px] tw-h-full tw-pb-[30px] tw-bg-brand-primary', { 'collapse-menu': isCallapsed })}>
        <div className='tw-w-full tw-h-[80px] tw-flex tw-justify-center tw-items-center tw-text-[18px] tw-font-bold tw-px-[30px]'>
          <div class="tw-w-[53px] tw-h-[53px] tw-bg-white  tw-flex tw-items-center tw-justify-center tw-text-[22px] tw-rounded-full">St</div>
          {!isCallapsed && <div className='tw-ml-[20px] tw-text-[26px] tw-font-bold tw-text-white'>Admin</div>}
        </div>
        {/* {!isCallapsed && <div className='tw-px-[12px] tw-py-[12px] tw-rounded-[15px] tw-flex tw-justify-start tw-items-center tw-border tw-mr-[30px]'>
          <div className='tw-w-[55px] tw-h-[55px] tw-rounded-full tw-overflow-hidden' style={{ backgroundImage: `url(${currentUser && (BASE_URL + currentUser.avatar)})`, backgroundSize: '100%', backgroundPosition: 'center' }}></div>
          <div className='tw-w-[calc(100%-55px)] tw-pl-[20px] gotham-font'>
            <div className='tw-text-[16px]'>Hi, </div>
            <div className='tw-w-full tw-font-[600] tw-text-[18px] tw-line-clamp-1 tw-whitespace-nowrap tw-text-ellipsis tw-block'>{currentUser && currentUser.username}</div>
          </div>
        </div>} */}

        <div className='tw-w-full tw-py-[20px] tw-px-[20px]'>
          {navRouterList.map((menu, index) => {
            return (<div className='tw-text-body-text-grey' key={menu.path}>
              <div className={classNames(`tw-w-full tw-pl-[20px] tw-pr-[30px] tw-h-[50px] tw-flex tw-justify-between tw-items-center tw-text-[16px] tw-relative route-menu tw-mx-auto`, { 'hs-open-menu': menu.isOpen })} onClick={() => this.toggleMenu(index, menu)}>
                {/* {!isCallapsed && <i className='tw-absolute tw-left-0 tw-top-0 tw-bg-none tw-w-[0px] tw-h-full'></i>} */}
                <div className='tw-flex tw-items-center'>
                  <i className={`${menu.icon} tw-mt-[1px]`}></i>
                  {!isCallapsed && <div className='tw-ml-[20px] tw-font-[600] tw-text-[16px]'>{menu.text}</div>}
                </div>
                {!isCallapsed && menu.children && <TriangleRightIcon size={16} className="arrow-icon " />}
              </div>
              <Collapse isOpen={menu.isOpen} navbar>
                {menu.children && menu.children.map(childMenu => {
                  return <div key={childMenu.path} className={classNames('tw-w-full tw-pl-[52px] tw-py-[10px] tw-text-[#596b8c] tw-text-[15px]', { ' tw-text-brand-primary-light': pathname === childMenu.path })} onClick={() => this.routeToSpecPage(childMenu.path)}>{childMenu.text}</div>
                })}
              </Collapse>
            </div>)
          })}
        </div>
      </div>
      <div className={classNames('tw-w-[calc(100%-300px)]', { 'full-route': isCallapsed })}>
        <Header {...this.props} toggleCollapseStatus={this.toggleCollapseStatus} isCallapsed={isCallapsed} />
        <div className='tw-w-full tw-h-[calc(100%-80px)] tw-overflow-y-scroll scrollbar-hidden tw-pb-[50px]'>
          <Switch>
            <Route path={PagePathConstants.DASHBOARD} component={DashboardPage} />

            <Route path={PagePathConstants.ORDER_DETAIL_PAGE} component={OrderDetailPage} />
            <Route path={PagePathConstants.ORDER_PAGE} component={OrderPage} />

            <Route path={PagePathConstants.PAYMENT_PAGE} component={PaymentPage} />

            <Route path={PagePathConstants.WALLET_PAGE} component={WalletPage} />
            <Route path={PagePathConstants.WITHDRAW_PAGE} component={WithdrawPage} />

            {isAdmin && <Route path={PagePathConstants.USER} component={UserPage} exact />}
            {isAdmin && <Route path={PagePathConstants.USER_EDIT} component={UserEditPage} />}

            <Route path={PagePathConstants.BLACKLIST} component={BlacklistPage} exact />
            <Route path={PagePathConstants.BLACKLIST_EDIT} component={BlacklistEditPage} />

            {(isAdmin || isCustomer) && <Route path={PagePathConstants.ST} component={StCardPage} exact />}
            {isAdmin && <Route path={PagePathConstants.ST_EDIT} component={StEditPage} />}
            {isAdmin && <Route path={PagePathConstants.ST_GROUP_EDIT} component={StGroupEditPage} />}
            {isAdmin && <Route path={PagePathConstants.ST_GROUP} component={StGroupPage} />}

            {isAdmin && <Route path={PagePathConstants.IBAN} component={IbanPage} exact />}
            {isAdmin && <Route path={PagePathConstants.IBAN_EDIT} component={IbanEditPage} />}

            {isAdmin && <Route path={PagePathConstants.ACH} component={AchPage} exact />}
            {isAdmin && <Route path={PagePathConstants.ACH_EDIT} component={AchEditPage} />}

            {isAdmin && <Route path={PagePathConstants.COIN} component={CoinPage} exact />}
            {isAdmin && <Route path={PagePathConstants.COIN_EDIT} component={CoinEditPage} />}

            {isAdmin && <Route path={PagePathConstants.PRODUCT_EDIT} component={ProductEditPage} />}
            {isAdmin && <Route path={PagePathConstants.PRODUCT} component={ProductListPage} exact />}
            {isAdmin && <Route path={PagePathConstants.PRODUCT_CATETORY_EDIT} component={ProductCategoryEditPage} />}
            {isAdmin && <Route path={PagePathConstants.PRODUCT_CATETORY} component={ProductCategoryListPage} />}

            {isAdmin && <Route path={PagePathConstants.ACCOUNT_COUNTRY_EDIT} component={AccountCountryEditPage} />}
            {isAdmin && <Route path={PagePathConstants.ACCOUNT_COUNTRY} component={AccountCountryListPage} exact />}
            <Redirect exact to={PagePathConstants.DASHBOARD} />
          </Switch>
        </div>
      </div>

    </div>;
  }
}

function mapStateToProps(state) {
  return {
    currentUser: UserSelectors.selectCurrentUser(state),
    paymentType: UserSelectors.selectPaymentType(state),
    navRouterList: RouteSelectors.selectRouteList(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActionCreator, dispatch),
    routeActions: bindActionCreators(routeActionCreator, dispatch),
  };
}

export default WithLoggedInRedirectionFactory(connect(mapStateToProps, mapDispatchToProps)(EntrancePage));