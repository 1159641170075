import ActionConstants from '../constants/actions/ActionConstants';
import * as AccountCountryAPI from '../apis/AccountCountryAPI';
import { showErrorGlobalAlert, showSuccessGlobalAlert } from './globalAlertActionCreator';

export const updateAccountCountryListAction = (countryList, total) => ({
    type: ActionConstants.UPDATE_ACCOUNT_COUNTRY_LIST,
    payload: { data: countryList, total: total },
});

export const updateUnpagedAccountCountryListAction = (countryList, total) => ({
    type: ActionConstants.UPDATE_UNPAGED_ACCOUNT_COUNTRY_LIST,
    payload: { data: countryList, total: total },
});

export const fetchCountryList = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            AccountCountryAPI.fetchAccountCountryList(params)
                .then((res) => {
                    if (res.status === 200) {
                        const countryList = res.data.data.list;
                        const total = res.data.data.total;
                        dispatch(updateAccountCountryListAction(countryList, total));
                        return resolve('success');
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}

export const fetchUnpagedAccountCountryList = () => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            return AccountCountryAPI.fetchUnpagedAccountCountryList()
                .then((res) => {
                    if (res.status === 200) {
                        const countryList = res.data.data.list;
                        const total = res.data.data.total;
                        dispatch(updateUnpagedAccountCountryListAction(countryList, total));
                        return resolve('success');
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}

export const fetchAccountCountryDetailById = (id) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            AccountCountryAPI.fetchAccountCountryDetail(id)
                .then((res) => {  
                    if (res.status === 200) {
                        return resolve(res.data.data);
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}

export const createOrEditAccountCountryInfo = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            AccountCountryAPI.createOrEditAccountCountry(params)
                .then((res) => {
                    if (res.status === 200) {
                        if (params.id) {
                            dispatch(showSuccessGlobalAlert('create-account-country-succeed'));
                        }
                        dispatch(showSuccessGlobalAlert('edit-account-country-succeed'));
                        return resolve('success');
                    }
                })
                .catch((err) => {
                    if (params.id) {
                        dispatch(showErrorGlobalAlert('create-account-country-faileds'));
                    }
                    dispatch(showErrorGlobalAlert('edit-account-country-failed'));
                    return reject(err)
                });
        });
    };
}

export const deleteAccountCountry = (id) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            AccountCountryAPI.deleteAccountCountry(id)
                .then((res) => {
                    if (res.status === 200) {
                        dispatch(showSuccessGlobalAlert('delete-info-succeed'));
                        return resolve('success');
                    }
                })
                .catch((err) => {
                    dispatch(showErrorGlobalAlert('delete-info-failed'));
                    return reject(err);
                });
        });
    };
}