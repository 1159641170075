import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";

import { bindActionCreators } from "redux";
import { Pagination } from "element-react";
import Switch from "@material-ui/core/Switch";

// --- Components --- //
import Loading from "../../components/common/LoadingIndicator";
import SvgIcon from "../../components/common/SvgIcon";
import EDSInput from "../../components/common/EDSInput";
import CountryFlag from "../../components/common/CountryFlag/index";

// --- Icons --- //
import SearchIcon from "../../icons/SearchIcon";
import DollarIcon from "../../icons/DollarIcon";
import JPYIcon from "../../icons/JPYIcon";
import EURIcon from "../../icons/EURIcon";
import GBPIcon from "../../icons/GBPIcon";
import ClockIcon from "../../icons/ClockIcon";

// --- Constants --- //
import ColorConstants from "../../constants/ColorConstants";
import DialogConstants from "../../constants/dialog/index";

// --- Actions --- //
import * as stCardActionCreator from "../../actions/stCardActionCreator";
import * as basicConfigActionCreator from "../../actions/basicConfigActionCreator";
import * as orderActionCreator from "../../actions/orderActionCreator";

// --- Selectors --- //
import * as SdCardSelectors from "../../selectors/SdCardSelectors";
import * as UserSelectors from "../../selectors/UserSelectors";

import I18N from "../../i18n";
import RDSButton from "../../components/common/RDSButton";
import PagePathConstants from "../../constants/router/PagePathConstants";
import DeleteConfirmationModal from "../../components/common/dialog/DeleteConfirmationModal";
import classNames from "classnames";
import LocalStorageConstants from "../../constants/LocalStorageConstants";
import LocalStorageUtil from "../../utils/LocalStorageUtil";
import * as DateTimeUtil from "../../utils/DateTimeUtil";
import UserHelper from "../../helpers/UserHelper";

const ORDER_FILTER_TYPE = [
	{
		id: "",
		text: "All",
	},
	{
		id: 0,
		text: "Off",
	},
	{
		id: 1,
		text: "T2",
	},
	{
		id: 2,
		text: "T7",
	},
];
const CACHED_PARAM_KEY = LocalStorageConstants.ST_ACCOUNT_CACHED_PARAM;
class STCardPage extends Component {
	constructor(props, context) {
		super(props, context);
		const cachedParam = LocalStorageUtil.loadCachedParam(CACHED_PARAM_KEY);
		this.state = {
			sdCardListParams: cachedParam
				? cachedParam
				: {
					page: 1,
					per_page: 5,
					status: "",
					keywords: "",
					user_id: "",
				},
			currencyList: null,
			showLoading: false,
			showDeleteConfirmModal: false,
			deleteItemId: "",
			cancelItem: "",

			currentFraudList: null,
			showFraudModal: false,
			fraudListHasMore: false,
			fraudListLoading: false,
			showCancelConfirmModal: false,
			viewStCardId: null,

			showDisputeModal: false,
			disputeListHasMore: false,
			disputeListLoading: false,

			currentDisputeList: null,

			selectedUser: null,

			isTimerHovered: false,
			stTimingData: null,
		};

		this.getSTRecordList = this.getSTRecordList.bind(this);
		this.handleSdCardFilterClick = this.handleSdCardFilterClick.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.toggleLoading = this.toggleLoading.bind(this);
		this.handleCommentFilterChange = this.handleCommentFilterChange.bind(this);
		this.handleEditSdCard = this.handleEditSdCard.bind(this);
		this.handleDeleteSdCard = this.handleDeleteSdCard.bind(this);
		this.createNewSdCard = this.createNewSdCard.bind(this);
		this.toggleDeleteConfirmModal = this.toggleDeleteConfirmModal.bind(this);
		this.handleDeleteSTCard = this.handleDeleteSTCard.bind(this);
		this.fetchCurrencyList = this.fetchCurrencyList.bind(this);
		this.searchNewStCardRecord = this.searchNewStCardRecord.bind(this);
		this.handleMaxPollingChange = this.handleMaxPollingChange.bind(this);
		this.handleViewFraudList = this.handleViewFraudList.bind(this);
		this.toggleFraudModal = this.toggleFraudModal.bind(this);
		this.handleCancelCharge = this.handleCancelCharge.bind(this);
		this.toggleCancelConfirmModal = this.toggleCancelConfirmModal.bind(this);
		this.handleCancelChargeOrder = this.handleCancelChargeOrder.bind(this);
		this.toggleDisputeModal = this.toggleDisputeModal.bind(this);
		this.handleViewDisputeList = this.handleViewDisputeList.bind(this);
		this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
		this.resetQueryParamsByCachedData = this.resetQueryParamsByCachedData.bind(this);
		this.handleSelectedUser = this.handleSelectedUser.bind(this);
		this.setTimerIsHovered = this.setTimerIsHovered.bind(this);
		this.fetchStTimerData = this.fetchStTimerData.bind(this);
		this.handleChangeSTTimingData = this.handleChangeSTTimingData.bind(this);
		this.handleStTimingBlur = this.handleStTimingBlur.bind(this);
		this.handleLoadMoreFraudList = this.handleLoadMoreFraudList.bind(this);
		this.toggleFraudlistLoading = this.toggleFraudlistLoading.bind(this);

		this.handleLoadMoreDisputeList = this.handleLoadMoreDisputeList.bind(this);
		this.toggleDisputeListLoading = this.toggleDisputeListLoading.bind(this);
	}

	componentDidMount() {
		this.getSTRecordList();
		this.fetchCurrencyList();
		this.resetQueryParamsByCachedData();
		this.fetchStTimerData();
	}

	handleLoadMoreFraudList() {
		const { stCardActions } = this.props;
		let {viewStCardId, currentFraudList} = this.state;
		this.toggleFraudlistLoading();
		stCardActions &&
			stCardActions
				.fetchSTFraudListById({id: viewStCardId, starting_after: currentFraudList[currentFraudList.length - 1].id})
				.then((data) => {
					this.toggleFraudlistLoading();
					this.setState({
						currentFraudList: currentFraudList.concat(data.fraudList),
						fraudListHasMore: data.has_more
					});
				})
				.catch(() => {
					this.toggleFraudlistLoading();
				});
	}

	handleLoadMoreDisputeList() {
		const { stCardActions } = this.props;
		let {viewStCardId, currentDisputeList} = this.state;
		this.toggleDisputeListLoading();
		stCardActions &&
			stCardActions
				.fetchSTDisputeListById({id: viewStCardId, starting_after: currentDisputeList[currentDisputeList.length - 1].id})
				.then((data) => {
					this.toggleDisputeListLoading();
					this.setState({
						currentDisputeList: currentDisputeList.concat(data.disputeList),
						disputeListHasMore: data.has_more
					});
				})
				.catch(() => {
					this.toggleDisputeListLoading();
				});
	}

	handleChangeSTTimingData(e) {
		const stTimingData = e && e.target.value;
		const regx = new RegExp(/^[0-9]{1,}$/);
		if (regx.test(stTimingData) || stTimingData === "") {
			if (stTimingData === "") {
				this.setState({ stTimingData: null });
			} else {
				this.setState({ stTimingData: parseInt(stTimingData) });
			}
		} else {
			this.setState({ stTimingData: "" });
		}
	}

	handleStTimingBlur(e) {
		const { stTimingData } = this.state;
		const { stCardActions } = this.props;
		stCardActions &&
			stCardActions.setSTAccountTimingData(parseInt(stTimingData)).then((data) => {
				console.log(data);
				this.setTimerIsHovered(false);
			});
	}

	fetchStTimerData() {
		const { stCardActions } = this.props;
		stCardActions.setSTAccountTimingData().then((data) => {
			this.setState({ stTimingData: data });
		});
	}

	setTimerIsHovered(isTimerHovered) {
		if (isTimerHovered === undefined) {
			this.setState({ isTimerHovered: !this.state.isTimerHovered });
		} else {
			this.setState({ isTimerHovered });
		}
	}

	resetQueryParamsByCachedData(props) {
		const cachedParam = LocalStorageUtil.loadCachedParam(CACHED_PARAM_KEY);
		if (cachedParam && cachedParam.user_id !== "") {
			const { sdCardListParams } = this.state;
			const { unpagedUserList } = props || this.props;
			const filterUsers = unpagedUserList.filter((user) => {
				return user.value === sdCardListParams.user_id;
			});
			if (filterUsers.length > 0) {
				this.setState({ selectedUser: filterUsers[0] });
			}
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.unpagedUserList !== this.props.unpagedUserList) {
			this.resetQueryParamsByCachedData(nextProps);
		}
	}

	fetchCurrencyList() {
		const { basicConfigActions } = this.props;
		basicConfigActions &&
			basicConfigActions.fetchCurrencyList().then((data) => {
				const currencyList =
					data &&
					data.map((item) => {
						return {
							value: item.code,
							label: item.currency,
						};
					});
				this.setState({ currencyList });
			});
	}

	toggleDeleteConfirmModal() {
		this.setState({ showDeleteConfirmModal: !this.state.showDeleteConfirmModal });
	}

	handleEditSdCard(id) {
		const { history } = this.props;
		history && history.push(PagePathConstants.ST_EDIT.replace(":id", id));
	}

	handleDeleteSdCard(id) {
		this.toggleDeleteConfirmModal();
		this.setState({ deleteItemId: id });
	}

	handleDeleteSTCard() {
		const { stCardActions } = this.props;
		const { deleteItemId } = this.state;

		stCardActions &&
			deleteItemId &&
			deleteItemId !== "" &&
			stCardActions
				.deleteSTCardInfoById(this.state.deleteItemId)
				.then((data) => {
					this.toggleDeleteConfirmModal();
					this.getSTRecordList();
					return;
				})
				.catch(this.toggleDeleteConfirmModal);
	}

	toggleLoading() {
		this.setState({ showLoading: !this.state.showLoading });
	}

	toggleFraudlistLoading() {
		this.setState({ fraudListLoading: !this.state.fraudListLoading });
	}

	toggleDisputeListLoading() {
		this.setState({ disputeListLoading: !this.state.disputeListLoading });
	}

	handleCommentFilterChange(e) {
		const comment = e && e.target.value;
		const { sdCardListParams } = this.state;
		sdCardListParams.keywords = comment;
		this.setState({ sdCardListParams }, () => {
			LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, sdCardListParams);
		});
	}

	getSTRecordList(params) {
		if (!params) {
			params = this.state.sdCardListParams;
		}
		const { stCardActions } = this.props;
		this.toggleLoading();
		return (
			stCardActions &&
			stCardActions
				.getSdCardList(params)
				.then((data) => {
					this.toggleLoading();
					return;
				})
				.catch(this.toggleLoading)
		);
	}

	handleSdCardFilterClick(status) {
		let { sdCardListParams } = this.state;
		sdCardListParams.status = status.id;
		sdCardListParams.page = 1;
		this.setState({ sdCardListParams }, () => {
			LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, sdCardListParams);
		});
		this.getSTRecordList(sdCardListParams);
	}

	searchNewStCardRecord() {
		let { sdCardListParams } = this.state;
		sdCardListParams.page = 1;
		this.setState({ sdCardListParams }, () => {
			this.getSTRecordList(sdCardListParams);
			LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, sdCardListParams);
		});
	}

	handlePageChange(page) {
		let { sdCardListParams } = this.state;
		sdCardListParams.page = page;
		this.getSTRecordList(sdCardListParams).then(() => {
			LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, sdCardListParams);
			this.setState({ sdCardListParams });
		});
	}

	createNewSdCard() {
		const { history } = this.props;
		history && history.push(PagePathConstants.ST_ADD);
	}

	renderSTCardStatus(state) {
		switch (state) {
			case 2: {
				return (
					<div className="tw-min-w-[80px] tw-rounded-[20px] tw-px-[12px] tw-py-[4px] tw-text-[12px] tw-bg-status-notified-bg tw-text-white tw-flex tw-items-center tw-justify-center">
						<span>{I18N.getText("t7")}</span>
					</div>
				);
			}
			case 1: {
				return (
					<div className="tw-min-w-[80px] tw-rounded-[20px] tw-px-[12px] tw-py-[4px] tw-text-[12px] tw-bg-status-succeed-bg tw-text-white tw-flex tw-items-center tw-justify-center">
						<span>{I18N.getText("t2")}</span>
					</div>
				);
			}
			case 0:
			default: {
				return (
					<div className="tw-min-w-[80px] tw-rounded-[20px] tw-px-[12px] tw-py-[4px] tw-text-[12px] tw-bg-status-timeout-bg tw-text-white tw-flex tw-items-center tw-justify-center">
						<span>{I18N.getText("off")}</span>
					</div>
				);
			}
		}
	}

	handleMaxPollingChange(record, e) {
		const { stCardActions } = this.props;
		let newStcardInfo = Object.assign({}, record);
		if (record.lunxun_max <= 1) {
			newStcardInfo.lunxun_max = 3;
		} else {
			newStcardInfo.lunxun_max = 1;
		}
		this.toggleLoading();
		stCardActions &&
			stCardActions
				.editSTCardInfoById(newStcardInfo)
				.then(() => {
					this.toggleLoading();
					this.getSTRecordList();
				})
				.catch(() => {
					this.toggleLoading();
				});
	}

	handleViewFraudList(id) {
		const { stCardActions } = this.props;
		this.setState({ viewStCardId: id });
		this.toggleLoading();
		stCardActions &&
			stCardActions
				.fetchSTFraudListById({id})
				.then((data) => {
					this.toggleLoading();
					this.setState({
						showFraudModal: !this.state.showFraudModal,
						currentFraudList: data.fraudList,
						fraudListHasMore: data.has_more
					});
				})
				.catch(() => {
					this.toggleLoading();
				});
	}

	handleViewDisputeList(id) {
		const { stCardActions } = this.props;
		this.setState({ viewStCardId: id });
		this.toggleLoading();
		stCardActions &&
			stCardActions
				.fetchSTDisputeListById({id})
				.then((data) => {
					this.toggleLoading();
					this.setState({
						showDisputeModal: !this.state.showDisputeModal,
						currentDisputeList: data.disputeList,
					});
				})
				.catch(() => {
					this.toggleLoading();
				});
	}

	toggleFraudModal() {
		this.setState({ showFraudModal: !this.state.showFraudModal }, () => {
			this.setState({ viewStCardId: null });
		});
	}

	toggleDisputeModal() {
		this.setState({ showDisputeModal: !this.state.showDisputeModal }, () => {
			this.setState({ viewStCardId: null });
		});
	}

	handleCancelCharge(record) {
		this.toggleCancelConfirmModal();
		this.setState({ cancelItem: record });
	}

	toggleCancelConfirmModal() {
		this.setState({ showCancelConfirmModal: !this.state.showCancelConfirmModal });
	}

	handleCancelChargeOrder() {
		const { orderActions } = this.props;
		const { cancelItem, viewStCardId } = this.state;
		viewStCardId &&
			orderActions &&
			cancelItem &&
			cancelItem !== "" &&
			orderActions
				.cancelSTChargeOrder({ id: viewStCardId, charge: cancelItem.charge })
				.then((data) => {
					this.toggleCancelConfirmModal();
					return;
				})
				.catch(this.toggleCancelConfirmModal);
	}

	handlePageSizeChange(pageSize) {
		let { sdCardListParams } = this.state;
		sdCardListParams.per_page = pageSize;
		sdCardListParams.page = 1;
		this.getSTRecordList(sdCardListParams).then(() => {
			LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, sdCardListParams);
			this.setState({ sdCardListParams });
		});
	}

	handleSelectedUser(selectedUser) {
		let sdCardListParams = Object.assign({}, this.state.sdCardListParams);
		if (selectedUser) {
			sdCardListParams.user_id = selectedUser.value;
		} else {
			sdCardListParams.user_id = '';
		}
		sdCardListParams.page = 1;
		LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, sdCardListParams);
		this.setState(
			{
				selectedUser,
				sdCardListParams,
				showError: false,
			},
			this.getSTRecordList
		);
	}

	render() {
		const { stCardList, stCardTotalNum, currentUser, unpagedUserList } = this.props;
		const { sdCardListParams, showLoading, showDeleteConfirmModal, showFraudModal, currentFraudList, fraudListHasMore, fraudListLoading, disputeListHasMore, disputeListLoading, currentDisputeList, showCancelConfirmModal, showDisputeModal, selectedUser, isTimerHovered, stTimingData } = this.state;
		const total = Math.ceil(stCardTotalNum / sdCardListParams.per_page);
		const isAdmin = UserHelper.isAdminUser(currentUser);
		return (
			<div className="tw-w-full tw-px-[20px]">
				{showLoading && <Loading />}
				<DeleteConfirmationModal isDeleteModalOpen={showDeleteConfirmModal} toggle={this.toggleDeleteConfirmModal} handleDeleteConfirm={this.handleDeleteSTCard} sectionName={DialogConstants.ST_CARD} />
				<DeleteConfirmationModal isDeleteModalOpen={showCancelConfirmModal} toggle={this.toggleCancelConfirmModal} handleDeleteConfirm={this.handleCancelChargeOrder} sectionName={DialogConstants.CANCEL_CHARGE_STATUS} />
				{showFraudModal && (
					<div className="tw-w-full tw-h-full tw-fixed tw-top-0 tw-left-0 tw-bg-[rgba(0,0,0,0.7)] tw-z-[1049] tw-flex tw-justify-center tw-items-center" onClick={this.toggleFraudModal}>
						<div className="tw-w-[70%] tw-max-w-[1200px] tw-h-[40%] tw-max-h-[80%] tw-rounded-[30px] tw-bg-white" onClick={(e) => e.stopPropagation()}>
							<div className="tw-flex tw-items-center tw-font-bold tw-text-[20px] tw-h-[70px] tw-border-b tw-border-[rgb(233, 233, 239)] tw-px-[20px]">Empty early fraud warning list</div>
							<div className="tw-w-full tw-h-[calc(100%-70px)] tw-overflow-y-auto tw-pt-[20px] tw-pb-[30px] tw-px-[20px]">
								<div className="tw-flex tw-justify-between tw-items-center tw-text-center tw-font-bold tw-text-[12px] tw-mb-[10px] tw-h-[40px]  tw-rounded-[10px] tw-shadow-table tw-text-[16px] tw-font-bold">
									<div className="tw-w-[5%] tw-text-center">#</div>
									<div className="tw-w-[20%] tw-text-center">Charge ID</div>
									<div className="tw-w-[20%] tw-text-center">Payment Intent ID</div>
									<div className="tw-w-[10%] tw-text-center">Customer</div>
									<div className="tw-w-[20%] tw-text-center">Customer Email</div>
									<div className="tw-w-[15%] tw-text-center">{I18N.getText("date")}</div>
									<div className="tw-w-[10%] tw-text-center">{I18N.getText("operation")}</div>
								</div>
								{currentFraudList &&
									currentFraudList.map((item, index) => {
										return (
											<div className="tw-w-full tw-text-[12px] tw-pb-[10px] tw-shadow-table tw-flex tw-items-center tw-justify-between tw-mt-[20px] tw-border-b tw-border-[rgb(233, 233, 239)]">
												<div className="tw-w-[5%] tw-text-center">{index + 1}</div>
												<div className="tw-w-[20%] tw-text-center">{item.charge}</div>
												<div className="tw-w-[20%] tw-text-center">{item.payment_intent}</div>
												<div className="tw-w-[10%] tw-text-center">{item.name}</div>
												<div className="tw-w-[20%] tw-text-center">{item.email}</div>
												<div className="tw-w-[15%] tw-text-center">{DateTimeUtil.showFullTimeFormat(item.created * 1000)}</div>
												<div className="tw-w-[10%] tw-flex tw-items-center tw-justify-center">{item.actionable && <SvgIcon size={18} color={ColorConstants.brand.primary} name="cancel-order-icon" onClick={() => this.handleCancelCharge(item)} />}</div>
											</div>
										);
									})}
								{fraudListHasMore && <div className="tw-w-full tw-h-[50px] tw-flex tw-items-center tw-justify-center">
									{fraudListLoading && <div className="tw-w-full tw-flex tw-justify-center tw-items-center" >
										<div class="lds-ripple lds-ripple-fraudlist !tw-w-[40px] !tw-h-[40px]">
											<div></div>
											<div></div>
										</div>
									</div>}
									{!fraudListLoading && <div className="tw-px-[20px] tw-py-[5px] tw-text-white tw-text-[12px] tw-rounded-[6px] tw-bg-brand-primary" onClick={this.handleLoadMoreFraudList}>Load More</div>}
								</div>}
							</div>
							
						</div>
					</div>
				)}
				{showDisputeModal && (
					<div className="tw-w-full tw-h-full tw-fixed tw-top-0 tw-left-0 tw-bg-[rgba(0,0,0,0.7)] tw-z-[1049] tw-flex tw-justify-center tw-items-center" onClick={this.toggleDisputeModal}>
						<div className="tw-w-[70%] tw-max-w-[1200px] tw-h-[40%] tw-max-h-[80%] tw-bg-white tw-rounded-[30px] tw-bg-white" onClick={(e) => e.stopPropagation()}>
							<div className="tw-flex tw-items-center tw-font-bold tw-text-[20px] tw-h-[70px] tw-border-b tw-border-[rgb(233, 233, 239)] tw-px-[20px]">Dispute order list</div>
							<div className="tw-w-full tw-h-[calc(100%-70px)] tw-overflow-y-auto tw-py-[20px] tw-px-[20px]">
								<div className="tw-flex tw-justify-between tw-items-center tw-text-center tw-font-bold tw-text-[12px] tw-mb-[10px] tw-h-[40px]  tw-rounded-[10px] tw-shadow-table tw-text-[16px] tw-font-bold">
									<div className="tw-w-[5%] tw-text-center">#</div>
									<div className="tw-w-[20%] tw-text-center">Charge ID</div>
									<div className="tw-w-[10%] tw-text-center">Amount</div>
									<div className="tw-w-[20%] tw-text-center">Customer</div>
									<div className="tw-w-[20%] tw-text-center">Customer Email</div>
									<div className="tw-w-[15%] tw-text-center">{I18N.getText("date")}</div>
									<div className="tw-w-[10%] tw-text-center">{I18N.getText("operation")}</div>
								</div>
								{currentDisputeList &&
									currentDisputeList.map((item, index) => {
										return (
											<div className="tw-w-full tw-text-[12px] tw-pb-[10px] tw-shadow-table tw-flex tw-items-center tw-justify-between tw-mt-[20px] tw-border-b tw-border-[rgb(233, 233, 239)]">
												<div className="tw-w-[5%] tw-text-center">{index + 1}</div>
												<div className="tw-w-[20%] tw-text-center">{item.charge}</div>
												<div className="tw-w-[10%] tw-text-center">{item.amount}</div>
												<div className="tw-w-[20%] tw-text-center">{item.evidence && item.evidence.customer_name}</div>
												<div className="tw-w-[20%] tw-text-center">{item.evidence && item.evidence.customer_email_address}</div>
												<div className="tw-w-[15%] tw-text-center">{DateTimeUtil.showFullTimeFormat(item.created * 1000)}</div>
												<div className="tw-w-[10%] tw-flex tw-items-center tw-justify-center">
													<SvgIcon size={18} color={ColorConstants.brand.primary} name="cancel-order-icon" onClick={() => this.handleCancelCharge(item)} />
												</div>
											</div>
										);
									})}
								{disputeListHasMore && <div className="tw-w-full tw-h-[50px] tw-flex tw-items-center tw-justify-center">
									{disputeListLoading && <div className="tw-w-full tw-flex tw-justify-center tw-items-center" >
										<div class="lds-ripple lds-ripple-fraudlist !tw-w-[40px] !tw-h-[40px]">
											<div></div>
											<div></div>
										</div>
									</div>}
									{!disputeListLoading && <div className="tw-px-[20px] tw-py-[5px] tw-text-white tw-text-[12px] tw-rounded-[6px] tw-bg-brand-primary" onClick={this.handleLoadMoreFraudList}>Load More</div>}
								</div>}
							</div>
						</div>
					</div>
				)}
				<div className="tw-w-full tw-mt-[30px]">
					<div className="tw-flex tw-justify-between tw-items-center tw-w-full tw-mt-[20px] hs-userlist-filter-container">
						<div className="tw-flex tw-flex tw-items-center">
							{ORDER_FILTER_TYPE.map((item) => {
								return (
									<div key={item.id} className={classNames("tw-h-[40px] tw-flex tw-justify-center tw-items-center tw-rounded-[10px] tw-text-body-text-1 tw-text-[14px] tw-font-bold tw-mr-[30px] tw-text-[#717579]", { "!tw-text-brand-primary-light": sdCardListParams.status === item.id })} onClick={() => this.handleSdCardFilterClick(item)}>
										{item.text}
									</div>
								);
							})}

							<div className="tw-flex tw-items-center tw-relative">
								<div className="animate__animated animate__tada animate__infinite tw-cursor-pointer tw-group" onClick={() => this.setTimerIsHovered()}>
									<ClockIcon color="#717579" size={36} />
									{stTimingData !== undefined && stTimingData !== null && <div className="tw-z-[100002] tw-absolute tw-top-[30px] tw-left-1/2 tw-translate-x-[-50%] tw-my-2 tw-px-2 tw-bg-gray-800 tw-text-white tw-text-sm tw-py-1 tw-px-2 tw-rounded tw-shadow-lg tw-hidden tw-transition-all tw-duration-300 group-hover:tw-inline-block tw-text-center">{stTimingData}</div>}
								</div>

								{isTimerHovered && <input type="text" placeholder="Enter text..." value={stTimingData} onChange={this.handleChangeSTTimingData} onBlur={this.handleStTimingBlur} className={`tw-w-[60px] tw-p-2 tw-rounded-lg tw-shadow-md tw-absolute tw-left-[40px] tw-opacity-0 tw-transform tw-translate-x-[-20px] tw-transition-all tw-duration-100 ${isTimerHovered ? "tw-opacity-100 tw-translate-x-0 animate__fadeInRight animate__animated" : ""}`} />}
							</div>
						</div>

						<div className="tw-flex tw-flex-end tw-items-center">
							{isAdmin && <Select className="tw-bg-white tw-min-w-[320px] tw-rounded-[30px] tw-px-[20px] tw-shadow-xl placeholder:tw-font-normal placeholder:tw-text-[12px] hs-role-selector-container" styles={{ dropdownIndicator: (base) => ({ ...base, color: ColorConstants.brand.primary }) }} value={selectedUser} placeholder={I18N.getText("st-group-placeholder")} onChange={this.handleSelectedUser} isClearable={true} name="stGroup" options={unpagedUserList} />}
							<div className="tw-relative tw-justify-between tw-items-center tw-ml-[30px]">
								<EDSInput className="tw-w-[450px] tw-py-[12px] tw-rounded-[30px] tw-px-[20px] tw-border-none tw-shadow-xl" value={sdCardListParams.keywords} type="text" placeholder={I18N.getText("keywords-placeholder")} onChange={this.handleCommentFilterChange} />
								<SearchIcon size={24} color={ColorConstants.black[0]} className="tw-absolute tw-right-[20px] tw-bottom-[12px] tw-w-[24px] tw-h-[24px]" onClick={this.searchNewStCardRecord} />
							</div>
							{isAdmin && (
								<div className="tw-flex tw-justify-end tw-items-center tw-ml-[30px]">
									<RDSButton className="tw-text-white" onClick={this.createNewSdCard}>
										Create
									</RDSButton>
								</div>
							)}
						</div>
					</div>

					{stCardList && stCardList.length > 0 && (
						<React.Fragment>
							<div className="tw-w-full tw-mt-[30px]">
								<div className="tw-w-full hs-users-table-container">
									<div className="tw-flex tw-justify-between tw-items-center tw-text-center tw-font-bold tw-text-[12px] tw-mb-[20px] tw-rounded-[30px] tw-bg-white tw-h-[80px] tw-shadow-xl">
										<div className="tw-w-[11%]">{I18N.getText("note")}</div>
										<div className="tw-w-[5%]">{I18N.getText("grouping")}</div>
										<div className="tw-w-[8%]">Country</div>
										<div className="tw-w-[3%]">{I18N.getText("max-order")}</div>
										<div className="tw-w-[8%]">{I18N.getText("total-money")}</div>
										<div className="tw-w-[8%]">{I18N.getText("order-count")}</div>
										<div className="tw-w-[10%]">{I18N.getText("status")}</div>
										<div className="tw-w-[8%]">{I18N.getText("date")}</div>
										<div className="tw-w-[8%]">{I18N.getText("max-polling")}</div>
										<div className="tw-w-[5%]">{I18N.getText("frequency")}</div>
										<div className="tw-w-[7%]">Category</div>
										<div className="tw-w-[8%]">{I18N.getText("description")}</div>
										<div className="tw-w-[10%]">{I18N.getText("operation")}</div>
									</div>
									<div className="tw-w-full tw-text-[12px]">
										{stCardList &&
											stCardList.map((record) => {
												return (
													<div className="tw-rounded-[30px] tw-h-[80px] tw-bg-white tw-overflow-hidden tw-flex tw-justify-between tw-items-center tw-text-center tw-mb-[20px] tw-shadow-xl hs-table-row">
														<div className="tw-w-[11%] tw-flex tw-justify-center tw-items-center">
															<span className="tw-text-[12px] tw-ml-[5px] hs-td-cell">{record.comment}</span>
														</div>
														<div className="tw-w-[5%] tw-flex tw-justify-center tw-items-center tw-font-bold hs-td-cell">{record.group_name}</div>
														<div className="tw-w-[8%] tw-flex tw-justify-center tw-items-center">
															<span className="tw-font-bold hs-td-cell tw-text-[14px]"><CountryFlag countryCode={record.country_code} size={32} /> </span>
														</div>
														<div className="tw-w-[3%] tw-flex tw-justify-center tw-items-center tw-font-bold hs-td-cell">{`${record.max_order}`}</div>
														<div className="tw-w-[8%] tw-flex tw-justify-center tw-items-center hs-td-cell">{`${record.money_sum}`}</div>
														<div className="tw-w-[8%] tw-flex tw-justify-center tw-items-center hs-td-cell">{`${record.order_count}`}</div>
														<div className="tw-w-[10%] tw-flex tw-justify-center tw-items-center">{this.renderSTCardStatus(record.status)}</div>
														<div className="tw-w-[8%] tw-flex tw-justify-center tw-items-center">
															<span className="tw-text-[12px] tw-ml-[5px] hs-td-cell">{record.createtime}</span>
														</div>
														<div className="tw-w-[8%] tw-flex tw-justify-center tw-items-center">
															<Switch color="primary" size="small" checked={parseInt(record.lunxun_max) > 1} onChange={(e) => this.handleMaxPollingChange(record, e)} />
														</div>
														<div className="tw-w-[5%] tw-flex tw-justify-center tw-items-center">
															<span className="tw-text-[12px] tw-ml-[5px] hs-td-cell">{I18N.getText(`${record.frequency}`)}</span>
														</div>
														<div className="tw-w-[7%] tw-flex tw-justify-center tw-items-center">
															<span className="tw-text-[12px] tw-ml-[5px] hs-td-cell">{record.fenlei}</span>
														</div>
														<div className="tw-w-[8%] tw-flex tw-justify-center tw-items-center">
															<span className="tw-text-[12px] tw-ml-[5px] hs-td-cell">{record.description}</span>
														</div>
														<div className="tw-w-[10%] tw-flex tw-justify-center tw-items-center">
															<SvgIcon onClick={() => this.handleViewDisputeList(record.id)} size={22} color={ColorConstants.brand.primary} name="dispute-icon" className="tw-mr-[6px]" />
															<SvgIcon onClick={() => this.handleViewFraudList(record.id)} size={20} color={ColorConstants.brand.primary} name="fraud-icon" className="tw-mr-[6px]" />
															{isAdmin && <SvgIcon onClick={() => this.handleEditSdCard(record.id)} size={20} color={ColorConstants.brand.primary} name="edit-icon" className="tw-mr-[6px]" />}
															{isAdmin && <SvgIcon onClick={() => this.handleDeleteSdCard(record.id)} size={20} color={ColorConstants.brand.primary} name="delete-icon" />}
														</div>
													</div>
												);
											})}
									</div>
								</div>
							</div>
							{stCardTotalNum > 0 && (
								<div className="tw-w-full tw-flex tw-justify-center tw-mt-[20px]">
									<Pagination total={stCardTotalNum} pageSize={sdCardListParams.per_page} currentPage={sdCardListParams.page} layout="sizes,prev,pager,next,jumper,->,total" pageSizes={[10, 20, 30, 50]} onCurrentChange={this.handlePageChange} onSizeChange={this.handlePageSizeChange} />
								</div>
							)}
						</React.Fragment>
					)}
					{stCardList && stCardList.length === 0 && (
						<div className="tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center">
							<SvgIcon size={200} name="non-record-icon" color={ColorConstants.brand.primary} />
							<div className="tw-mt-[20px] tw-text-[16px] tw-font-medium">{I18N.getText("no-records")}</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		stCardList: SdCardSelectors.selectSdCardList(state),
		stCardTotalNum: SdCardSelectors.selectStRecordTotalNum(state),
		currentUser: UserSelectors.selectCurrentUser(state),
		unpagedUserList: UserSelectors.selectUnpagedUserListForSelector(state),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		stCardActions: bindActionCreators(stCardActionCreator, dispatch),
		basicConfigActions: bindActionCreators(basicConfigActionCreator, dispatch),
		orderActions: bindActionCreators(orderActionCreator, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(STCardPage);
