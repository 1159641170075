import ColorConstants from '../constants/ColorConstants';
import DollarIcon from '../icons/DollarIcon';
import JPYIcon from '../icons/JPYIcon';
import EURIcon from '../icons/EURIcon';
import GBPIcon from '../icons/GBPIcon';
import I18N from '../i18n/index';


export const getCurrencySymbol = (currencyCode) => {
    const currency = parseInt(currencyCode)
    switch (currency) {
        case 0: {
            return '$';
        }
        case 1: {
            return '€';
        }
        case 2: {
            return '¥';
        }
        case 3: {
            return '￡';
        }
        case 4: {
            return 'ZAR';
        }
        case 5: {
            return 'CAD';
        }
        case 6: {
            return 'NZD';
        }
        case 7: {
            return 'AU';
        }
        case 8: {
            return '₩';
        }
        default: {
            return '$';
        }
    }
}

export const renderOrderStatus = (is_notify, status) => {
    if (is_notify) {
        return (<div className='tw-rounded-[6px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-py-[0px] tw-bg-status-notified-bg tw-flex tw-items-center tw-justify-center'>
            <span>{I18N.getText('notified')}</span>
        </div>)
    }
    switch (status) {
        case 1: {
            return (<div className='tw-rounded-[6px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-bg-status-succeed-bg tw-flex tw-items-center tw-justify-center'>
                <span className='tw-text-status-succeed-text'>{I18N.getText('succeed')}</span>
            </div>)
        }
        case 3: {
            return (<div className='tw-rounded-[6px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-bg-status-settle-accounts-bg tw-flex tw-items-center tw-justify-center'>
                <span>{I18N.getText('settle-accounts')}</span>
            </div>)
        }
        case 4: {
            return (<div className='tw-rounded-[6px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-bg-status-timeout-bg tw-flex tw-items-center tw-justify-center'>
                <span className='tw-text-status-timeout-text'>{I18N.getText('timeout')}</span>
            </div>)
        }
        case 0: {
            return (<div className='tw-rounded-[6px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-bg-status-unpaid-bg tw-flex tw-items-center tw-justify-center'>
                <span className='tw-text-status-timeout-text'>{I18N.getText('unpaid')}</span>
            </div>)
        }
        case 5: {
            return (<div className='tw-rounded-[6px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-bg-status-being-processed-bg tw-flex tw-items-center tw-justify-center'>
                <span>{I18N.getText('pending-review')}</span>
            </div>)
        }
        case 6: {
            return (<div className='tw-rounded-[6px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-bg-status-timeout-bg tw-flex tw-items-center tw-justify-center'>
                <span>{I18N.getText('failed')}</span>
            </div>)
        }
        case 7: {
            return (<div className='tw-rounded-[6px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-bg-status-unabled-pay-bg tw-flex tw-items-center tw-justify-center'>
                <span>{I18N.getText('unable-to-pay')}</span>
            </div>)
        }
        case 8: {
            return (<div className='tw-rounded-[6px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-bg-status-pending-withdrawal-bg tw-flex tw-items-center tw-justify-center'>
                <span className='tw-text-status-pending-withdrawal-text'>{I18N.getText('pending-withdrawal')}</span>
            </div>)
        }
        default: {
            return (<div className='tw-rounded-[6px] tw-px-[12px] tw-py-[4px] tw-text-[11px] tw-py-[2px] tw-bg-status-being-processed-bg tw-flex tw-items-center tw-justify-center'>
                <span>{I18N.getText('being-processed')}</span>
            </div>)
        }
    }
}


export const currencyToLocaleMap = {
    
    "EUR": "en-GB",
    "JPY": "ja-JP",
    // "CNY": "zh-CN",

    "GBP": "en-GB",
    "USD": "en-US",
    "ZAR": "en-ZA", //南非
    "CAD": "en-CA", //加拿大
    "NZD": "en-NZ", //新西兰
    "AUD": "en-AU", //澳元

    // "INR": "hi-IN",
    // "KRW": "ko-KR",
    // "BRL": "pt-BR",
    
};