import React, { Component } from "react";
import { connect } from "react-redux";

import { bindActionCreators } from "redux";
import { Pagination } from "element-react";

// --- Components --- //
import Loading from "../../components/common/LoadingIndicator";
import SvgIcon from "../../components/common/SvgIcon";
import EDSInput from "../../components/common/EDSInput";
import DeleteConfirmationModal from "../../components/common/dialog/DeleteConfirmationModal";

// --- Icons --- //
import SearchIcon from "../../icons/SearchIcon";

// --- Constants --- //
import ColorConstants from "../../constants/ColorConstants";
import DialogConstants from "../../constants/dialog/index";

// --- Actions --- //
import * as stGroupActionCreator from "../../actions/stGroupActionCreator";
import * as basicConfigActionCreator from "../../actions/basicConfigActionCreator";
import * as userActionCreator from "../../actions/userActionCreator";
import * as productCategoryActionCreator from "../../actions/productCategoryActionCreator";

// --- Selectors --- //
import * as ProductCategorySelectors from "../../selectors/ProductCategorySelectors";
import * as UserSelectors from "../../selectors/UserSelectors";

// --- Utils --- //
import * as DateTimeUtil from "../../utils/DateTimeUtil";

import I18N from "../../i18n";
import RDSButton from "../../components/common/RDSButton";
import PagePathConstants from "../../constants/router/PagePathConstants";
import LocalStorageConstants from "../../constants/LocalStorageConstants";
import LocalStorageUtil from "../../utils/LocalStorageUtil";

const CACHED_PARAM_KEY = LocalStorageConstants.PRODUCT_CATEGORY_PARAM;
class STGroupPage extends Component {
	constructor(props, context) {
		super(props, context);

		const cachedParam = LocalStorageUtil.loadCachedParam(CACHED_PARAM_KEY);
		this.state = {
			productCategoryParams: cachedParam
				? cachedParam
				: {
					page: 1,
					per_page: 20,
					fenlei: "",
				},
			currencyList: null,
			showLoading: false,
			showDeleteConfirmModal: false,
			deleteItemId: null,
		};

		this.handlePageChange = this.handlePageChange.bind(this);
		this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
		this.toggleLoading = this.toggleLoading.bind(this);
		this.handleEditProductCategory = this.handleEditProductCategory.bind(this);
		this.createNewProductCategory = this.createNewProductCategory.bind(this);
		this.getProductCategoryList = this.getProductCategoryList.bind(this);
		this.handleDeleteProductCategory = this.handleDeleteProductCategory.bind(this);
		this.toggleDeleteConfirmModal = this.toggleDeleteConfirmModal.bind(this);
		this.handleDeleteProductCategoryConfirmation = this.handleDeleteProductCategoryConfirmation.bind(this);
		this.handleProductNameFilterChange = this.handleProductNameFilterChange.bind(this);
		this.handleProductCategoryFilterClick = this.handleProductCategoryFilterClick.bind(this);
	}

	componentDidMount() {
		this.getProductCategoryList();
	}

	getProductCategoryList(params) {
		if (!params) {
			params = this.state.productCategoryParams;
		}
		const { productCategoryActions } = this.props;
		this.toggleLoading();
		return (
			productCategoryActions &&
			productCategoryActions.fetchProductCategoryList(params).then((data) => {
				this.toggleLoading();
				return;
			})
		);
	}

	handleEditProductCategory(id) {
		const { history } = this.props;
		history && history.push(PagePathConstants.PRODUCT_CATETORY_EDIT.replace(":id", id));
	}

	toggleLoading() {
		this.setState({ showLoading: !this.state.showLoading });
	}

	handlePageChange(page) {
		let { productCategoryParams } = this.state;
		productCategoryParams.page = page;
		this.getProductCategoryList(productCategoryParams).then(() => {
			LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, productCategoryParams);
			this.setState({ productCategoryParams });
		});
	}

	handlePageSizeChange(pageSize) {
		let { productCategoryParams } = this.state;
		productCategoryParams.per_page = pageSize;
		productCategoryParams.page = 1;
		this.getProductCategoryList(productCategoryParams).then(() => {
			LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, productCategoryParams);
			this.setState({ productCategoryParams });
		});
	}

	createNewProductCategory() {
		const { history } = this.props;
		history && history.push(PagePathConstants.PRODUCT_CATETORY_ADD);
	}

	handleDeleteProductCategory(id) {
		this.toggleDeleteConfirmModal();
		this.setState({ deleteItemId: id });
	}

	toggleDeleteConfirmModal() {
		this.setState({ showDeleteConfirmModal: !this.state.showDeleteConfirmModal });
	}

	handleDeleteProductCategoryConfirmation() {
		const { productCategoryActions } = this.props;
		const { deleteItemId } = this.state;

		productCategoryActions &&
			deleteItemId &&
			deleteItemId !== "" &&
			productCategoryActions
				.deleteProductCategory(this.state.deleteItemId)
				.then((data) => {
					this.toggleDeleteConfirmModal();
					this.getProductCategoryList();
					return;
				})
				.catch(this.toggleDeleteConfirmModal);
	}

	handleProductCategoryFilterClick() {
		let { productCategoryParams } = this.state;
		productCategoryParams.page = 1;
		this.setState({ productCategoryParams }, () => {
			LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, productCategoryParams);
		});
		this.getProductCategoryList(productCategoryParams);
	}

	handleProductNameFilterChange(e) {
		const categoryName = e && e.target.value;
		const { productCategoryParams } = this.state;
		productCategoryParams.fenlei = categoryName;
		this.setState({ productCategoryParams });
	}

	render() {
		const { productCategoryList, productCategoryTotalNum, unpagedUserList } = this.props;
		const { productCategoryParams, showLoading, showDeleteConfirmModal } = this.state;
		const total = Math.ceil(productCategoryTotalNum / productCategoryParams.per_page);
		return (
			<div className="tw-w-full tw-px-[50px]">
				{showLoading && <Loading />}
				<DeleteConfirmationModal isDeleteModalOpen={showDeleteConfirmModal} toggle={this.toggleDeleteConfirmModal} handleDeleteConfirm={this.handleDeleteProductCategoryConfirmation} sectionName={DialogConstants.PRODUCT_CATEGORY} />
				<div className="tw-w-full tw-mt-[30px]">
					<div className="tw-flex tw-justify-between tw-items-center tw-w-full tw-mt-[20px] hs-userlist-filter-container">
						<div className="tw-relative">
							<EDSInput className="tw-w-[450px] tw-py-[12px] tw-rounded-[30px] tw-px-[20px] tw-border-none tw-shadow-xl" value={productCategoryParams.fenlei} type="text" placeholder={I18N.getText("keywords-placeholder")} onChange={this.handleProductNameFilterChange} />
							<SearchIcon size={24} color={ColorConstants.black[0]} className="tw-absolute tw-right-[20px] tw-bottom-[12px] tw-w-[24px] tw-h-[24px]" onClick={this.handleProductCategoryFilterClick} />
						</div>
						<div className="tw-w-[300px] tw-flex tw-justify-end tw-items-center">
							{/* <RDSButton className="tw-text-white tw-mr-[30px]" onClick={this.handleProductCategoryFilterClick}>{I18N.getText('search')}</RDSButton> */}
							<RDSButton className="tw-text-white" onClick={this.createNewProductCategory}>
								Create
							</RDSButton>
						</div>
					</div>

					{productCategoryList && productCategoryList.length > 0 && (
						<React.Fragment>
							<div className="tw-w-full tw-mt-[30px]">
								<div className="tw-w-full hs-users-table-container">
									<div className="tw-flex tw-justify-between tw-items-center tw-text-center tw-font-bold tw-text-[14px] tw-mb-[20px] tw-rounded-[30px] tw-bg-white tw-h-[80px] tw-shadow-xl">
										<div className="tw-w-[40%]">Product Category</div>
										<div className="tw-w-[40%]">Date</div>
										<div className="tw-w-[20%]">Operation</div>
									</div>
									<div className="tw-w-full tw-text-[14px]">
										{productCategoryList &&
											productCategoryList.map((record) => {
												return (
													<div className="tw-rounded-[30px] tw-h-[80px] tw-bg-white tw-overflow-hidden tw-flex tw-justify-between tw-items-center tw-text-center tw-mb-[20px] tw-shadow-xl hs-table-row">
														<div className="tw-w-[40%] tw-flex tw-justify-center tw-items-center">
															<span className="tw-text-[14px] tw-ml-[5px] hs-td-cell">{record.fenlei}</span>
														</div>
														<div className="tw-w-[40%] tw-flex tw-justify-center tw-items-center">
															<span className="tw-text-[14px] tw-ml-[5px] hs-td-cell">{DateTimeUtil.showFullTimeFormat(record.updatetime * 1000)}</span>
														</div>
														<div className="tw-w-[20%] tw-flex tw-justify-center tw-items-center">
															<SvgIcon onClick={() => this.handleEditProductCategory(record.id)} size={20} color={ColorConstants.brand.primary} name="edit-icon" />
															<SvgIcon onClick={() => this.handleDeleteProductCategory(record.id)} size={20} color={ColorConstants.brand.primary} name="delete-icon" />
														</div>
													</div>
												);
											})}
									</div>
								</div>
							</div>
							<div className="tw-w-full tw-flex tw-justify-center">
								<Pagination total={total} pageSize={productCategoryParams.per_page} currentPage={productCategoryParams.page} layout="sizes,prev,pager,next,jumper,->,total" pageSizes={[10, 20, 30, 50]} onCurrentChange={this.handlePageChange} onSizeChange={this.handlePageSizeChange} />
							</div>
						</React.Fragment>
					)}
					{productCategoryList && productCategoryList.length === 0 && (
						<div className="tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center">
							<SvgIcon size={200} name="non-record-icon" color={ColorConstants.brand.primary} />
							<div className="tw-mt-[20px] tw-text-[16px] tw-font-medium">{I18N.getText("no-records")}</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		unpagedUserList: UserSelectors.selectUnpagedUserListForSelector(state),
		productCategoryList: ProductCategorySelectors.selectProductCategoryList(state),
		productCategoryTotalNum: ProductCategorySelectors.selectProductCategoryRecordTotalNum(state),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		productCategoryActions: bindActionCreators(stGroupActionCreator, dispatch),
		productCategoryActions: bindActionCreators(productCategoryActionCreator, dispatch),
		userActions: bindActionCreators(userActionCreator, dispatch),
		basicConfigActions: bindActionCreators(basicConfigActionCreator, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(STGroupPage);
