import ActionConstants from '../constants/actions/ActionConstants';
import * as ProductCategoryAPI from '../apis/ProductCategoryAPI';
import { showErrorGlobalAlert, showSuccessGlobalAlert } from './globalAlertActionCreator';

export const updateProductCategoryListAction = (categoryList, total) => ({
    type: ActionConstants.UPDATE_PRODUCT_CATEGORY_LIST,
    payload: { data: categoryList, total: total },
});

export const updateUnpagedProductCategoryListAction = (categoryList, total) => ({
    type: ActionConstants.UPDATE_PRODUCT_CATEGORY_LIST,
    payload: { data: categoryList, total: total },
});

export const fetchProductCategoryList = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            ProductCategoryAPI.fetchCategoryList(params)
                .then((res) => {
                    if (res.status === 200) {
                        const categoryList = res.data.data.list;
                        const total = res.data.data.total;
                        dispatch(updateProductCategoryListAction(categoryList, total));
                        return resolve('success');
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}

export const fetchUnpagedProductCategoryList = () => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            return ProductCategoryAPI.fetchUnpagedProductCategoryList()
                .then((res) => {
                    if (res.status === 200) {
                        const categoryList = res.data.data.list;
                        const total = res.data.data.total;
                        dispatch(updateProductCategoryListAction(categoryList, total));
                        return resolve('success');
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}

export const fetchCategoryDetailById = (id) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            ProductCategoryAPI.fetchProductCategoryDetail(id)
                .then((res) => {  
                    if (res.status === 200) {
                        return resolve(res.data.data);
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}

export const editProductCategoryInfoById = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            ProductCategoryAPI.editProductCategoryById(params)
                .then((res) => {
                    if (res.status === 200) {
                        dispatch(showSuccessGlobalAlert('edit-product-category-succeed'));
                        return resolve('success');
                    }
                })
                .catch((err) => {
                    dispatch(showErrorGlobalAlert('edit-product-category-failed'));
                    return reject(err)
                });
        });
    };
}

export const createNewProductCategory = (params) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            ProductCategoryAPI.createNewProductCategory(params)
                .then((res) => {
                    if (res.status === 200) {
                        dispatch(showSuccessGlobalAlert('create-product-info-succeed'));
                        return resolve('success');
                    }
                })
                .catch((err) => {
                    dispatch(showErrorGlobalAlert('create-product-info-failed'));
                    return reject(err);
                });
        });
    };
}

export const deleteProductCategory = (id) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            ProductCategoryAPI.deleteProductCategory(id)
                .then((res) => {
                    if (res.status === 200) {
                        dispatch(showSuccessGlobalAlert('delete-info-succeed'));
                        return resolve('success');
                    }
                })
                .catch((err) => {
                    dispatch(showErrorGlobalAlert('delete-info-failed'));
                    return reject(err);
                });
        });
    };
}