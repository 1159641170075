import { createSelector } from 'reselect';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const selectProductCategoryReducer = state => state.get(ReducerNameConstants.PRODUCT_CATEGORY);

export const selectProductCategoryList = createSelector(
  [selectProductCategoryReducer],
  (productCategoryReducer) => {
    return productCategoryReducer && productCategoryReducer.get(StoreStateConstants.PRODUCT_CATEGORY_LIST);
  },
);

export const selectProductCategoryRecordTotalNum = createSelector(
  [selectProductCategoryReducer],
  (productCategoryReducer) => {
    return productCategoryReducer && productCategoryReducer.get(StoreStateConstants.PRODUCT_CATEGORY_TOTAL_COUNT);
  },
);


export const selectUnpagedProductCategoryList = createSelector(
  [selectProductCategoryReducer],
  (productCategoryReducer) => {
    const categoryList = productCategoryReducer && productCategoryReducer.get(StoreStateConstants.PRODUCT_CATEGORY_LIST);
    return categoryList && categoryList.map(item => {
      return {
        value: item.id,
        label: item.fenlei
      }
    })
  },
);