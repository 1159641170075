import React, { Component } from "react";
import { connect } from "react-redux";
import Select from 'react-select';
import { bindActionCreators } from "redux";
import { Pagination } from "element-react";

// --- Components --- //
import Loading from "../../components/common/LoadingIndicator";
import SvgIcon from "../../components/common/SvgIcon";
import CountryFlag from "../../components/common/CountryFlag/index";

// --- Constants --- //
import ColorConstants from "../../constants/ColorConstants";
import DialogConstants from "../../constants/dialog/index";

// --- Actions --- //
import * as stCardActionCreator from "../../actions/stCardActionCreator";
import * as basicConfigActionCreator from "../../actions/basicConfigActionCreator";
import * as orderActionCreator from "../../actions/orderActionCreator";
import * as productActionCreator from "../../actions/productActionCreator";
import * as productCategoryActionCreator from "../../actions/productCategoryActionCreator";
import * as countryActionCreator from "../../actions/countryActionCreator";

// --- Selectors --- //
import * as UserSelectors from "../../selectors/UserSelectors";
import * as ProductSelectors from "../../selectors/ProductSelectors";
import * as AccountCountrySelectors from "../../selectors/AccountCountrySelectors";
import * as ProductCategorySelectors from "../../selectors/ProductCategorySelectors";

import I18N from "../../i18n";
import RDSButton from "../../components/common/RDSButton";
import PagePathConstants from "../../constants/router/PagePathConstants";
import DeleteConfirmationModal from "../../components/common/dialog/DeleteConfirmationModal";
import LocalStorageConstants from "../../constants/LocalStorageConstants";
import LocalStorageUtil from "../../utils/LocalStorageUtil";
import * as DateTimeUtil from "../../utils/DateTimeUtil";
import UserHelper from "../../helpers/UserHelper";

const CACHED_PARAM_KEY = LocalStorageConstants.ACCOUNT_COUNTRY_PARAM;
class AccountCountryPage extends Component {
	constructor(props, context) {
		super(props, context);
		const cachedParam = LocalStorageUtil.loadCachedParam(CACHED_PARAM_KEY);
		this.state = {
			countryListParams: cachedParam ?
			cachedParam : {
				page: 1,
				per_page: 20,
			},
			selectedCategory: null,
			showLoading: false,
			showDeleteConfirmModal: false,
			deleteItemId: "",
			cancelItem: "",
		};

		this.getCountryList = this.getCountryList.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.toggleLoading = this.toggleLoading.bind(this);
		this.handleEditCountry = this.handleEditCountry.bind(this);
		this.handleDeleteProduct = this.handleDeleteProduct.bind(this);
		this.createNewProduct = this.createNewProduct.bind(this);
		this.toggleDeleteConfirmModal = this.toggleDeleteConfirmModal.bind(this);
		this.handleDeleteCountryConfirmation = this.handleDeleteCountryConfirmation.bind(this);
		this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
	}

	componentDidMount() {
		this.getCountryList();
	}

	toggleDeleteConfirmModal() {
		this.setState({ showDeleteConfirmModal: !this.state.showDeleteConfirmModal });
	}

	handleEditCountry(id) {
		const { history } = this.props;
		history && history.push(PagePathConstants.ACCOUNT_COUNTRY_EDIT.replace(":id", id));
	}

	handleDeleteProduct(id) {
		this.toggleDeleteConfirmModal();
		this.setState({ deleteItemId: id });
	}

	handleDeleteCountryConfirmation() {
		const { countryActions } = this.props;
		const { deleteItemId } = this.state;

		countryActions &&
			deleteItemId &&
			deleteItemId !== "" &&
			countryActions
				.deleteAccountCountry(this.state.deleteItemId)
				.then((data) => {
					this.toggleDeleteConfirmModal();
					this.getCountryList();
					return;
				})
				.catch(this.toggleDeleteConfirmModal);
	}

	toggleLoading() {
		this.setState({ showLoading: !this.state.showLoading });
	}

	getCountryList(params) {
		if (!params) {
			params = this.state.countryListParams;
		}
		const { countryActions } = this.props;
		this.toggleLoading();
		return (
			countryActions &&
			countryActions
				.fetchCountryList(params)
				.then((data) => {
					this.toggleLoading();
					return;
				})
				.catch(this.toggleLoading)
		);
	}

	handlePageChange(page) {
		let { countryListParams } = this.state;
		countryListParams.page = page;
		this.getCountryList(countryListParams).then(() => {
			LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, countryListParams);
			this.setState({ countryListParams });
		});
	}

	createNewProduct() {
		const { history } = this.props;
		history && history.push(PagePathConstants.ACCOUNT_COUNTRY_ADD);
	}

	handlePageSizeChange(pageSize) {
		let { countryListParams } = this.state;
		countryListParams.per_page = pageSize;
		countryListParams.page = 1;
		this.getCountryList(countryListParams).then(() => {
			LocalStorageUtil.saveCacheQueryParam(CACHED_PARAM_KEY, countryListParams);
			this.setState({ countryListParams });
		});
	}

	render() {
		const { countryList, countryTotalNum, currentUser, unpagedCategoryList} = this.props;
		const { countryListParams, showLoading, showDeleteConfirmModal, selectedCategory } = this.state;
		const total = Math.ceil(countryTotalNum / countryListParams.per_page);
		const isAdmin = UserHelper.isAdminUser(currentUser);

		return (
			<div className="tw-w-full tw-px-[20px]">
				{showLoading && <Loading />}
				<DeleteConfirmationModal isDeleteModalOpen={showDeleteConfirmModal} toggle={this.toggleDeleteConfirmModal} handleDeleteConfirm={this.handleDeleteCountryConfirmation} sectionName={DialogConstants.COUNTRY} />
				<div className="tw-w-full tw-mt-[30px]">
					<div className="tw-w-full tw-flex tw-justify-end tw-items-center tw-w-full tw-mt-[20px] hs-userlist-filter-container">
						
						<div className="tw-ml-[30px]">
							{isAdmin && (
								<div className="tw-flex tw-justify-end tw-items-center tw-ml-[30px]">
									<RDSButton className="tw-text-white" onClick={this.createNewProduct}>
										Create
									</RDSButton>
								</div>
							)}
						</div>
					</div>

					{countryList && countryList.length > 0 && (
						<React.Fragment>
							<div className="tw-w-full tw-mt-[30px]">
								<div className="tw-w-full hs-users-table-container tw-bg-white tw-rounded-[20px] tw-overflow-hidden">
									<div className="tw-flex tw-justify-between tw-items-center tw-text-center tw-font-bold tw-text-[14px] tw-mb-[20px] tw-rounded-[30px] tw-bg-white tw-h-[60px] ">
										<div className="tw-w-[20%]">Country Flag</div>
										<div className="tw-w-[20%]">Account Country</div>
										<div className="tw-w-[20%]">Account Reset Time</div>
										<div className="tw-w-[20%]">Date</div>
										<div className="tw-w-[20%]">Operation</div>
									</div>
									<div className="tw-w-full tw-text-[12px]">
										{countryList &&
											countryList.map((record) => {
												return (
													<div className="tw-h-[50px] tw-bg-white tw-overflow-hidden tw-flex tw-justify-between tw-items-center tw-text-center tw-mb-[10px]  hs-table-row">
														<div className="tw-w-[20%] tw-flex tw-justify-center tw-items-center"><CountryFlag countryCode={record.country_code} size={32} /></div>
														<div className="tw-w-[20%] tw-flex tw-justify-center tw-items-center tw-font-bold hs-td-cell">{record.country_code}</div>
														<div className="tw-w-[20%] tw-flex tw-justify-center tw-items-center hs-td-cell">{record.time_country}</div>
														<div className="tw-w-[20%] tw-flex tw-justify-center tw-items-center hs-td-cell">{DateTimeUtil.showFullTimeFormat(record.updatetime * 1000)}</div>
														<div className="tw-w-[20%] tw-flex tw-justify-center tw-items-center">
															{isAdmin && <SvgIcon onClick={() => this.handleEditCountry(record.id)} size={20} color={ColorConstants.brand.primary} name="edit-icon" className="tw-mr-[6px]" />}
															{isAdmin && <SvgIcon onClick={() => this.handleDeleteProduct(record.id)} size={20} color={ColorConstants.brand.primary} name="delete-icon" />}
														</div>
													</div>
												);
											})}
									</div>
								</div>
							</div>
							{countryTotalNum > 0 && (
								<div className="tw-w-full tw-flex tw-justify-center tw-mt-[20px]">
									<Pagination total={countryTotalNum} pageSize={countryListParams.per_page} currentPage={countryListParams.page} layout="sizes,prev,pager,next,jumper,->,total" pageSizes={[10, 20, 30, 50]} onCurrentChange={this.handlePageChange} onSizeChange={this.handlePageSizeChange} />
								</div>
							)}
						</React.Fragment>
					)}
					{countryList && countryList.length === 0 && (
						<div className="tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center">
							<SvgIcon size={200} name="non-record-icon" color={ColorConstants.brand.primary} />
							<div className="tw-mt-[20px] tw-text-[16px] tw-font-medium">{I18N.getText("no-records")}</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		currentUser: UserSelectors.selectCurrentUser(state),
		countryList: AccountCountrySelectors.selectAccountCountryList(state),
		countryTotalNum: AccountCountrySelectors.selectAccountCountryRecordTotalNum(state),
		unpagedCategoryList: ProductCategorySelectors.selectUnpagedProductCategoryList(state),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		countryActions: bindActionCreators(stCardActionCreator, dispatch),
		basicConfigActions: bindActionCreators(basicConfigActionCreator, dispatch),
		orderActions: bindActionCreators(orderActionCreator, dispatch),
		countryActions: bindActionCreators(countryActionCreator, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountCountryPage);
