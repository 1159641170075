import ApiEndpointConstants from '../constants/api/ApiEndpointConstants';
import api from './API';

export const fetchProductCategoryDetail = (id) => {
    return api.post(
        `${ApiEndpointConstants.PRODUCT_CATEGORY_DETAIL}`, {id});
}

export const editProductCategoryById = (params) => {
    return api.post(
        `${ApiEndpointConstants.PRODUCT_CATEGORY_EDIT}`, params,
        );
}

export const createNewProductCategory = (params) => {
    return api.post(
        `${ApiEndpointConstants.PRODUCT_EDIT}`, params, 
        );
}

export const fetchCategoryList = (params) => {
    return api.post(
        `${ApiEndpointConstants.PRODUCT_CATEGORY_LIST}`,
        params,
    );
}

export const fetchUnpagedProductCategoryList = (params) => {
    return api.post(
        `${ApiEndpointConstants.UNPAGED_PRODUCT_CATEGORY_LIST}`,
    );
}


export const deleteProductCategory = (id) => {
    return api.post(
        `${ApiEndpointConstants.PRODUCT_CATEGORY_DELETE}`,
        { id },
    );
}
