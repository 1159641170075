import ApiEndpointConstants from '../constants/api/ApiEndpointConstants';
import api from './API';

export const getSTCardInfoById = (id) => {
    return api.get(
        `${ApiEndpointConstants.EDIT_ST_CARD}?id=${id}`);
}

export const editSTCardInfoById = (params) => {
    return api.post(
        `${ApiEndpointConstants.EDIT_ST_CARD}`, {stripe: params}, {
            headers:{
                'Content-Type': 'multipart/form-data'
              }
        });
}

export const deleteSTCardInfoById = (id) => {
    return api.post(
        `${ApiEndpointConstants.DELETE_ST_CARD}`, {id: id});
}


// export const createSTCard = (params) => {
//     return api.post(
//         `${ApiEndpointConstants.EDIT_ST_CARD}`, params, {
//             headers:{
//                 'Content-Type': 'multipart/form-data'
//               }
//         });
// }

export const fetchSTCardList = (page, per_page, status, keywords, user_id) => {
    return api.post(
        `${ApiEndpointConstants.ST_CARD}`,
        { page, per_page, status, keywords, user_id },
    );
}

export const fetchSTFraudListById = (params) => {
    return api.post(
        `${ApiEndpointConstants.ST_FRAUD_LIST}`,
        params,
    );
}


export const fetchSTDisputeListById = (params) => {
    return api.post(
        `${ApiEndpointConstants.ST_DISPUTE_LIST}`,
        params,
    );
}


export const setSTAccountTimingData = (data) => {
    return api.post(
        `${ApiEndpointConstants.ST_ACCOUNT_TIMING}`,
        { data },
    );
}