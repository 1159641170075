const Endpoint = {
    LOGIN: '/user/login',
    LOGOUT: '/user/logout',
    TELEGRAM_CODE: '/User/telms',
    
    ORDER_GRAPH: '/home/graphOrderList',
    WITHDRAW_GRAPH: '/home/graphWithdrawList',
    MONEY: '/money/moneyLogList',
    ORDER: '/order/getOdersList',
    ORDER_DETAIL: '/Orderdetail/getOrder',
    ORDER_STATISTICS: '/order/orderData',
    ORDER_PAYMENT_STATUS: '/Order/Updateorder',
    ORDER_CANCEL_SUBSCRIPTION: '/Dingyue/quxiaodingyue',
    ORDER_CANCEL_CHARGE: '/Dingyue/quxiaozhifu',
    ORDER_INVOICES: '/Dingyue/getInvoices',
    ORDER_RISK_DETAIL: '/Dingyue/getRisklevel',
    ORDER_REFUND_ALL: '/Dingyue/piliangtuikuan',
    NOTIFY: '/order/notify',
    TELEGRAM_NOTIFY: '/Message/sendAMessage',

    WITHDRAW_LIST: '/money/getWithdrawList',
    WITHDRAW: '/money/addWithdraw',
    WITHDRAW_ACTION: '/money/actionWithdraw',
    ME: '/user/getUserinfo',

    USER: '/user/getUsers',
    UNPAGED_USER: '/user/getUserslist',
    EDIT_USER: '/user/editUser',

    ST_FRAUD_LIST: '/Dingyue/getEarlyFraudWarnings',
    ST_DISPUTE_LIST: '/Dingyue/getDisputelist',
    ST_CARD: '/foreigner/getStripeList',
    EDIT_ST_CARD: '/foreigner/editStripe',
    DELETE_ST_CARD: '/foreigner/delStripe',

    BLACKLIST: '/Heimingdan/getHeimingdanlist',
    EDIT_BLACKLIST: '/Heimingdan/setHeimingdan',
    BLACKLIST_DETAIL: '/Heimingdan/getHeimingdan',
    DELETE_BLACKLIST: '/Heimingdan/delHeimingdan',

    ST_GROUP: '/foreigner/getStripegroupList',
    EDIT_ST_GROUP: '/foreigner/editStripegroup',

    IBAN_CARD: '/foreigner/getCardList',
    EDIT_IBAN_CARD: '/foreigner/editCard',

    ACH_CARD: '/Accounttable/getAccounttableList',
    EDIT_ACH_CARD: '/Accounttable/setAccounttable',
    ACH_CARD_DETAIL: '/Accounttable/getAccounttable',
    DELETE_ACH_CARD: '/Accounttable/delAccounttable',

    COIN: '/Accounttable/getAccounttableList',
    EDIT_COIN: '/Accounttable/setAccounttable',
    COIN_DETAIL: '/Accounttable/getAccounttable',
    DELETE_COIN: '/Accounttable/delAccounttable',

    UPLOAD: '/common/upload',

    CURRENCY_LIST: '/order/getBiZhong',

    ST_ACCOUNT_TIMING: '/foreigner/setTiming',

    PRODUCT_CATEGORY_EDIT: '/chanpin/setFenlei',
    PRODUCT_CATEGORY_LIST: '/chanpin/getZhandianfenleilist',
    UNPAGED_PRODUCT_CATEGORY_LIST: '/chanpin/getFenleilist',
    PRODUCT_CATEGORY_DETAIL: '/chanpin/getFenlei',
    PRODUCT_CATEGORY_DELETE: '/chanpin/delFenlei',

    PRODUCT_EDIT: '/chanpin/setChanpin',
    PRODUCT_LIST: '/chanpin/getChanpinlist',
    PRODUCT_DETAIL: '/chanpin/getChanpin',
    PRODUCT_DELETE: '/chanpin/delChanpin',

    ACCOUNT_COUNTRY_EDIT: '/Zhuandian/setZhuandian',
    ACCOUNT_COUNTRY: '/Zhuandian/getZhuandianlist',
    ACCOUNT_COUNTRY_DETAIL: '/Zhuandian/getZhuandian',
    ACCOUNT_COUNTRY_DELETE: '/Zhuandian/delZhuandian',
    UNPAGED_ACCOUNT_COUNTRY: '/Zhuandian/getZhuandianlistT',
}
export default Endpoint;

export const BASE_URL = window.location.protocol + "//" + window.location.host;