import ApiEndpointConstants from '../constants/api/ApiEndpointConstants';
import api from './API';

export const fetchAccountCountryDetail = (id) => {
    return api.post(
        `${ApiEndpointConstants.ACCOUNT_COUNTRY_DETAIL}`, {id});
}

export const createOrEditAccountCountry = (params) => {
    return api.post(
        `${ApiEndpointConstants.ACCOUNT_COUNTRY_EDIT}`, params, 
        );
}

export const fetchAccountCountryList = (params) => {
    return api.post(
        `${ApiEndpointConstants.ACCOUNT_COUNTRY}`,
        params,
    );
}

export const fetchUnpagedAccountCountryList = () => {
    return api.post(
        `${ApiEndpointConstants.UNPAGED_ACCOUNT_COUNTRY}`,
    );
}

export const deleteAccountCountry = (id) => {
    return api.post(
        `${ApiEndpointConstants.ACCOUNT_COUNTRY_DELETE}`,
        { id },
    );
}
