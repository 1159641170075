import ApiEndpointConstants from '../constants/api/ApiEndpointConstants';
import api from './API';

export const fetchProductDetail = (id) => {
    return api.post(
        `${ApiEndpointConstants.PRODUCT_DETAIL}`, {id});
}

export const editProductById = (params) => {
    return api.post(
        `${ApiEndpointConstants.PRODUCT_EDIT}`, params,
        );
}

export const createOrEditProduct = (params) => {
    return api.post(
        `${ApiEndpointConstants.PRODUCT_EDIT}`, params, 
        );
}

export const fetchProductList = (params) => {
    return api.post(
        `${ApiEndpointConstants.PRODUCT_LIST}`,
        params,
    );
}

export const deleteProduct = (id) => {
    return api.post(
        `${ApiEndpointConstants.PRODUCT_DELETE}`,
        { id },
    );
}
