import { createSelector } from 'reselect';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const selectProductReducer = state => state.get(ReducerNameConstants.PRODUCT);

export const selectProductList = createSelector(
  [selectProductReducer],
  (productReducer) => {
    return productReducer && productReducer.get(StoreStateConstants.PRODUCT_LIST);
  },
);

export const selectProductRecordTotalNum = createSelector(
  [selectProductReducer],
  (productReducer) => {
    return productReducer && productReducer.get(StoreStateConstants.PRODUCT_TOTAL_COUNT);
  },
);
