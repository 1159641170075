import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';

// --- Components --- //
import RDSButton from '../../components/common/RDSButton';
import SvgIcon from '../../components/common/SvgIcon';
import EDSInput from '../../components/common/EDSInput';
import Loading from '../../components/common/LoadingIndicator';

// --- Utils --- //
import * as FormValidationsUtil from '../../utils/FormValidationsUtil';

// --- Constants --- //
import PagePathConstants from '../../constants/router/PagePathConstants';
import ColorConstants from '../../constants/ColorConstants';

// --- Actions --- //
import * as stGroupActionCreator from '../../actions/stGroupActionCreator';
import * as basicConfigActionCreator from '../../actions/basicConfigActionCreator';
import * as userActionCreator from '../../actions/userActionCreator';
import * as productCategoryActionCreator from '../../actions/productCategoryActionCreator';
import * as productActionCreator from '../../actions/productActionCreator';

import * as UserSelectors from '../../selectors/UserSelectors';
import * as ProductCategorySelectors from '../../selectors/ProductCategorySelectors';

import I18N from '../../i18n';
import classNames from 'classnames';


class UserEditPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            productName: '',
            chineseProductName: '',
            selectedCategory: null,

            showError: false,
            showLoading: false,
            errMsg: '',
            isEditing: false,
        };

        this.fetchCategoryList = this.fetchCategoryList.bind(this);
        this.handleProductNameChange = this.handleProductNameChange.bind(this);
        this.handleChineseProductNameChange = this.handleChineseProductNameChange.bind(this);
        this.handleSelectCategory = this.handleSelectCategory.bind(this);
        this.toggleError = this.toggleError.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.toggleEditing = this.toggleEditing.bind(this);
        this.handleBackPage = this.handleBackPage.bind(this);
        this.editProduct = this.editProduct.bind(this);
        
    }

    componentDidMount() {
        this.fetchCategoryList();
    }

    fetchCategoryList() {
        const { productCategoryActions } = this.props;
        this.toggleLoading()
        productCategoryActions && productCategoryActions.fetchUnpagedProductCategoryList()
            .then((data) => {
                
                this.getProductDetailById();
            })
            .catch(() => {
                this.toggleLoading();
            });
    }

    getProductDetailById() {
        const { location, productActions, unpagedCategoryList } = this.props;
        const productId = location.pathname.split(`${PagePathConstants.PRODUCT}/`)[1];
        if (productId && productId !== 'add') {
            productActions.fetchProductInfoById(productId)
                .then(res => {
                    const productInfo = res;
                    let selectedCategory = unpagedCategoryList.filter(item => item.value == productInfo.zhandianfenleiid)[0];

                    this.setState({
                        productName: productInfo.product_name || this.state.productName,
                        chineseProductName: productInfo.zhongwen || this.state.chineseProductName,
                        selectedCategory: selectedCategory || this.state.selectedCategory,
                    });
                    this.toggleLoading();
                })
                .catch(() => {
                    this.toggleLoading();
                })
        } else {
            this.toggleLoading();
        }
    }

    toggleEditing() {
        this.setState({ isEditing: !this.state.isEditing });
    }

    toggleError() {
        this.setState({ showError: !this.state.showError });
    }

    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    handleProductNameChange(e) {
        const productName = e && e.target.value;
        this.setState({ productName, showError: false });
    }

    handleChineseProductNameChange(e) {
        const chineseProductName = e && e.target.value;
        this.setState({ chineseProductName, showError: false });
    }

    handleSelectCategory(selectedCategory) {
        this.setState({selectedCategory});
    }

    editProduct() {
        const { productActions, history, location } = this.props;
        const productId = location.pathname.split(`${PagePathConstants.PRODUCT}/`)[1];
        let { productName, chineseProductName, selectedCategory } = this.state;
       
        const params = {
            product_name: productName,
            zhongwen: chineseProductName,
            zhandianfenleiid: selectedCategory ? selectedCategory.value : null,
        }
        if (productId && productId !== 'add') {
            params['id'] = productId;
        }
        this.toggleEditing();
        productActions && productActions.createOrEditProduct(params)
            .then(() => {
                history && history.push(PagePathConstants.PRODUCT);
            })
            .catch(err => {
                this.toggleEditing();
            });
    }

    handleBackPage() {
        const { history } = this.props;
        history && history.goBack();
    }

    render() {
        const { isEditing, showLoading, productName, chineseProductName, showError, errMsg, errFiled, selectedCategory } = this.state;
        const { location, unpagedUserList, unpagedCategoryList } = this.props;
        return <div className='tw-w-full tw-px-[50px] tw-py-[50px]'>
            {showLoading && <Loading />}
            <div className='tw-rounded-[30px] tw-bg-white'>
                <div className='tw-flex tw-items-center  tw-px-[30px] tw-py-[24px] tw-border-b' onClick={this.handleBackPage}>
                    <SvgIcon name="rds-back-icon" color={ColorConstants.black[0]} size={24} />
                    <span className='tw-ml-[10px] tw-text-[18px] tw-font-bold' >{I18N.getText('back')}</span>
                </div>
                <div className='tw-text-[14px] tw-px-[30px] tw-py-[30px]'>
                    <div className='tw-flex tw-items-center'>
                        <div className='tw-w-[20%] tw-font-bold'>Product name</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`, { 'tw-border-light-red': (showError && errFiled === 'comment') })}
                            placeholder={I18N.getText('group-name-tip')}
                            value={productName || ""}
                            type="text"
                            onChange={this.handleProductNameChange}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold'>Chinese product name</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`, { 'tw-border-light-red': (showError && errFiled === 'apiKey') })}
                            placeholder={I18N.getText('group-desc-tip')}
                            value={chineseProductName || ""}
                            type="textarea"
                            onChange={this.handleChineseProductNameChange}
                        />
                    </div>
                    <div className='tw-flex tw-items-center tw-mt-[30px]'>
                        <div className='tw-w-[20%] tw-font-bold'>Product Category</div>
                        <Select
                            className="tw-w-full tw-rounded-[30px]  tw-px-[20px] tw-border tw-shadow-xl tw-border-border-gray placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px] hs-role-selector-container"
                            styles={{ dropdownIndicator: base => ({ ...base, color: ColorConstants.brand.primary }) }}
                            value={selectedCategory}
                            placeholder={I18N.getText('currency-tip')}
                            onChange={this.handleSelectCategory}
                            isClearable={true}
                            name="category"
                            options={unpagedCategoryList}
                        />
                    </div>
                    
                    
                    {showError && <div className='tw-mt-[20px] tw-text-[14px] tw-text-error-text'>{errMsg}</div>}
                    <div className='tw-flex tw-justify-center tw-items-center tw-mt-[30px]'>
                        <RDSButton
                            loading={isEditing}
                            disabled={isEditing || productName.trim() === '' || chineseProductName.trim() === '' || !selectedCategory || showError}
                            onClick={this.editProduct}
                            className="tw-text-white" >{I18N.getText('edit')}</RDSButton>
                    </div>
                </div>
            </div>
        </div>;
    }
}

function mapStateToProps(state) {
    return {
        unpagedUserList: UserSelectors.selectUnpagedUserListForSelector(state),
        unpagedCategoryList: ProductCategorySelectors.selectUnpagedProductCategoryList(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        productActions: bindActionCreators(stGroupActionCreator, dispatch),
        userActions: bindActionCreators(userActionCreator, dispatch),
        basicConfigActions: bindActionCreators(basicConfigActionCreator, dispatch),
        productCategoryActions: bindActionCreators(productCategoryActionCreator, dispatch),
        productActions: bindActionCreators(productActionCreator, dispatch),
        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEditPage);