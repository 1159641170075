import { Map, List } from 'immutable';
import StoreStateConstants, { PAYMENT_TYPE_CONSTANTS } from '../constants/store/StoreStateConstants';
import ActionConstants from '../constants/actions/ActionConstants';
import {LOGIN_CONSTANTS} from '../constants/store/StoreStateConstants';
import cookie from "react-cookies";
import LocalStorageUtil from '../utils/LocalStorageUtil';
// import api from '../apis/API';
import { setAPIAuthorizationToken, unsetAPIAuthorizationToken } from '../apis/API';


const localAuth = cookie.load(StoreStateConstants.AUTHENTICATION, {path: '/'});
console.log(localAuth)
// if (localAuth) {
//     setAPIAuthorizationToken(localAuth);
// }

const localCurrentUser = LocalStorageUtil.getItem(StoreStateConstants.CURRENT_USER);
const initialState = Map({
    [StoreStateConstants.LOGIN_STATUS]: localCurrentUser ? LOGIN_CONSTANTS.LOGGED_IN: LOGIN_CONSTANTS.LOGGED_OUT,
    [StoreStateConstants.CURRENT_USER]: (localCurrentUser && JSON.parse(localCurrentUser) && Map(JSON.parse(localCurrentUser))) || Map({
        username: 'test@test.com'
    }),
    [StoreStateConstants.PAYMENT_TYPE]: PAYMENT_TYPE_CONSTANTS.STRIPE,
    [StoreStateConstants.USER_LIST]: [],
    [StoreStateConstants.USER_TOTAL_COUNT]: 0,
    [StoreStateConstants.UNPAGED_USER_LIST]: [],
    [StoreStateConstants.UNPAGED_USER_TOTAL_COUNT]: 0,
});

export const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionConstants.UPDATE_CURRENT_USER: {
            const data = action.payload;
            LocalStorageUtil.setItem(StoreStateConstants.CURRENT_USER, JSON.stringify(data));
            return state.set(StoreStateConstants.CURRENT_USER, Map(data));
        }
        case ActionConstants.LOGOUT: {
            cookie.remove(StoreStateConstants.AUTHENTICATION, {path: '/'});
            unsetAPIAuthorizationToken();
            return state.set(StoreStateConstants.LOGIN_STATUS, LOGIN_CONSTANTS.LOGGED_OUT);
        }
        case ActionConstants.UPDATE_AUTHENTICATION: {
            //TODO: 叫后端设置cookie httpOnly 以及samesite 属性
            const time = new Date(new Date().getTime() + 86400 * 1000);
            cookie.save(StoreStateConstants.AUTHENTICATION, action.payload.token, {expires: time, path: '/' });
            setAPIAuthorizationToken(action.payload.token);
            return state.set(StoreStateConstants.LOGIN_STATUS, LOGIN_CONSTANTS.LOGGED_IN);
        }
        case ActionConstants.UPDATE_LOGIN_STATUS: {
            return state.set(StoreStateConstants.LOGIN_STATUS, action.payload ? LOGIN_CONSTANTS.LOGGED_IN: LOGIN_CONSTANTS.LOGGED_OUT);
        }
        case ActionConstants.CLEAR_CURRENT_USER: {
            LocalStorageUtil.removeItem(StoreStateConstants.CURRENT_USER);
            return state.set(StoreStateConstants.CURRENT_USER, null);
        }
        case ActionConstants.UPDATE_PAYMENT_TYPE: {
            return state.set(StoreStateConstants.PAYMENT_TYPE, action.payload);
        }
        case ActionConstants.UPDATE_USER_LIST: {
            return state.set(StoreStateConstants.USER_LIST, action.payload.data).set(StoreStateConstants.USER_TOTAL_COUNT, action.payload.total);
        }
        case ActionConstants.UPDATE_UNPAGEAD_USER_LIST: {
            console.log('~~~~~~~~~~');
            console.log(action.payload.data)
            return state.set(StoreStateConstants.UNPAGED_USER_LIST, action.payload.data).set(StoreStateConstants.UNPAGED_USER_TOTAL_COUNT, action.payload.total);
        }
        default:
            return state;
    }
};
