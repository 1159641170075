import { createSelector } from 'reselect';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const selectAccountCountryReducer = state => state.get(ReducerNameConstants.ACCOUNT_COUNTRY);

export const selectAccountCountryList = createSelector(
  [selectAccountCountryReducer],
  (countryReducer) => {
    return countryReducer && countryReducer.get(StoreStateConstants.ACCOUNT_COUNTRY_LIST);
  },
);

export const selectAccountCountryRecordTotalNum = createSelector(
  [selectAccountCountryReducer],
  (countryReducer) => {
    return countryReducer && countryReducer.get(StoreStateConstants.ACCOUNT_COUNTRY_TOTAL_COUNT);
  },
);

export const selectUnpagedAccountCountryList = createSelector(
  [selectAccountCountryReducer],
  (countryReducer) => {
    const countryList = countryReducer && countryReducer.get(StoreStateConstants.UNPAGED_ACCOUNT_COUNTRY_LIST);
    return countryList && countryList.map(item => {
      return {
        value: item.id,
        label: item.country_code
      }
    })
  },
);