
export function renderSubscriptionStatus(status) {
  switch (status) {
    case '成功订阅': return 'Successfully Subscribed';
    case '停止订阅': return 'Subscription Canceled';
    case '失败订阅': return 'Subscription Failed';
    case '成功修改': return 'Amount Successfully Updated';
    default: return ''
  }
}


export function renderSubscriptionIconStatus(status) {
  switch (status) {
    case '成功订阅': return '#16cc65';
    case '停止订阅': return '#edcc3d';
    case '失败订阅': return '#ef5160';
    case '成功修改': return '#4797ff';
    default: return ''
  }
}
