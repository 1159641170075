import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// --- Components --- //
import RDSButton from '../../components/common/RDSButton';
import SvgIcon from '../../components/common/SvgIcon';
import EDSInput from '../../components/common/EDSInput';
import Loading from '../../components/common/LoadingIndicator';

// --- Constants --- //
import PagePathConstants from '../../constants/router/PagePathConstants';
import ColorConstants from '../../constants/ColorConstants';

// --- Actions --- //
import * as productCategoryActionCreator from '../../actions/productCategoryActionCreator';

import I18N from '../../i18n';
import classNames from 'classnames';


class ProductCategoryEditPage extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            desc: '',
        };

        this.handleDescChange = this.handleDescChange.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.toggleEditing = this.toggleEditing.bind(this);
        this.handleBackPage = this.handleBackPage.bind(this);
        this.editProductCategoryInfo = this.editProductCategoryInfo.bind(this);
        this.getProductCategoryInfoById = this.getProductCategoryInfoById.bind(this);
    }

    componentDidMount() {
        this.getProductCategoryInfoById();
    }

    getProductCategoryInfoById() {
        const { location, productCategoryActions } = this.props;
        const productCategoryId = location.pathname.split(`${PagePathConstants.PRODUCT_CATETORY}/`)[1];
        if (productCategoryId && productCategoryId !== 'add') {
            this.toggleLoading();
            productCategoryActions.fetchCategoryDetailById(productCategoryId)
                .then(res => {
                    this.setState({
                        desc: res.fenlei,    
                    });
                    this.toggleLoading();
                })
                .catch(() => {
                    this.toggleLoading();
                })
        }
    }

    toggleEditing() {
        this.setState({ isEditing: !this.state.isEditing });
    }

    toggleLoading() {
        this.setState({ showLoading: !this.state.showLoading });
    }

    handleDescChange(e) {
        const desc = e && e.target.value;
        this.setState({ desc, showError: false });
    }


    editProductCategoryInfo() {
        const { productCategoryActions, history, location } = this.props;
        const productCategoryId = location.pathname.split(`${PagePathConstants.PRODUCT_CATETORY}/`)[1];
        const params = {
            fenlei: this.state.desc.trim()
        }
        if (productCategoryId && productCategoryId !== 'add') {
            params['id'] = productCategoryId;
        }
        this.toggleEditing();
        productCategoryActions && productCategoryActions.editProductCategoryInfoById(params)
            .then(() => {
                history && history.push(PagePathConstants.PRODUCT_CATETORY);
            })
            .catch(err => {
                this.toggleEditing();
            });
    }

    handleBackPage() {
        const { history } = this.props;
        history && history.goBack();
    }

    render() {
        const { isEditing, showLoading, desc, showError, errMsg, errFiled } = this.state;
        return <div className='tw-w-full tw-px-[50px] tw-py-[50px]'>
            {showLoading && <Loading />}
            <div className='tw-rounded-[30px] tw-bg-white'>
                <div className='tw-flex tw-items-center  tw-px-[30px] tw-py-[24px] tw-border-b' onClick={this.handleBackPage}>
                    <SvgIcon name="rds-back-icon" color={ColorConstants.black[0]} size={24} />
                    <span className='tw-ml-[10px] tw-text-[18px] tw-font-bold' >{I18N.getText('back')}</span>
                </div>
                <div className='tw-text-[14px] tw-px-[30px] tw-py-[30px]'>
                    <div className='tw-flex tw-items-center'>
                        <div className='tw-w-[20%] tw-font-bold' >{I18N.getText('product-category')}</div>
                        <EDSInput
                            className={classNames(`tw-py-[10px] tw-px-[20px] tw-rounded-[30px] tw-border tw-shadow-xl tw-text-base tw-border-border-gray tw-font-medium focus:tw-border-brand-primary focus:tw-shadow-none placeholder:tw-text-body-text-secondary placeholder:tw-font-normal placeholder:tw-text-[12px]`, { 'tw-border-light-red': (showError && errFiled === 'desc') })}
                            placeholder={I18N.getText('product-category-tip')}
                            value={desc || ""}
                            type="text"
                            onChange={this.handleDescChange}
                        />
                    </div>
                    {showError && <div className='tw-mt-[20px] tw-text-[14px] tw-text-error-text'>{errMsg}</div>}
                    <div className='tw-flex tw-justify-center tw-items-center tw-mt-[30px]'>
                        <RDSButton
                            loading={isEditing}
                            disabled={isEditing || desc.trim() === ''}
                            onClick={this.editProductCategoryInfo}
                            className="tw-text-white" >{I18N.getText('edit')}</RDSButton>
                    </div>
                </div>
            </div>
        </div>;
    }
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
        productCategoryActions: bindActionCreators(productCategoryActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCategoryEditPage);